import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/authContext';
import CameraButton from './CameraButton';
import { gql, useMutation } from '@apollo/client';
const CREATE_REPORTED_ISSUE = gql`
    mutation($pickupRequestId: ID!, $files: Upload!, $remarks: String!) {
        createReportedIssue(pickupRequestId: $pickupRequestId, files: $files, remarks: $remarks) {
            reportedIssue {
                id
                remarks
            }
        }
    }`;

const ReportIsssueModal = ({ showModal, closeModal, pickupId = null }) => {
    const { token } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        remarks: '',
        files: [],
    });
    const [allImages, setAllImages] = useState([]);

    const [errors, setErrors] = useState([]);

    const [createReportedIssue, { reportedIssueLoading, reportedIssueError, data: reportedIssueData }] = useMutation(CREATE_REPORTED_ISSUE);

    useEffect(() => {
        if (reportedIssueData && reportedIssueData?.createReportedIssue?.reportedIssue) {
            closeModal();
        }
    }, [reportedIssueData])

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });
    };

    const handleAllTakenImages = (image) => {
        setAllImages(prevList => {
            const newList = [...prevList, image];
            return newList;
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createReportedIssue({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                pickupRequestId: pickupId,
                files: allImages,
                remarks: formData.remarks,
            }
        });

    };

    const removeImage = (e, index) => {
        e.preventDefault();
        let images = allImages.filter((image, idx) => idx !== index);
        return setAllImages(images);
    };

    if (showModal) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40">
                <div className="w-full max-w-sm pt-4 mx-auto bg-[#1D397A] text-white rounded-lg shadow-lg md:max-w-2xl">
                    <div className="flex items-center justify-between p-4 border-b border-gray-600 rounded-t">
                        <h3 className="text-lg font-semibold text-white">Report Issue</h3>
                        <button
                            onClick={() => closeModal()}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form>
                        <div className="p-4 bg-white rounded-lg text-black">
                            <div className="mb-4">
                                <label
                                    className="block text-[16px] font-bold tracking-wide text-gray-700 uppercase mb-2"
                                    htmlFor="issue_remarks"
                                >
                                    Pickup Request ID# {pickupId}
                                </label>
                                <label
                                    className="block text-xs font-bold tracking-wide text-gray-700 uppercase mb-2"
                                    htmlFor="issue_remarks"
                                >
                                    Remarks
                                </label>
                                <textarea
                                    value={formData.remarks}
                                    onChange={(value) => handleChange('remarks', value)}
                                    className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:bg-white"
                                    id="issue_remarks"
                                    placeholder="Enter Issue Description"
                                />
                                {errors && errors.remarks && (
                                    <p className="text-xs italic text-red-500">{errors.remarks}</p>
                                )}
                            </div>
                            <div className="items-center justify-between mb-4">

                                <CameraButton alltakenImages={handleAllTakenImages} />
                                {allImages.length > 0 && <div className="mt-4">
                                    <h2>All Captured Images</h2>
                                    <div className="w-full flex flex-wrap items-center justify-start">
                                        {allImages.map((src, index) => (
                                            <div key={index} className="w-2/6 px-2 relative">
                                                <div className="absolute top-2 right-3 z-10">
                                                    <svg onClick={(e) => removeImage(e, index)} width={20} fill={'#fff'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                                    </svg>
                                                </div>
                                                <img width={'100%'} key={index} src={src} alt={`Captured ${index}`} className="mb-2 mr-3 rounded-md" />
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => closeModal()}
                                    className="px-6 py-2 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                    className="px-6 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default ReportIsssueModal;
