import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import OtherTruckModel from './OtherTruckModal';
import SuggestedTrucks from './SuggestedTrucks';
import ReportIssueModal from './ReportIssueModal';
import CompletedFreight from './CompletedFreight';
import GenerateInvoice from '../batch_invoice/GenerateInvoice';
import ImageLightBoxModal from './ImageLightBoxModal';

import { BACKEND_API_URL } from '../../constants';

const Delivery = ({ filterValue, selectedTruckTab, allTrucks, selectedTruck }) => {
    const { token } = useContext(AuthContext);
    const [trucks, setTrucks] = useState([]);
    const [loadingTruck, setLoadingTruck] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState([]);
    const [showOtherTruckModal, setShowOtherTruckModal] = useState(false);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);
    const [currentPickup, setCurrentPickup] = useState(null);
    const [lighBoxImage, setLighBoxImage] = useState(null)
    const [showImageLightBoxModal, setImageLightBoxModal] = useState(false)

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!,$status: String,$truckId: ID) {
            updatePickupRequest (id: $id,status: $status, truckId: $truckId) {
                pickupRequest {
                    id,
                    createdAt,
                    apiMode,
                    shipmentDate,
                    pickupDate,
                    pickupTime,
                    pickupNumber,
                    shipmentTerms,
                    serviceType,
                    pickupStatus,
                    truck {
                        id,
                        numberPlate,
                        model,
                    }
                    customer {
                        contactPersonFullName,
                        businessName
                    }
                    account {
                        id,
                        companyName,
                    },
                    pickupRequestAddresses {
                        id,
                        companyName,
                        addressType,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                    },
                    pickupRequestItems {
                        id,
                        description,
                        quantity,
                        weight,
                        length,
                        width,
                        height,
                        pallets,
                        shipmentClass,
                        charges
                    },
                    pickupRequestInvoices{
                        id,
                        customer {
                            id
                            contactPersonFullName
                        }
                        invoiceDate,
                        invoicePath,
                        status
                    }
                }
            }
        }
    `;

    const [updatePickupRequest, { pickupRequestLoading, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);

    useEffect(() => {
        if (selectedTruck && selectedTruck.assignedPickupRequests.length > 0) {
            let truckStorageLocation = selectedTruck.zone && selectedTruck?.zone?.zoneLocations ? selectedTruck.zone.zoneLocations[0] : {};
            setTrucks([selectedTruck])
        }
        else {
            setTrucks([])
        }
    }, [selectedTruck, setTrucks, allTrucks]);

    useEffect(() => {
        let filteredUpcomingTrucks = allTrucks.filter((truck, index) => {
            return truck.id != selectedTruck.id;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [setUpcomingTrucks, allTrucks, selectedTruckTab]);


    useEffect(() => {
        if (pickupRequestUpdatedData && pickupRequestUpdatedData.updatePickupRequest?.pickupRequest) {
            let updatedPickupRequest = pickupRequestUpdatedData.updatePickupRequest?.pickupRequest;
            let updatedTrucks = trucks.map(truck => {
                let updatedAssignedPickupRequests = truck.assignedPickupRequests.map(pickup =>
                    pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
                );

                return {
                    ...truck,
                    assignedPickupRequests: updatedAssignedPickupRequests
                };
            });
            setTrucks(updatedTrucks)
        }
    }, [pickupRequestUpdatedData]);


    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (
                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full  my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Delivery Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                )
            })
        }

    }

    const renderSuggestedTruck = (truckId, pickupRequest) => {
        let suggestedTruck = trucks.filter((truck) => truck.id === truckId);

        if (pickupRequest.pickupStatus !== 'loaded') {
            return (
                <p className="font-bold">Suggested Truck: {suggestedTruck[0].numberPlate ? suggestedTruck[0].numberPlate : ''}</p>
            )
        }
        return (
            <div>
                <p className="">Confirmed Truck: <span className="font-bold text-green-500">{suggestedTruck[0].numberPlate ? suggestedTruck[0].numberPlate : ''}</span></p>
                <p className="">Delivery Status:  <span className="font-bold text-green-500">loaded</span></p>

            </div>
        );

    }

    const closeOtherTruckModal = () => {
        setShowOtherTruckModal(false)
    }

    const showReportIssueModalHandle = (id) => {
        setShowReportIssueModal(true);
        setShowReportIssueModalId(id);
    }

    const showOtherTruckModalHandle = (pickupId) => {
        setShowOtherTruckModal(true)
        setCurrentPickup(pickupId);
    }

    const confirmTruckHandle = (pickupId, status) => {
        let confirmedTruck = null;
        if (pickupId) {
            let confirmedTruckArray = loadingTruck.filter((truck) => truck.pickupId === pickupId);
            confirmedTruck = confirmedTruckArray[0]['truckId'];
        }

        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: confirmedTruck,
                status: status,
            }
        });
        if (showOtherTruckModal == true) {
            setShowOtherTruckModal(false);
        }
    }

    const setSuggestedTruckHandle = (truck, pickupId) => {
        if (truck && truck.id) {
            setLoadingTruck((prevLoadingTruck) => [
                ...prevLoadingTruck,
                { pickupId: pickupId, truckId: truck.id }
            ]);
        }
    }

    const closeImageLightBoxModal = () => {
        setImageLightBoxModal(false);
    }

    const showImageLightBox = (pickupImage) => {
        setLighBoxImage(pickupImage)
        setImageLightBoxModal(true);
    }

    const renderPickupImages = (pickupRequest) => {
        if (pickupRequest?.pickupRequestImages?.length > 0) {
            return pickupRequest.pickupRequestImages.map((pickupImage) => {
                if (pickupImage.pickupRequestStatus == pickupRequest.pickupStatus) {
                    return (
                        <div key={pickupImage.id} onClick={() => showImageLightBox(pickupImage?.image)} className='border p-1 rounded-md hover:bg-black'>
                            <img width="50" src={BACKEND_API_URL + 'images/' + pickupImage.image} />
                        </div>
                    )
                }
                return '';
            })
        }
        return '';
    }

    const renderPickupIssueImages = (reportedIssue) => {
        if (reportedIssue?.pickupRequestIssueImages?.length > 0) {
            return reportedIssue?.pickupRequestIssueImages.map((pickupImage) => {
                return (
                    <div key={pickupImage.id} onClick={() => showImageLightBox(pickupImage, reportedIssue)} className='border p-1 rounded-md hover:bg-black'>
                        <img width="50" src={BACKEND_API_URL + 'images/' + pickupImage.image} />
                    </div>
                )
            })
        }
        return '';
    }

    const renderTruckPickupRequests = (truck) => {
        if (truck.assignedPickupRequests.length > 0) {
            return truck.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                const renderDeliveryStatus = (pickupRequest) => {
                    if (pickupRequest?.pickupStatus == 'delivered') {
                        return <span className="px-3 bg-[#28A7451A] text-[#28A745] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Delivered</span>;
                    }
                    else if (pickupRequest?.pickupStatus == 'loaded') {
                        return <span className="px-3 bg-[#007BFF1A] text-[#007BFF] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Driver App confirmation Pending</span>
                    }
                    else if (pickupRequest?.pickupStatus == 'driver-accepted-delivery') {
                        return <span className="px-3 bg-[#007BFF1A] text-[#007BFF] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Delivery Accepted by Driver</span>
                    }
                    else if (pickupRequest?.pickupStatus == 'delivery-on-the-move') {
                        return <span className="px-3 bg-[#007BFF1A] text-[#007BFF] rounded-md text-[16px] font-medium inline-flex items-center"><span className="text-[18px] font-medium mr-2">•</span> Delivery on route</span>
                    }
                }

                return (
                    <div key={pickupRequest.id} className="w-full flex flex-wrap rounded-lg mb-[20px]">
                        <div className="w-full ">
                            <div className='w-full flex flex-wrap rounded-lg'>
                                <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-[25%]">
                                    <div className="w-full">
                                        <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">BOL #{index + 1}</h5>
                                        {renderDeliveryStatus(pickupRequest)}
                                    </div>
                                    {pickupRequest.pickupStatus == 'delivered' && <GenerateInvoice pickupRequest={pickupRequest} />}
                                </div>
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-[50%]">
                                    <div className="w-full">
                                        <div className="flex flex-wrap w-full">
                                            <div className='flex flex-wrap w-full'>
                                                <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Delivery # {pickupRequest.id}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Delivery Number:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                            </div>
                                            {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                                            <div className='flex flex-wrap w-full'>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
                                    <div className="flex justify-start md:justify-end">
                                        <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-md font-semibold transition-colors duration-200 transform   hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A] rounded-md">
                                            <span className="mr-2">

                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                    <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                    <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                </svg>

                                            </span>
                                            Report an issue
                                        </button>
                                    </div>
                                    <div className='w-[100] mt-4'>
                                        {pickupRequest.pickupStatus == 'delivered' && <h3 className='text-xl font-medium'>Freight Photos at Destination</h3>}
                                        <div className='w-full flex flex-wrap items-center justify-start gap-3 mt-4'>
                                            {renderPickupImages(pickupRequest)}
                                        </div>
                                        {pickupRequest?.pickupRequestIssues?.length > 0 &&
                                            <div className='w-full mt-4'>
                                                <hr className='my-4'></hr>
                                                <h3 className='text-xl font-medium'>Issue Reported - Case Photos</h3>
                                                <div className='w-full flex flex-wrap items-center justify-start gap-3 mt-4'>
                                                    {renderPickupIssueImages(pickupRequest?.pickupRequestIssues[0])}
                                                </div>
                                            </div>
                                        }
                                        {pickupRequest.pickupStatus == 'delivered' && pickupRequest.deliverySignature &&
                                            <div>
                                                <h3 className='text-xl font-medium'>Delivery Signed By: </h3>
                                                <h4 className='text-lg font-medium'>Full Name: {pickupRequest?.deliverySignature?.signatureBy} </h4>
                                                <div className='w-full flex flex-wrap items-center justify-start gap-3 mt-4'>
                                                    <div onClick={() => showImageLightBox(pickupRequest?.deliverySignature?.signatureImage, pickupRequest)} className='border p-1 rounded-md hover:bg-black'>
                                                        <img width="50" src={BACKEND_API_URL + 'images/' + pickupRequest?.deliverySignature?.signatureImage} />
                                                    </div>
                                                    <div>
                                                        <h4 className='text-md font-medium'>Remarks:</h4>
                                                        <p>{pickupRequest?.deliverySignature?.remarks}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={pickupRequest.id}>
                            {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                    </div >
                )

            });
        }
    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }


    const loadingStatusHandle = (index, pickupId) => {
        let updatedloadingStatus = [...loadingStatus, index + '-' + pickupId]
        setLoadingStatus(updatedloadingStatus);
    }

    const renderTrucksDetails = () => {
        if (trucks.length > 0) {

            return trucks.map((truck, index) => {
                return (
                    <div key={truck.id} className="md:w-full">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="md:w-full flex flex-wrap">
                                {renderTruckPickupRequests(truck)}
                            </div>
                            <div key={truck.id}>
                                {showReportIssueModalId === truck.id && <ReportIssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {
            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full">
                        <div className="md:w-full flex p-5 border bg-white shadow-md rounded-lg h-[100%]">

                            <div className="w-full">
                                {renderTruckPickupRequests(truck)}
                            </div>

                        </div>

                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    return (
        <div>
            <div className="tab-container">
                <div className="relative z-1">

                    {trucks.length > 0 && trucks[0]?.assignedPickupRequests?.length > 0 ? <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                        {renderTrucksDetails()}
                        <ImageLightBoxModal pickupImage={lighBoxImage} showModal={showImageLightBoxModal} closeModal={() => closeImageLightBoxModal()} />
                        <OtherTruckModel updateSelectedTruck={(truckId) => confirmTruckHandle(currentPickup, 'loaded')} currentPickup={currentPickup} selectedTruck={loadingTruck} showModal={showOtherTruckModal} closeModal={() => closeOtherTruckModal()} />
                    </div> :
                        <p className=' mt-7'>No Delivery Found...</p>}
                </div>
            </div>
        </div >
    )
}
export default Delivery;