import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";
import SortableTable from "../../components/SortableTable";
import { AuthContext } from '../../context/authContext';
import { Link, useParams } from 'react-router-dom';
import Pagination from "../../components/Pagination";
import searchIcon from '../../assets/search-icon.svg';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';
import EditInvoice from './components/EditInvoice';
import GraphMutation from '../../components/graphql/mutations/Mutation';
import InvoiceHistory from './components/InvoiceHistory';
import { gql, useMutation } from '@apollo/client';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

const INVOICE_MAIL = gql`
    mutation CreateInvoiceMail($invoiceMailData: InvoiceMailInput!) {
        createInvoiceMails(invoiceMailData: $invoiceMailData) {
            success
            message
        }
    }
`

function PickupInvoiceDetailPage() {
    const { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [pickupInvoiceDataLoading, setPickupInvoiceDataLoading] = useState(false);
    const [editActionType, setEditActionType] = useState('preview');
    const [invoiceCharges, setInvoiceCharges] = useState([]);
    const [invoiceHistories, setInvoiceHistories] = useState([]);
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const [mailingInvoiceId, setMailingInvoiceId] = useState(null);
    const [showInvoiceMail, setShowInvoiceMail] = useState(false);
    const [mailFormData, setMailFormData] = useState({
        invoiceId: null,
        invoicePath: '',
        sendToMe: false,
        sendToCustomer: false,
        subject: '',
    })

    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);

    const { responseData: pickupInvoiceData, loading: invoiceloading, error } = GraphQuery(
        { name: 'PickupInvoice', single: 'PickupInvoice' },
        { id: parseInt(id) },
        `
        id,
        invoiceDate,
        invoicePath,
        updateType,
        status,
        subtotal,
        totalPrice,
        pickupRequest {
            pickupNumber
            serviceType
        }
        customer {
            contactPersonFullName
        }
        pickupInvoiceItems {
            id,
            description,
            quantity,
        }
        invoiceCharges {
            id,
            invoice {
                id
            }
            description,
            quantity,
            unitPrice,
            amount,
        }
        invoiceHistories {
            invoice {
                id
                invoicePath
                customer {
                    contactPersonFullName
                }
                pickupRequest {
                    pickupNumber
                }
            }
            updatedBy {
                id,
                firstName
            }
            updateType,
            invoicePath
            oldGrandTotal,
            newGrandTotal,
            timestamp
        }
    `
    );
    // const { executeMutation: invoiceMails, responseData: invoiceMailData, loading: invoiceMailLoading, errors: invoiceMailErrors, } = GraphMutation(
    //     { name: 'InvoiceMails', expectedResponseField: 'InvoiceMails', method: 'create' },
    //     { name: 'invoiceMail', type: 'InvoiceMailInput' },
    //     `
    //     success
    //     message
    //     `
    // )

    const [invoiceMails, { invoiceMailLoading, invoiceMailErrors, data: invoiceMailData }] = useMutation(INVOICE_MAIL);

    const { executeMutation: CreatePickupInvoices, responseData: pickupInvoicesData, loading: pickupInvoiceLoading, errors: pickupInvoiceError } = GraphMutation(
        { name: 'PickupInvoices', expectedResponseField: 'pickupInvoices', method: 'create' },
        { name: 'pickupInvoice', type: 'CreatePickupInvoicesInput' },
        `
        id
        invoiceDate,
        invoicePath,
        updateType,
        status,
        subtotal,
        totalDiscount,
        totalPrice,
        pickupRequest {
            id
            pickupNumber
            serviceType
        }
        customer {
            contactPersonFullName
        }
        pickupInvoiceItems {
            id,
            description,
            quantity,
        }
        invoiceCharges {
            id,
            invoice {
                id
            }
            description,
            quantity,
            amount,
        }
        invoiceHistories {
            invoice {
                id
                customer {
                    contactPersonFullName
                }
                pickupRequest {
                    pickupNumber
                }
            }
            updatedBy {
                id,
                firstName
            }
            updateType,
            invoicePath,
            oldGrandTotal,
            newGrandTotal,
            timestamp
        }
    `
    );

    // const { responseData: pickupInvoiceHistoryData, invoiceHistoryloading, errors: pickupInvoiceHistoryErrors, refetchData } = GraphQuery(
    //     { name: 'invoiceHistories', single: 'InvoiceHistory' },
    //     {
    //         id: parseInt(id),
    //         sortBy: 'id',
    //         order: 'desc',
    //     },
    //     `
    //         id
    //         invoice {
    //             id
    //             invoicePath
    //             customer {
    //                 contactPersonFullName
    //             }
    //             pickupRequest {
    //                 pickupNumber
    //             }
    //         }
    //         updatedBy {
    //             id,
    //             firstName
    //         }
    //         updateType,
    //         oldGrandTotal,
    //         newGrandTotal,
    //         timestamp
    //     `
    // );

    useEffect(() => {
        console.log('sendInvoiceMailData', invoiceMailData)
        if (invoiceMailData) {
            console.log('inner', invoiceMailData.invoiceMails)
        }
    }, [invoiceMailData])

    useEffect(() => {
        if (pickupInvoiceData && pickupInvoiceData.pickupInvoiceItems) {
            setEditActionType(pickupInvoiceData.updateType);
            setInvoiceData(pickupInvoiceData);
            setInvoiceItems(pickupInvoiceData.pickupInvoiceItems);
            setInvoiceHistories(prev => [...pickupInvoiceData.invoiceHistories]);
            setPickupInvoiceDataLoading(false);
        }
    }, [pickupInvoiceData]);

    useEffect(() => {
        // refetchData({ variables: { id: id, sortBy: 'id', order: 'desc' }, fetchPolicy: 'network-only' });
        if (pickupInvoicesData && pickupInvoicesData.pickupInvoices) {
            const pickupInvoiceData = pickupInvoicesData.pickupInvoices[0];
            setInvoiceData(pickupInvoiceData);
            setInvoiceItems(pickupInvoiceData.pickupInvoiceItems);
            setInvoiceHistories(prev => [...pickupInvoiceData.invoiceHistories]);
            const invoiceCharges = pickupInvoiceData.invoiceCharges;
            // const noInvoiceCharges = invoiceCharges.some(charge => charge.description === 'Grand Total');
            // if (noInvoiceCharges) {
            //     setInvoiceCharges([]);
            // }
            setInvoiceCharges(invoiceCharges)
            setPickupInvoiceDataLoading(false);
        }
    }, [pickupInvoicesData]);

    useEffect(() => {
        const invoiceCharges = pickupInvoiceData?.invoiceCharges;
        setInvoiceCharges(invoiceCharges)
        // const noInvoiceCharges = invoiceCharges?.some(charge => charge.description === 'Grand Total');
        // if (noInvoiceCharges && editActionType !== 'preview') {
        //     setInvoiceCharges([]);
        // }
    }, [editActionType, pickupInvoiceData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);

    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    };

    const config = [
        {
            label: 'ID',
            render: (invoice) => <Link to={`/admin/invoices/${invoice.id}`} className='text-blue-600 text-sm leading-5 font-normal'>{invoice.id}</Link>,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Invoice Date',
            render: (invoice) => invoice.invoiceDate,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Pickup Number',
            render: (invoice) => invoice?.pickupRequest?.pickupNumber,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Customer',
            render: (invoice) => invoice?.customer?.contactPersonFullName,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Status',
            render: (invoice) => <span className='p-1 px-3 bg-yellow-300 rounded-md'>{invoice.status}</span>,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4 capitalize',
        },
        {
            label: 'Computer Generated Estimate File',
            render: (invoice) => <Link to={invoice.invoicePath} className='text-blue-600 text-sm leading-5 font-normal'>{invoice.invoicePath}</Link>,
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Total Price',
            render: (invoice) => '$' + parseFloat(invoice.totalPrice).toFixed(2),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
        {
            label: 'Actions',
            render: (invoiceItem) => (
                <div className='flex gap-3'>
                    <button onClick={() => editInvoiceHandle('invoice-by-line-item')} className={`rounded-md ${editActionType === 'invoice-by-line-item' ? 'bg-indigo-600 text-white' : 'bg-white border text-black'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex`}>
                        <span className="ml-2">Generate Invoice By line Item</span>
                    </button>
                    <button onClick={() => editInvoiceHandle('invoice-by-grand-total')} className={`rounded-md ${editActionType === 'invoice-by-grand-total' ? 'bg-indigo-600 text-white' : 'bg-white border text-black'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex`}>
                        <span className="ml-2">Generate Invoice By Grand Total</span>
                    </button>
                </div>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: 'pl-4',
        },
    ];

    const editInvoiceHandle = (editType) => {
        setEditActionType(editType === editActionType ? 'preview' : editType);
    };

    const keyFun = (invoice) => {
        const key = invoice?.id || `temp-key-${Math.random()}`;
        return key;
    };

    const invoiceItemkeyFun = (invoiceItem) => {
        return invoiceItem.id;
    };

    const searchHandler = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    };

    const saveChargesHandle = async (charges, type, grandTotal) => {
        let input = {
            ids: [invoiceData.id],
            invoiceCharges: charges,
            generateType: 'create',
            updateType: type,
            grandTotal: grandTotal,
        };
        await CreatePickupInvoices(input);
        setPickupInvoiceDataLoading(true);
    };

    const mailingInvoiceIdHandle = (data) => {
        console.log('mailing invoice id ', data);
        setShowInvoiceMail(true);
        setMailFormData({
            ...mailFormData,
            invoiceId: data.invoiceId,
            invoicePath: data.invoicePath,
        })
    }

    const checkboxChangeHandle = (property, e) => {
        // const formData = [...mailFormData];
        // formData[property] = e.target.checked ? true : false;

        setMailFormData({
            ...mailFormData,
            [property]: e.target.checked ? true : false,
        });
    }

    const handleMailform = (property, e) => {
        // const formData = [...mailFormData];
        // formData[property] = e.target.checked ? true : false;

        setMailFormData({
            ...mailFormData,
            [property]: e.target.value,
        });
    }

    const sendInvoiceMail = async () => {
        console.log('sending mail...', mailFormData);
        let input = {
            invoiceId: mailFormData.invoiceId,
            sendToMe: mailFormData.sendToMe,
            sendToCustomer: mailFormData.sendToCustomer,
            subject: mailFormData.subject,
        };
        await invoiceMails({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                invoiceMailData: input,
            }
        });

    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="content-bar flex justify-between mt-6">
                    <div className='flex flex-wrap justify-between'>
                        <div className="mr-3">
                            <PageHeading>PICK UP INVOICE DETAIL PAGE</PageHeading>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content px-3 lg:px-8">
                {invoiceloading ? <Loader /> :
                    <div>
                        <div className="mt-8">
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                <div className="absolute inset-0"></div>
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-x-scroll mt-7">
                                        <SortableTable tableLayout="row" data={[invoiceData]} config={config} keyFun={keyFun} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-3'>
                            <div className='w-3/6'>
                                <div className='mt-4 w-full'>
                                    <EditInvoice showloading={pickupInvoiceDataLoading} item={invoiceItems[0]} savedTotal={invoiceData.totalPrice} serviceType={invoiceData?.pickupRequest?.serviceType} saveCharges={saveChargesHandle} type={editActionType} invoiceCharges={invoiceCharges} previewMode={() => setEditActionType('preview')} />
                                </div>
                                {pickupInvoiceDataLoading ? (
                                    <Loader />
                                ) : (
                                    <div className='mt-4 w-full'>
                                        <InvoiceHistory pickupInvoiceHistories={invoiceHistories} updateMailingInvoiceId={mailingInvoiceIdHandle} />
                                    </div>
                                )}
                            </div>
                            {showInvoiceMail && <div className='w-3/6 border rounded-md py-5 px-3 mt-4'>
                                <div className="md:w-full px-3 flex items-center">
                                    <div className="inline-flex items-center">
                                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_mail_me'}>
                                            <input onChange={(value) => checkboxChangeHandle('sendToMe', value)} checked={mailFormData.sendToMe} type="checkbox"
                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                id={'check_mail_me'} />
                                            <span
                                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path fillRule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                        <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_mail_me'}>
                                            Send to me
                                        </label>
                                    </div>
                                </div>
                                <div className="md:w-full px-3 flex items-center">
                                    <div className="inline-flex items-center">
                                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_mail_customer'}>
                                            <input onChange={(value) => checkboxChangeHandle('sendToCustomer', value)} checked={mailFormData.sendToCustomer} type="checkbox"
                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                id={'check_mail_customer'} />
                                            <span
                                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" strokeWidth="1">
                                                    <path fillRule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clipRule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                        <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_mail_customer'}>
                                            Send to Customer
                                        </label>
                                    </div>
                                </div>
                                <div className="md:w-full px-3 ">
                                    <div>
                                        <div className="md:w-full mt-4 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="overall-length">
                                                Email Subject
                                            </label>
                                            <input value={mailFormData.subject} onChange={(value) => handleMailform('subject', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3" id="mail_subject" type="text" placeholder="Subject" required />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={() => sendInvoiceMail()} className="border rounded-lg bg-white text-black py-2 px-5">
                                        Send Email
                                    </button>
                                </div>

                            </div>}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default PickupInvoiceDetailPage;
