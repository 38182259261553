import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';

const ReactDateRangePicker = ({ getUpdatedDateRange, startDate = new Date(), endDate = null }) => {
    const [showCalendarBox, setShowCalendarBox] = useState(false);
    const [date, setDate] = useState({
        startDate: startDate ? new Date(startDate) : new Date(),
        endDate: endDate ? new Date(endDate) : null,
        key: 'selection',
    });

    // Format date for the input fields (in local timezone)
    const formatDateForInput = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleChange = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setDate({ ...date, startDate, endDate });
        getUpdatedDateRange({ startDate, endDate });
    };

    const calendarBoxHandle = (action) => {
        setShowCalendarBox(action);
    };

    return (
        <div className='w-full relative'>
            <div className='w-full flex flex-row items-center justify-end'>
                <div className='w-2/6 flex items-center mr-5'>
                    <label className='mr-2'>From:</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="date"
                        value={formatDateForInput(date.startDate)}
                        onFocus={() => calendarBoxHandle(true)}
                        onChange={(e) => {
                            const newStartDate = new Date(e.target.value);
                            setDate((prev) => ({ ...prev, startDate: newStartDate }));
                            getUpdatedDateRange({ startDate: newStartDate, endDate: date.endDate });
                        }}
                    />
                </div>
                <div className='w-2/6 flex items-center'>
                    <label className='mr-2'>To:</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="date"
                        value={formatDateForInput(date.endDate)}
                        onFocus={() => calendarBoxHandle(true)}
                        onChange={(e) => {
                            const newEndDate = new Date(e.target.value);
                            setDate((prev) => ({ ...prev, endDate: newEndDate }));
                            getUpdatedDateRange({ startDate: date.startDate, endDate: newEndDate });
                        }}
                    />
                </div>
            </div>

            {showCalendarBox && (
                <div className='border rounded-md mt-3 absolute z-10 right-0'>
                    <div className='w-full flex justify-end pt-4 pb-2 pr-3 bg-white'>
                        <button onClick={() => calendarBoxHandle(false)}>
                            <svg className='text-black fill-black w-[20px] h-[20px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                            </svg>
                        </button>
                    </div>
                    <DateRangePicker
                        locale={enUS}
                        ranges={[date]}
                        onChange={handleChange}
                    />
                </div>
            )}
        </div>
    );
};

export default ReactDateRangePicker;
