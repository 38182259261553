import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GQLQuery from '../graphql/queries/Query';
import GraphQuery from '../graphql/queries/Query';

const CommodityDescription = ({ demoData, getBoxesData, boxErrors }) => {
    const { token } = useContext(AuthContext);
    const [commodityTabs, setCommodityTabs] = useState([
        { id: 1, title: 'Box 1' },
    ]);
    const [selectedBox, setSelectedBox] = useState(0);
    const [boxes, setBoxes] = useState([
        { shipmentClass: '', grossWeight: '', packageTemplate: '', dimension1: '', dimension2: '', length: '', packageType: '', commoditySource: '', shipmentMode: '', consigneeType: '', routedExportTransaction: '', transactionParties: '' },
    ]);

    const [shipmentClassOptions, setShipmentClassOptions] = useState([]);
    const [packageTemplateOptions, setPackageTemplateOptions] = useState([]);
    const [packageTypeOptions, setPackageTypeOptions] = useState([]);
    const [commoditySourceOptions, setCommoditySourceOptions] = useState([]);
    const [shipmentModeOptions, setShipmentModeOptions] = useState([]);
    const [consigneeTypeOptions, setConsigneeTypeOptions] = useState([]);


    const [routedExportTransactionOptions] = useState([
        { title: 'Yes', slug: 'yes' },
        { title: 'No', slug: 'no' }
    ]);
    const [transactionPartiesOptions] = useState([
        { title: 'Related', slug: 'related' },
        { title: 'Non Related', slug: 'non-related' }
    ]);

    // const GET_SHIPMENT_CLASSES = gql`
    //     query GetShipmentClasses($first: Int, $skip: Int, $search: String, $sortBy: ShipmentClassSortInput) {
    //         shipmentClasses(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
    //             id
    //             title
    //         },
    //     }
    // `;

    // const { data: shipmentClassesData } = useQuery(GET_SHIPMENT_CLASSES, {
    //     context: {
    //         headers: {
    //             Authorization: `JWT ${token}`,
    //         },
    //     },
    //     variables: {
    //         search: '',
    //         sortBy: {
    //             field: 'title',
    //             direction: 'asc',
    //         },
    //     },
    // });




    const { responseData: shipmentClassesData, loading, error } = GraphQuery({ name: 'ShipmentClasses', single: 'ShipmentClass' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title');

    const { responseData: packageTemplateData, } = GraphQuery({ name: 'PackageTemplates', single: 'PackageTemplate' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title dimension1 dimension2 length');

    const { responseData: packageTypeData, } = GraphQuery({ name: 'PackageTypes', single: 'PackageType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title slug');

    const { responseData: shipmentModeData, } = GraphQuery({ name: 'ShipmentModes', single: 'ShipmentMode' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title slug');

    const { responseData: CommoditySourceData, } = GraphQuery({ name: 'CommoditySources', single: 'CommoditySource' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title alias');

    const { responseData: consigneeTypeData, } = GraphQuery({ name: 'ConsigneeTypes', single: 'ConsigneeType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title slug');

    useEffect(() => {
        if (shipmentClassesData) {
            setShipmentClassOptions(shipmentClassesData)
        }
    }, [shipmentClassesData, setShipmentClassOptions]);

    useEffect(() => {
        if (demoData.boxes && demoData.boxes.length > 0) {
            setBoxes(demoData.boxes);
        }
    }, [demoData]);

    useEffect(() => {
        if (consigneeTypeData) {
            setConsigneeTypeOptions(consigneeTypeData)
        }
    }, [consigneeTypeData, setConsigneeTypeOptions]);

    useEffect(() => {
        if (shipmentModeData) {
            setShipmentModeOptions(shipmentModeData)
        }
    }, [shipmentModeData, setShipmentModeOptions]);

    useEffect(() => {
        if (packageTemplateData) {
            setPackageTemplateOptions(packageTemplateData)
        }
    }, [packageTemplateData, setPackageTemplateOptions]);

    useEffect(() => {
        if (packageTypeData) {
            setPackageTypeOptions(packageTypeData)
        }
    }, [packageTypeData, setPackageTypeOptions]);

    useEffect(() => {
        if (CommoditySourceData) {
            setCommoditySourceOptions(CommoditySourceData)
        }
    }, [CommoditySourceData, setCommoditySourceOptions]);



    const handleBoxValueChange = (idx, property, value) => {
        const updatedBoxes = [...boxes];
        updatedBoxes[idx][property] = value;
        setBoxes(updatedBoxes);
        getBoxesData(boxes);
    }

    const boxSelectItemHandle = (idx, property, value) => {
        const updatedBoxes = [...boxes];
        updatedBoxes[idx][property] = value;
        if (property == 'packageTemplate') {
            let selectedTemplate = packageTemplateOptions.filter((template) => template.id === value);
            updatedBoxes[idx]['dimension1'] = selectedTemplate[0].dimension1;
            updatedBoxes[idx]['dimension2'] = selectedTemplate[0].dimension2;
            updatedBoxes[idx]['length'] = selectedTemplate[0].length;
        }
        setBoxes(updatedBoxes);
        getBoxesData(boxes);
    }

    // useEffect(() => {

    // }, [boxes, boxSelectItemHandle, boxSelectItemHandle])

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const changeBoxHandle = (e, index) => {
        e.preventDefault();
        setSelectedBox(index);
    }



    const renderCommodityTabs = () => {
        return boxes.map((box, index) => {
            return (

                <li key={index} className={'border-white relative'} >
                    <div className={selectedBox == index ? 'border border-gray-300 rounded-t-lg border-b-white relative z-10  bg-white inline-block px-4 py-3 hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white' : 'relative z-10  bg-white inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white'}>
                        <button onClick={(e) => changeBoxHandle(e, index)}>Box {index + 1}</button>
                        {index > 0 && <button onClick={(e) => removeBoxHandle(e, index)} className="ml-3 absolute top-2 right-1">
                            <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#ff0b0b" viewBox="0 0 14 14">
                                <path stroke="#ff0b0b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </button>}
                    </div>
                </li >
            )
        })
    }

    const addBoxHandle = (e) => {
        e.preventDefault();
        // setLastTabId(lastTabId + 1);
        setBoxes([
            ...boxes,
            { shipmentClass: '', grossWeight: '', packageTemplate: '', dimension1: '', dimension2: '', length: '', packageType: '', commoditySource: '', shipmentMode: '', consigneeType: '', routedExportTransaction: '', transactionParties: '' }
        ])
    }

    const removeBoxHandle = (e, idx) => {
        e.preventDefault();
        let filteredBoxes = boxes.filter((box, index) => index !== idx);
        setBoxes(filteredBoxes);
        if (selectedBox === idx) {
            setSelectedBox(idx - 1);
        }

    }



    const renderTabContent = () => {
        return boxes.map((box, index) => {
            if (selectedBox === index) {
                return (
                    <div key={index}>
                        <div className="row bg-white rounded px-4 sm:px-8 pt-6 pb-8 mb-4 flex my-2">
                            <div className="md:w-full">
                                <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                    <div className="w-full md:w-1/2 px-3">
                                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-class">
                                            Class
                                        </label>
                                        <div className="relative mb-3">
                                            <select value={box.shipmentClass} onChange={(e) => boxSelectItemHandle(index, 'shipmentClass', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="box-class">
                                                <option value="">Select Class</option>
                                                {
                                                    shipmentClassOptions.map((shipmentClass, index) => {
                                                        return (
                                                            <option key={shipmentClass.title} value={shipmentClass.id}>{shipmentClass.title}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                            </div>
                                            {boxErrors && boxErrors[`items[${index}][shipmentClass]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][shipmentClass]`]}</p>}

                                        </div>
                                        {/* <input value={box.shipmentClass} onChange={(e) => handleBoxValueChange(index, 'shipmentClass', e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-customer-name" type="text" autoComplete="on" placeholder="Adress line 1" required /> */}
                                        {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                    </div>
                                    <div className="w-full md:w-1/2 px-3">
                                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-gross-weight">
                                            Gross Weight
                                        </label>
                                        <input value={box.grossWeight} onChange={(e) => handleBoxValueChange(index, 'grossWeight', e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="box-gross-weight" type="text" autoComplete="on" placeholder="Gross Weight" required />
                                        {boxErrors && boxErrors[`items[${index}][grossWeight]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][grossWeight]`]}</p>}

                                        {/* {boxErrors.items && boxErrors.items[index] && boxErrors.items[index].grossWeight && (
                                            <p className="text-red-500 text-xs italic">{boxErrors.items[index].grossWeight}</p>
                                        )} */}

                                    </div>
                                </div>
                                <div className="md:w-full">
                                    <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-class">
                                                Package Template
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.packageTemplate} onChange={(e) => boxSelectItemHandle(index, 'packageTemplate', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_state">
                                                    <option value="">Select Package Template</option>
                                                    {
                                                        packageTemplateOptions.map((packageTemplate, index) => {
                                                            return (
                                                                <option key={packageTemplate.title} value={packageTemplate.id}>{packageTemplate.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                                {boxErrors && boxErrors[`items[${index}][packageTemplate]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][packageTemplate]`]}</p>}

                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-class">
                                                Package Type
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.packageType} onChange={(e) => boxSelectItemHandle(index, 'packageType', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_state">
                                                    <option value="">Select Package Type</option>
                                                    {
                                                        packageTypeOptions.map((packageType, index) => {
                                                            return (
                                                                <option key={packageType.title} value={packageType.id}>{packageType.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                                {boxErrors && boxErrors[`items[${index}][packageType]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][packageType]`]}</p>}

                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-full">
                                    <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-length">
                                                Dimension 1 (Width)
                                            </label>
                                            <input value={box.dimension1} onChange={(e) => handleBoxValueChange(index, 'dimension1', e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-customer-name" type="text" autoComplete="on" placeholder="Dimension 1" required />
                                            {boxErrors && boxErrors[`items[${index}][dimension1]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][dimension1]`]}</p>}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-class">
                                                D/F/M Commodity Source
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.commoditySource} onChange={(e) => boxSelectItemHandle(index, 'commoditySource', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="customer_state">
                                                    <option value="">Select Commodity Source</option>
                                                    {
                                                        commoditySourceOptions.map((commoditySource, index) => {
                                                            return (
                                                                <option key={commoditySource.title} value={commoditySource.id}>{commoditySource.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>

                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-full">
                                    <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-package-type">
                                                Dimension 2 (Height)
                                            </label>
                                            <input value={box.dimension2} onChange={(e) => handleBoxValueChange(index, 'dimension2', e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-customer-name" type="text" autoComplete="on" placeholder="Dimension 2" required />
                                            {boxErrors && boxErrors[`items[${index}][dimension2]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][dimension2]`]}</p>}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-shipmentMode">
                                                Shipment Mode
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.shipmentMode} onChange={(e) => boxSelectItemHandle(index, 'shipmentMode', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="box-shipmentMode">
                                                    <option value="">Select Shipment Mode</option>
                                                    {
                                                        shipmentModeOptions.map((shipmentMode, index) => {
                                                            return (
                                                                <option key={shipmentMode.title} value={shipmentMode.id}>{shipmentMode.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>

                                    </div>
                                </div>
                                <div className="md:w-full">
                                    <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-length">
                                                Length
                                            </label>
                                            <input value={box.length} onChange={(e) => handleBoxValueChange(index, 'length', e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="box-length" type="text" autoComplete="on" placeholder="Length" required />
                                            {boxErrors && boxErrors[`items[${index}][length]`] && <p className="text-red-500 text-xs italic">{boxErrors[`items[${index}][length]`]}</p>}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-consignee-type">
                                                Consignee Type
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.consigneeType} onChange={(e) => boxSelectItemHandle(index, 'consigneeType', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="box-consignee-type">
                                                    <option value="">Select Consignee Type</option>
                                                    {
                                                        consigneeTypeOptions.map((consigneeType, index) => {
                                                            return (
                                                                <option key={consigneeType.title} value={consigneeType.id}>{consigneeType.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>

                                    </div>
                                </div>
                                <div className="md:w-full">
                                    <div className="bg-white rounded mb-4 flex flex-wrap flex-row my-2">
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-consignee-type">
                                                Routed Export Transaction
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.routedExportTransaction} onChange={(e) => boxSelectItemHandle(index, 'routedExportTransaction', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="box-consignee-type">
                                                    <option value="">Select Option</option>
                                                    {
                                                        routedExportTransactionOptions.map((option, index) => {
                                                            return (
                                                                <option key={option.title} value={option.slug}>{option.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="box-consignee-type">
                                                Parties to Transaction
                                            </label>
                                            <div className="relative mb-3">
                                                <select value={box.transactionParties} onChange={(e) => boxSelectItemHandle(index, 'transactionParties', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="box-consignee-type">
                                                    <option value="">No Selection</option>
                                                    {
                                                        transactionPartiesOptions.map((transactionParties, index) => {
                                                            return (
                                                                <option key={transactionParties.title} value={transactionParties.slug}>{transactionParties.title}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </div>
                                            {/* {errors && errors.businessName && <p className="text-red-500 text-xs italic">{errors.businessName}</p>} */}
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >
                )
            }
            return '';
        })
    }

    return (
        <div>
            <div className="tab-container">
                <ul className="relative flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    {renderCommodityTabs()}
                    <li className={selectedBox == 'summary' ? 'border border-gray-300 rounded-t-lg border-b-white relative z-10  bg-white inline-block px-4 py-3 hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white' : 'relative z-10  inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white'} >
                        <a onClick={(e) => changeBoxHandle(e, 'summary')} type="button" href="#" className="">Summary</a>
                    </li>
                    <li className={'border-white'} >
                        <a type="button" onClick={(e) => addBoxHandle(e)} href="#" className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white ">+Box</a>
                    </li>
                </ul>
                <div className="relative tab-content shadow-md rounded border  top-[-1px] z-1">
                    {renderTabContent()}
                    {selectedBox == 'summary' && <div>
                        <div className="row bg-white rounded px-4 sm:px-8 pt-6 pb-8 mb-4 flex my-2">
                            <h4 className='text-2xl'>Summary</h4>

                        </div>
                    </div>}
                </div>
            </div>
        </div >
    )
}
export default CommodityDescription;