import React, { useEffect, useRef, useState } from "react";
import searchIcon from '../assets/search-icon.svg';
const CustomSelect = ({ searchValue, placeholderValue, getSuggestions, options, setSearchValue, setSelectedItems,updateOptions, multiple=false, selectedOptions=[],labelColor='red', labelTextColor='white'}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
     if (filteredOptions.length === 0) {
        setFilteredOptions(options);
     }   
    },[])

    const searchHandler = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value);
        let filteredOptions = options;
        if (e.target?.value.length > 2) {
            filteredOptions = options.filter(option => option.title.includes(e.target.value));
        }
        // if (filteredOptions.length > 0) {
        //   return setCutomerLabelOptions(filteredOptions);
        // }
        setFilteredOptions(filteredOptions);
        getSuggestions(filteredOptions);
    }

    const renderSuggestions = () => {
        let suggestions = filteredOptions;
        if (suggestions.length > 0) {
            return suggestions.map((suggestion) => {
                let isSelected = selectedOptions.includes(suggestion.slug) ? true: false; 
                let bgClass = isSelected ? 'bg-green-400 hover:bg-green-500': 'bg-white hover:bg-gray-100'; 
                return (
                    <li key={suggestion.id} className={`block px-4 py-2 ${bgClass} text-black  dark:hover:bg-gray-600 dark:hover:text-white`} onClick={() => selectSuggestionHandle(suggestion)}>
                        <span className="flex items-center justify-between">
                        {suggestion.title} 
                            { isSelected && <svg className="fill-white w-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>}    
                        </span>
                    </li>
                )
            })
        }
    }

    const selectSuggestionHandle = (value) => {
        let oldOptions = [...selectedOptions];
        
        if (oldOptions.length > 0 && oldOptions?.includes(value.slug)) {
            console.log('exists')
            oldOptions = oldOptions.filter(option => option != value.slug);
            // setSelectedCustomerLabels(updatedOptions)
        }
        else {
            console.log('not exists', value.slug)
            oldOptions.push(value.slug);
        }
        console.log(oldOptions, 'oldOptions')
        setSelectedItems(oldOptions);
        setShowDropdown(false)
    }

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) && // Click outside dropdown
            inputRef.current &&
            !inputRef.current.contains(event.target)      // Click outside input
          ) {
            setShowDropdown(false);  // Close the dropdown
          }
    }

    const removeSelectedOption = (option) => {
        let prevData = selectedOptions;
        let updatedData = prevData.filter(label => label != option);
        setSelectedItems(updatedData);
    }

    const renderSelectedOptions = () => {
        if (selectedOptions.length > 0) {
            let labelbgColor = `bg-[${labelColor}]`;
            let labelTextColorClass = `text-[${labelTextColor}]`;
            return selectedOptions?.map((option, index) => {
                return (
                    <span style={{backgroundColor: labelColor,color: labelTextColor}} className={`inline-flex items-center justify-between pl-2 mb-1 mr-2 text-sm rounded-[50px]`} key={index}>
                        {option}
                        <i className="h-[20px] w-[20px] rounded-[50px] ml-3 text-white bg-green-500 cursor-pointer flex items-center justify-center" onClick={() => removeSelectedOption(option)}>
                        <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                        </i>
                    </span>
                )
            })
        }
        return '';
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const addOptionHandle = () => {
        let title = searchValue;
        if (searchValue.length > 2) {
            let slug = title?.toLowerCase()?.replaceAll(' ', '-');
            // let lastId = options[(options.length) - 1]?.id;
            console.log('new slsug', slug);
            // let prevOptions = options;
            // prevOptions.
            updateOptions({id: null, title: searchValue, slug: slug})
            selectSuggestionHandle({id: null, title: searchValue, slug: slug})
        }
    } 

    const enterKeyPress = (e) => {
        if(e.keyCode === 13) {
            addOptionHandle();
        }
    }

    return (
        <div className="relative search-bar">
            <div className="flex p-2 pl-4 bg-white border border-gray-300 rounded-md shadow-sm search-field-container">
                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                <input onKeyDown={enterKeyPress} ref={inputRef} onFocus={() => setShowDropdown(true)} value={searchValue} onInput={(e) => searchHandler(e)} type="text" className="w-full px-4 py-1 border-inherit" placeholder={placeholderValue} />
                <button className="px-2 ml-1 text-white bg-green-600 rounded-md" onClick={() => addOptionHandle()}>Add</button>
            </div>
            <div ref={dropdownRef} className="bg-white">
                { multiple && selectedOptions.length > 0 && <div className="px-3 py-2 border rounded-md">
                    {renderSelectedOptions()}
                </div>}
                {(options.length > 0 && showDropdown === true) && <div  className="absolute z-20 w-full overflow-hidden border rounded-md">
                    <ul  className="list-disc">
                        {renderSuggestions()}
                    </ul>
                </div>}
            </div>
        </div>
    );
}

export default CustomSelect;