import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

const SuggestedLocations = ({ suggestedLocations, pickupId, zipCode, setSelectedSuggestedLocation }) => {
    const { token } = useContext(AuthContext);
    const [suggestedLocationName, setSuggestedLocationName] = useState(null);

    const GET_LOCATIONS_BY_ZIP_CODE = gql`
        query GetLocationByZipCode($zipCode: String, $sortBy: LocationsByZipCodeSortInput) {
            locationsByZipCode(zipCode: $zipCode, sortBy: $sortBy) {
                id
                title
                zone {
                    id
                    title
                }
            }
        }
    `;

    const [fetchLocationByZipCode, { data: locationsByZipCodeData }] = useLazyQuery(GET_LOCATIONS_BY_ZIP_CODE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            if (zipCode) {
                try {
                    let result = await fetchLocationByZipCode({
                        variables: {
                            zipCode: zipCode,
                            sortBy: {
                                field: 'name',
                                direction: 'asc',
                            },
                        },
                    });

                    if (result?.data?.locationsByZipCode[0] != null) {
                        setSuggestedLocationName({
                            pickupId: pickupId,
                            title: result?.data?.locationsByZipCode[0]?.title
                        });
                        let location = result?.data?.locationsByZipCode[0];
                        setSelectedSuggestedLocation(location, pickupId);
                    }
                } catch (error) {
                    console.error('Error fetching locations:', error);
                }
            }
        };

        fetchData();
    }, [zipCode, fetchLocationByZipCode]);

    return (
        <div className="">

            <span className=" pb-1 text-[#374151] rounded-md">Suggested Location: {suggestedLocationName != null && suggestedLocationName.pickupId == pickupId ? suggestedLocationName.title : ''}</span>
        </div>
    );
};

export default SuggestedLocations;
