import React, { useState, useContext, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { AuthContext } from '../context/authContext';
import PhoneInput from 'react-phone-input-2'
import CustomSelect from './CustomSelect';

const CreateCustomerModal = ({ showModal, closeModal, selectCreatedCustomer }) => {
    const { token } = useContext(AuthContext);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [billingCityOptions, setBillingCityOptions] = useState([]);

    const [customerLabelSearchValue, setCustomerLabelSearchValue] = useState('');
    const [customerLabelOptions, setCustomerLabelOptions] = useState([
        { id: 1, title: 'cash only customer', slug: 'cash-only-customer' },
        { id: 2, title: 'legacy customer', slug: 'legacy-customer' },
        { id: 3, title: 'new customer', slug: 'new-customer' },
        { id: 4, title: 'online customer', slug: 'online-customer' },
    ]);

    const [formData, setFormData] = useState({
        businessName: '',
        customerLabels: [],
        firstName: '',
        lastName: '',
        username: '',
        contactPersonEmail: '',
        password: '',
        contactPersonPhoneNumber: '',
        contactPersonMobileNumber: '',
        websiteUrl: '',
        businessAddress1: '',
        businessAddress2: '',
        businessAddress3: '',
        state: '',
        city: '',
        zipCode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingState: '',
        billingCity: '',
        billingZipCode: '',
    });
    const [errors, setErrors] = useState([]);
    const [disableCities, setDisableCities] = useState(true);

    const CREATE_CUSTOMER = gql`
        mutation($customerNew: CreateCustomerInput!) {
            createCustomer(customerData: $customerNew) {
                customer {
                    id,
                    businessName,
                    customerLabels,
                    contactPersonFullName
                    contactPersonEmail
                    websiteUrl
                    businessAddress1
                    businessAddress2
                    businessAddress3
                    state
                    city
                    zipCode
                    billingAddress1
                    billingAddress2
                    billingAddress3
                    billingState
                    billingCity
                    billingZipCode
                }
            }
        }
    `;

    const GET_US_STATES = gql`
        query GetUSStates($first: Int, $skip: Int, $search: String, $sortBy: USStateSortInput) {
            usStates(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                code,
            },
        }
    `;

    const GET_CITIES = gql`
        query GetCities($stateId: ID,$first: Int, $skip: Int, $search: String, $sortBy: CitySortInput) {
            cities(stateId: $stateId, first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                state {
                    name
                }
            },
        }
    `;

    const { data: usStateData } = useQuery(GET_US_STATES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            search: '',
            sortBy: {
                field: 'name',
                direction: 'asc',
            },
        },
    });

    const [fetchCities, { data: cityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [fetchBillingCities, { data: billingCityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [createCustomer, { data: createdCustomerData, loading }] = useMutation(CREATE_CUSTOMER, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
    })

    useEffect(() => {
        if (billingCityData && billingCityData.cities) {
            setBillingCityOptions(billingCityData.cities);
        }
    }, [billingCityData]);

    useEffect(() => {
        fetchCities({
            variables: {
                stateId: formData.state,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.state, fetchCities]);

    useEffect(() => {
        fetchBillingCities({
            variables: {
                stateId: formData.billingState,
                search: '',
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [formData?.billingState, fetchBillingCities]);

    useEffect(() => {
        if (createdCustomerData && createdCustomerData.createCustomer) {
            selectCreatedCustomer(createdCustomerData.createCustomer.customer);
            closeModal();
        }
    }, [createdCustomerData])


    // if (loading) return <p>Loading ... please wait</p>
    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createCustomer({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                customerNew: {
                    customerLabels: formData.customerLabels,
                    businessName: formData.businessName,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    username: formData.username,
                    contactPersonEmail: formData.contactPersonEmail,
                    password: formData.password,
                    contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
                    contactPersonMobileNumber: formData.contactPersonMobileNumber,
                    websiteUrl: formData.websiteUrl,
                    businessAddress1: formData.businessAddress1,
                    businessAddress2: formData.businessAddress2,
                    businessAddress3: formData.businessAddress3,
                    state: formData.state,
                    city: formData.city,
                    zipCode: formData.zipCode,
                    billingAddress1: formData.billingAddress1,
                    billingAddress2: formData.billingAddress2,
                    billingAddress3: formData.billingAddress3,
                    billingState: formData.billingState,
                    billingCity: formData.billingCity,
                    billingZipCode: formData.billingZipCode ? formData.billingZipCode : '',
                }
            }
        });
    }

    useEffect(() => {
        if (usStateData && usStateData.usStates) {
            const states = usStateData.usStates;
            setStateOptions(states);
        }
    }, [usStateData, setStateOptions]);

    useEffect(() => {
        if (cityData && cityData.cities) {
            const cities = cityData.cities;
            setCityOptions(cities);
        }
    }, [cityData, setCityOptions]);

    if (loading) return <p>Loading...</p>;

    const stateHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });
        setDisableCities(false);
        // fetchCities({
        //     variables: {
        //         stateId: e.target.value,
        //         search: '',
        //         sortBy: {
        //             field: 'name',
        //             direction: 'asc',
        //         },
        //     },
        // });
    };

    const cityHandle = (e, property) => {
        setFormData({
            ...formData,
            [property]: e.target.value,
        });

    };

    const mobileNumberHandle = (mobileNumber) => {
        setFormData({
            ...formData,
            contactPersonMobileNumber: mobileNumber,
        })
    }

    const customerLabelSearchHandle = (data) => {
        setCustomerLabelSearchValue(data);
    }

    const suggestionHandler = (data) => {
        console.log(data, 'sugg handler');
    }

    const selectCustomerLabelsHandle = (data) => {
        // setSelectedCustomerLabels(data)
        setFormData({
            ...formData,
            customerLabels: data,
        });
    }

    const updateOptionsHandle = (option) => {
        let prevData = customerLabelOptions;
        setCustomerLabelOptions(() => {
            if (prevData?.some(item => item.slug == option.slug)) {
                return [...customerLabelOptions];
            }
            return [...customerLabelOptions, option]
        })
        console.log('updated customer options', customerLabelOptions)
    }

    if (showModal) {
        return (
            <div >
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-3xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-3xl">
                            <div className="flex items-center justify-between p-4 border-b rounded-t md:p-5 dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Create New Customer
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <div className="flex flex-col px-8 pt-6 pb-8 my-2 bg-white rounded">
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="contact_person_first_name">
                                                        Contact Person First Name
                                                    </label>
                                                    <input value={formData.firstName} onChange={(value) => handleChange('firstName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="contact_person_first_name" type="text" autoComplete="on" placeholder="First Name" required />
                                                    {errors && errors.firstName && <p className="text-xs italic text-red-500">{errors.firstName}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="contact_person_last_name">
                                                        Contact Person Last Name
                                                    </label>
                                                    <input value={formData.lastName} onChange={(value) => handleChange('lastName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="contact_person_last_name" type="text" autoComplete="on" placeholder="Last Name" required />
                                                    {errors && errors.firstName && <p className="text-xs italic text-red-500">{errors.firstName}</p>}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="business_name">
                                                        Business Name
                                                    </label>
                                                    <input value={formData.businessName} onChange={(value) => handleChange('businessName', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="business_name" type="text" autoComplete="on" placeholder="Business Name" required />
                                                    {errors && errors.businessName && <p className="text-xs italic text-red-500">{errors.businessName}</p>}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_username">
                                                        Username
                                                    </label>
                                                    <input value={formData.username} onChange={(value) => handleChange('username', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_username" type="text" autoComplete="on" placeholder="Username" required />
                                                    {errors && errors.username && <p className="text-xs italic text-red-500">{errors.username}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_email">
                                                        Email
                                                    </label>
                                                    <input value={formData.contactPersonEmail} onChange={(value) => handleChange('contactPersonEmail', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_email" type="email" autoComplete="on" placeholder="Email" required />
                                                    {errors && errors.email && <p className="text-xs italic text-red-500">{errors.email}</p>}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_password">
                                                        Password
                                                    </label>
                                                    <input value={formData.password} onChange={(value) => handleChange('password', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_password" type="password" autoComplete="on" placeholder="Password" required />
                                                    {errors && errors.password && <p className="text-xs italic text-red-500">{errors.password}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_phone_number">
                                                        Phone Number
                                                    </label>
                                                    <input value={formData.contactPersonPhoneNumber} onChange={(value) => handleChange('contactPersonPhoneNumber', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_phone_number" type="text" autoComplete="on" placeholder="Phone Number" required />
                                                </div>
                                            </div>
                                            <div className="mb-3 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="mobile_number">
                                                        Mobile Number
                                                    </label>
                                                    <PhoneInput
                                                        className="border-0"
                                                        country={'us'}
                                                        value={formData.contactPersonMobileNumber}
                                                        onChange={mobileNumberHandle}
                                                    />
                                                    {errors && errors.contactPersonMobileNumber && <p className="text-xs italic text-red-500">{errors.contactPersonMobileNumber}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/2">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_webiste_url">
                                                        Website URL
                                                    </label>
                                                    <input value={formData.websiteUrl} onChange={(value) => handleChange('websiteUrl', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="customer_webiste_url" type="text" autoComplete="on" placeholder="https://www.example.com" required />
                                                </div>
                                            </div>
                                            <div className="-mx-3 md:flex">
                                                <div className="px-3 md:w-1/2">
                                                    <CustomSelect multiple={true} selectedOptions={formData.customerLabels} searchValue={customerLabelSearchValue} setSearchValue={customerLabelSearchHandle} options={customerLabelOptions} placeholderValue="Select Customer Label" getSuggestions={(value) => suggestionHandler(value)} setSelectedItems={selectCustomerLabelsHandle} updateOptions={updateOptionsHandle} />
                                                </div>
                                            </div>
                                            <h4 className="mb-3 text-xl font-bold">Consignee Address</h4>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_1">
                                                        Address Line 1
                                                    </label>
                                                    <input value={formData.businessAddress1} onChange={(value) => handleChange('businessAddress1', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_1" type="text" autoComplete="on" placeholder="Street Address 1" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                    {errors && errors.businessAddress1 && <p className="text-xs italic text-red-500">{errors.businessAddress1}</p>}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_2">
                                                        Address Line 2
                                                    </label>
                                                    <input value={formData.businessAddress2} onChange={(value) => handleChange('businessAddress2', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_2" type="text" autoComplete="on" placeholder="Street Address 2" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_address_3">
                                                        Address Line 3
                                                    </label>
                                                    <input value={formData.businessAddress3} onChange={(value) => handleChange('businessAddress3', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_address_3" type="text" autoComplete="on" placeholder="Street Address 3" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_state">
                                                        State
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.state} onChange={(e) => stateHandle(e, 'state')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_state">
                                                            <option value="null">Select State</option>
                                                            {
                                                                stateOptions.map((state, index) => {
                                                                    return (
                                                                        <option key={state.code} value={state.code}>{state.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.state && <p className="text-xs italic text-red-500">{errors.state}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_city">
                                                        City
                                                    </label>
                                                    <div className="relative">
                                                        <select value={formData.city} disabled={disableCities} onChange={(e) => cityHandle(e, 'city')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_city">
                                                            <option value="null">Select City</option>
                                                            {
                                                                cityOptions.map((city) => {
                                                                    return (
                                                                        <option value={city.name} key={city.id}>{city.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.city && <p className="text-xs italic text-red-500">{errors.city}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_zipCode">
                                                        Zip Code
                                                    </label>
                                                    <input value={formData.zipCode} onChange={(value) => handleChange('zipCode', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                    {errors && errors.zipCode && <p className="text-xs italic text-red-500">{errors.zipCode}</p>}
                                                </div>
                                            </div>
                                            <h4 className="mb-3 text-xl font-bold">Billing Address</h4>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_1">
                                                        Address Line 1
                                                    </label>
                                                    <input value={formData.billingAddress1} onChange={(value) => handleChange('billingAddress1', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_1" type="text" autoComplete="on" placeholder="Street Address 1" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_2">
                                                        Address Line 2
                                                    </label>
                                                    <input value={formData.billingAddress2} onChange={(value) => handleChange('billingAddress2', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_2" type="text" autoComplete="on" placeholder="Street Address 2" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-full">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_address_3">
                                                        Address Line 3
                                                    </label>
                                                    <input value={formData.billingAddress3} onChange={(value) => handleChange('billingAddress3', value)} className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_address_3" type="text" autoComplete="on" placeholder="Street Address 3" required />
                                                    {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                                                </div>
                                            </div>
                                            <div className="mb-6 -mx-3 md:flex">
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_state">
                                                        State
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.billingState} onChange={(e) => stateHandle(e, 'billingState')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_billing_state">
                                                            <option value="null">Select State</option>
                                                            {
                                                                stateOptions.map((state, index) => {
                                                                    return (
                                                                        <option key={state.code} value={state.code}>{state.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.billingState && <p className="text-xs italic text-red-500">{errors.billingState}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_city">
                                                        City
                                                    </label>
                                                    <div className="relative mb-3">
                                                        <select value={formData.billingCity} disabled={disableCities} onChange={(e) => cityHandle(e, 'billingCity')} className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker" id="customer_billing_city">
                                                            <option value="null">Select City</option>
                                                            {
                                                                billingCityOptions.map((city) => {
                                                                    return (
                                                                        <option value={city.name} key={city.id}>{city.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker top-4">
                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>

                                                    </div>
                                                    {errors && errors.billingCity && <p className="text-xs italic text-red-500">{errors.billingCity}</p>}
                                                </div>
                                                <div className="px-3 md:w-1/3">
                                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="customer_billing_zipCode">
                                                        Zip Code
                                                    </label>
                                                    <input value={formData.billingZipCode} onChange={(value) => handleChange('billingZipCode', value)} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker" id="customer_billing_zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                    {errors && errors.billingZipCode && <p className="text-xs italic text-red-500">{errors.billingZipCode}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button type="button" onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ div >
        );
    }
    else
        return false;

}

export default CreateCustomerModal;