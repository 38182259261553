import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import GraphMutation from '../graphql/mutations/Mutation';

const GenerateInvoice = ({ pickupRequest }) => {

    const [downloadLink, setDownloadLink] = useState('');

    const { executeMutation: CreatePickupInvoice, responseData: pickupInvoiceData, loading: pickupInvoiceLoading, errors: pickupInvoiceError } = GraphMutation(
        { name: 'PickupInvoice', expectedResponseField: 'pickupInvoice', method: 'create' },
        { name: 'pickupInvoice', type: 'CreatePickupInvoiceInput' },
        `
            id,
            account{
                id,
            }
            pickupRequest {
                id,
                pickupNumber,
            }
            customer{
                contactPersonFullName,
                businessName
            }
            status,
            invoicePath,
            invoiceDate,
            totalPrice,
            pickupInvoiceItems {
                id,
                description,
                quantity,
                itemPrice,
                subtotal
            }
        `
    );

    useEffect(() => {
        if (pickupInvoiceData && pickupInvoiceData?.invoicePath) {
            setDownloadLink(pickupInvoiceData?.invoicePath)
        }

        if (pickupRequest?.pickupRequestInvoices.length > 0) {
            setDownloadLink(pickupRequest?.pickupRequestInvoices[0].invoicePath)
        }

    }, [pickupInvoiceData, pickupRequest]);

    useEffect(() => {

        if (pickupRequest?.pickupRequestInvoices.length > 0) {
            setDownloadLink(pickupRequest?.pickupRequestInvoices[0].invoicePath)
        }

    }, [pickupRequest]);

    const generateInvoiceHandle = async () => {
        let input = {
            pickupId: parseInt(pickupRequest.id),
        };
        await CreatePickupInvoice(input);
    }

    return (
        <div>
            <div>
                {
                    downloadLink == '' ?
                        <div className="w-full">
                            <button onClick={() => generateInvoiceHandle()} className="my-5 px-[1.5rem] py-[0.75rem] mr-4 leading-5 text-[14px] text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-[#ff7c38] focus:outline-none focus:bg-[#e96f30]">Generate invoice</button>
                        </div>
                        :
                        <div className="w-full">
                            <button className="my-5 px-[1.5rem] py-[0.75rem] mr-4 leading-5 text-[14px] text-white transition-colors duration-200 transform bg-[#878484] rounded-md hover:bg-[#878484] focus:outline-none focus:bg-[#878484]" disabled={true}>Invoice Generated</button>
                            <a target='_blank' href={downloadLink} className="my-5 px-[1.5rem] py-[0.75rem] mr-4 leading-5 text-[14px] text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-800" download="Invoice.pdf">Download Invoice</a>
                        </div>
                }
            </div>
        </div >
    )
}
export default GenerateInvoice;