import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import Loader from '../../components/Loader';


function TruckFeatureEditPage() {
    const { id } = useParams();
    const { token } = useContext(AuthContext);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: null,
        name: '',
    });

    const GET_TRUCK_FEATURE = gql`
        query GetTruckFeature($id: Int!) {
            truckFeature(id: $id) {
                id,
                name
            },
        }
    `;

    const UPDATE_TRUCK_FEATURE = gql`
        mutation($id: ID, $updateData: UpdateTruckFeatureInput!) {
            updateTruckFeature(id: $id, truckFeatureData: $updateData) {
                truckFeature {
                    name
                }
            }
        }
    `;

    const { loading: truckFeatureLoading, data: truckFeatureData } = useQuery(GET_TRUCK_FEATURE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            id: parseInt(id),
        },
    });

    const [updateTruckFeature, { loading: updateLoading }] = useMutation(UPDATE_TRUCK_FEATURE, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }
        },
        onCompleted: () => {
            // Handle completion
            window.location.href = "/admin/truck-features";
        },
    });

    useEffect(() => {
        if (truckFeatureData) {
            setFormData({
                id: truckFeatureData?.truckFeature?.id,
                name: truckFeatureData?.truckFeature?.name,
            });
        }
    }, [truckFeatureData]);

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateTruckFeature({
            context: {
                headers: {
                    Authorization: `JWT ${token}`
                }
            },
            variables: {
                id: id,
                updateData: {
                    name: formData.name,
                }
            }
        });
    }

    const handleCancel = () => {
        navigate("/admin/truck-features");
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Update Truck Feature</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden mt-7 px-4">

                            {/* {
                                data && data.createTruck?.truck && < div className='text-green-500'>Truck Updated successfully.</div>
                            } */}

                            {/* Create Driver Form  */}
                            {truckFeatureLoading || updateLoading ?
                                <Loader />
                                : <div className='row'>
                                    <div className='md:w-3/6'>
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-full px-3 mb-6 md:mb-0">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="truck-feature-name">
                                                        Name
                                                    </label>
                                                    <input value={formData.name} onChange={(value) => handleChange('name', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="truck-feature-name" type="text" autoComplete="on" placeholder="Name" required />
                                                    {errors && errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {/* Create Driver Form  */}
                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default TruckFeatureEditPage;