import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../../components/SortableTable";
import PageHeading from "../../components/PageHeading";

import { Link } from 'react-router-dom'
import Pagination from "../../components/Pagination";

import Switch from "react-switch";
import searchIcon from '../../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';
import GraphMutation from '../../components/graphql/mutations/Mutation';
import Swal from 'sweetalert2'

const UPDATE_CHARGES = gql`
        mutation($input: UpdateChargesInput!) {
            updateCharges(chargesData: $input) {
                updatedWidthSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedWeightSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedLengthSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedServiceTypeCharges{
                    id,
                    title,
                    cost
                }
                updatedOtherCharges{
                    id,
                    optionName,
                    optionValue,
                }
            }
        }
    `;


function ChargesPage() {
    const urlParams = new URLSearchParams(window.location.search);

    const { token } = useContext(AuthContext);

    const [widthSurcharges, setWidthSurcharges] = useState([]);
    const [lengthSurcharges, setLengthSurcharges] = useState([]);
    const [weightSurcharges, setWeightSurcharges] = useState([]);
    const [serviceTypeCharges, setServiceTypeCharges] = useState([]);
    const [otherCharges, setOtherCharges] = useState([]);
    const [errors, setErrors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        title: 'Modal Heading',
    });

    const [formData, setFormData] = useState({
        minimumValue: '',
        maximumValue: '',
        cost: '',
        title: '',
        optionName: '',
        optionValue: '',
    });


    const pageValue = urlParams.get('page');
    const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const recordsPerPage = 10;
    const [offset, setOffset] = useState((page - 1) * recordsPerPage);
    const [testMode, setTestMode] = useState({ checked: false });
    const [search, setSearch] = useState("");

    const DELETE_CHARGES = gql`
        mutation($id: ID, $chargeType: String) {
            deleteCharges(id: $id, chargeType:$chargeType){
                updatedWidthSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedWeightSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedLengthSurcharges{
                    id,
                    minimumValue,
                    maximumValue,
                    cost
                }
                updatedServiceTypeCharges{
                    id,
                    title,
                    cost
                }
                updatedOtherCharges{
                    id,
                    optionName,
                    optionValue,
                }
            }
        }
    `;

    const { responseData: widthSurchargeData, loading: lengthLoader } = GraphQuery({ name: 'WidthSurcharges', single: 'WidthSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: lengthSurchargeData, } = GraphQuery({ name: 'LengthSurcharges', single: 'LengthSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: serviceTypesData, } = GraphQuery({ name: 'ServiceTypes', single: 'ServiceType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id title slug cost');

    const { responseData: weightSurchargeData, } = GraphQuery({ name: 'WeightSurcharges', single: 'WeightSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: otherChargesData, } = GraphQuery({ name: 'OtherCharges', single: 'OtherCharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id optionName optionValue slug');



    const [updateCharges, { loading: chargesLoading, data: chargesData }] = useMutation(UPDATE_CHARGES, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        onCompleted: () => {
            // window.location.href = '/admin/trucks';
            // navigate("/admin/trucks", { fetch: true });
        }
    })

    const [deleteCharges, { loading: deleteChargesLoading, data: deletedChargesData }] = useMutation(DELETE_CHARGES, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        onCompleted: () => {
        }
    })


    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (chargesData && chargesData?.updateCharges) {
            setWeightSurcharges(chargesData?.updateCharges?.updatedWeightSurcharges)
            setWidthSurcharges(chargesData?.updateCharges?.updatedWidthSurcharges)
            setLengthSurcharges(chargesData?.updateCharges?.updatedLengthSurcharges)
            setServiceTypeCharges(chargesData?.updateCharges?.updatedServiceTypeCharges)
            setOtherCharges(chargesData?.updateCharges?.updatedOtherCharges)
            closeModal()
        }
    }, [chargesData]);

    useEffect(() => {
        if (deletedChargesData && deletedChargesData?.deleteCharges) {
            Swal.fire('Data deleted successfully', '', 'success');
            setWeightSurcharges(deletedChargesData?.deleteCharges?.updatedWeightSurcharges)
            setWidthSurcharges(deletedChargesData?.deleteCharges?.updatedWidthSurcharges)
            setLengthSurcharges(deletedChargesData?.deleteCharges?.updatedLengthSurcharges)
            setServiceTypeCharges(deletedChargesData?.deleteCharges?.updatedServiceTypeCharges)
            setOtherCharges(deletedChargesData?.deleteCharges?.updatedOtherCharges)
        }
    }, [deletedChargesData]);

    useEffect(() => {
        if (widthSurchargeData) {
            setWidthSurcharges(widthSurchargeData)
        }
    }, [widthSurchargeData, setWidthSurcharges]);

    useEffect(() => {
        if (otherChargesData) {
            setOtherCharges(otherChargesData)
        }
    }, [otherChargesData, setOtherCharges]);

    useEffect(() => {
        if (lengthSurchargeData) {
            setLengthSurcharges(lengthSurchargeData)
        }
    }, [lengthSurchargeData, setLengthSurcharges]);

    useEffect(() => {
        if (weightSurchargeData) {
            setWeightSurcharges(weightSurchargeData)
        }
    }, [weightSurchargeData, setWeightSurcharges]);

    useEffect(() => {
        if (serviceTypesData) {
            setServiceTypeCharges(serviceTypesData)
        }
    }, [serviceTypesData, setServiceTypeCharges]);

    // useEffect(() => {
    //     if (bolData && bolData.bols) {
    //         const bols = bolData.bols;
    //         setData(bols);
    //         setCount(bolData.bolCount);
    //     }
    // }, [bolData, setData]);

    useEffect(() => {
        setPage(pageValue ? parseInt(pageValue) : 1);
    }, [pageValue]);


    const handlePageChange = (page) => {
        setPage(page);
        setOffset((page - 1) * recordsPerPage);
    }

    // const handleApiModeChange = (checked) => {
    //     setPage(1);
    //     setOffset(0);
    //     setTestMode({ checked });
    //     testMode.checked === true ? setMode('test') : setMode('live');
    //     refetch({
    //         first: recordsPerPage,
    //         skip: 0,
    //         search: search.length > 3 ? search : '',
    //         sortBy: {
    //             field: 'id',
    //             direction: 'desc',
    //         },
    //         mode: testMode.checked === true ? 'test' : 'live',
    //     });
    // }

    const EditHandle = (type, data) => {
        let titles = {
            'length-surcharge': 'Length Surcharges',
            'width-surcharge': 'Width Surcharges',
            'weight-surcharge': 'Weight Surcharges',
        };
        setModalData({ title: titles[type], id: data.id, type: type });
        setFormData({
            minimumValue: data.minimumValue >= 0 ? data.minimumValue : '',
            maximumValue: data.maximumValue >= 0 ? data.maximumValue : '',
            cost: data.cost >= 0 ? data.cost : '',
            title: data.title || '',
            optionName: data.optionName || '',
            optionValue: data.optionValue || '',
        });
        openModal();
    }

    const handleDelete = (type, id) => {
        Swal.fire({
            title: 'Are you Sure you want to delete this?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            icon: 'warning'
        }
        ).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteCharges({
                    context: {
                        headers: {
                            Authorization: `JWT ${token}`,
                        },
                    },
                    variables: { id: id, chargeType: type },
                });

            }
        });
    }



    const lengthConfig = [
        {
            label: 'ID',
            render: (lengthSurcharge) => lengthSurcharge.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Minimum Value',
            render: (lengthSurcharge) => {
                return lengthSurcharge.minimumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Maximum Value',
            render: (lengthSurcharge) => {
                return lengthSurcharge.maximumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Cost',
            render: (lengthSurcharge) => {
                return '$' + lengthSurcharge.cost;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Actions',
            render: (lengthSurcharge) => (
                <>
                    <div key={lengthSurcharge.id} className='flex gap-3'>
                        <svg onClick={() => EditHandle('length-surcharge', lengthSurcharge)} xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        <svg onClick={() => handleDelete('length-surcharge', lengthSurcharge.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const widthConfig = [
        {
            label: 'ID',
            render: (widthSurcharge) => widthSurcharge.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Minimum Value',
            render: (widthSurcharge) => {
                return widthSurcharge.minimumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Maximum Value',
            render: (widthSurcharge) => {
                return widthSurcharge.maximumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Cost',
            render: (widthSurcharge) => {
                return '$' + widthSurcharge.cost;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Actions',
            render: (widthSurcharge) => (
                <>
                    <div key={widthSurcharge.id} className='flex gap-3'>
                        <svg onClick={() => EditHandle('width-surcharge', widthSurcharge)} xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        <svg onClick={() => handleDelete('width-surcharge', widthSurcharge.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const weightConfig = [
        {
            label: 'ID',
            render: (weightSurcharge) => weightSurcharge.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Minimum Value',
            render: (weightSurcharge) => {
                return weightSurcharge.minimumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Maximum Value',
            render: (weightSurcharge) => {
                return weightSurcharge.maximumValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Cost',
            render: (weightSurcharge) => {
                return '$' + weightSurcharge.cost;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Actions',
            render: (weightSurcharge) => (
                <>
                    <div key={weightSurcharge.id} className='flex gap-3'>
                        <svg onClick={() => EditHandle('weight-surcharge', weightSurcharge)} xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        <svg onClick={() => handleDelete('weight-surcharge', weightSurcharge.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const serviceTypeConfig = [
        {
            label: 'ID',
            render: (serviceType) => serviceType.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Service Title',
            render: (serviceType) => {
                return serviceType.title;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Cost',
            render: (serviceType) => {
                return '$' + serviceType.cost;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Actions',
            render: (serviceType) => (
                <>
                    <div key={serviceType.id} className='flex gap-3'>
                        <svg onClick={() => EditHandle('service-type', serviceType)} xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        <svg onClick={() => handleDelete('service-type', serviceType.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const otherChargesConfig = [
        {
            label: 'ID',
            render: (OtherCharge) => OtherCharge.id,
            headerClassName: 'pr-8 w-12',
            rowCellClassName: '',
        },
        {
            label: 'Option Name',
            render: (otherCharge) => {
                return otherCharge.optionName;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Option Value',
            render: (otherCharge) => {
                return otherCharge.optionName == 'handling charges' ? '$' + otherCharge.optionValue : otherCharge.optionValue;
            },
            headerClassName: 'pl-0 pr-0',
            rowCellClassName: 'pl-0 pr-0',
        },
        {
            label: 'Actions',
            render: (otherCharge) => (
                <>
                    <div key={otherCharge.id} className='flex gap-3'>
                        <svg onClick={() => EditHandle('other-charges', otherCharge)} xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" stroke="currentColor" className="fill-green-500 cursor-pointer" height="25" width="20" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                        <svg onClick={() => handleDelete('other-charges', otherCharge.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="fill-red-500 cursor-pointer" height="25" width="17" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                    </div>
                </>
            ),
            headerClassName: 'pl-4',
            rowCellClassName: '',
        },
    ];

    const keyFun = (lengthSurcharge) => {
        return lengthSurcharge.id
    }

    const paginationLastLimitHandler = () => {
        let leftRecords = count - (offset + 1);
        if (leftRecords > recordsPerPage) {
            return (page) * recordsPerPage;
        }
        else {
            return count;
        }
    }

    const searchHandler = (e) => {
        e.preventDefault();
        // if (e.target.value.length > 2) {
        setSearch(e.target.value);
        // }
    }

    const closeModal = () => {
        setShowModal(false);
        setFormData({
            maximumValue: '',
            minimumValue: '',
            cost: '',
            title: '',
        });
    }

    const openModal = () => {
        setShowModal(true);
    }

    const handleSubmit = async () => {
        let input = {
            chargeType: modalData.type,
            id: parseInt(modalData.id),
            minimumValue: parseFloat(formData.minimumValue),
            maximumValue: parseFloat(formData.maximumValue),
            cost: parseFloat(formData.cost),
            title: formData.title,
            optionName: formData.optionName,
            optionValue: formData.optionValue,

        };
        // await updateCharges(input);
        await updateCharges({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                input: input,
            }
        });
    }

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
    }

    const createOtherCharge = (type) => {
        let titles = {
            'length-surcharge': 'Length Surcharges',
            'width-surcharge': 'Width Surcharges',
            'weight-surcharge': 'Weight Surcharges',
            'other-charges': 'Other Charges',
        };
        setModalData({ title: titles[type], id: data.id, type: type, method: 'create' });
        setFormData({
            minimumValue: '',
            maximumValue: '',
            cost: '',
            title: '',
        });
        openModal();
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">

                {/* <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Customer Business Name" />
                    </div>
                </div> */}
            </div>
            <div className="mt-8">
                {lengthLoader ?
                    <Loader />
                    :
                    <div className='flex flex-wrap gap-9'>
                        <div className='w-[30%]'>
                            <div className="content-bar flex justify-between mt-6">
                                <PageHeading>Weight Surcharges</PageHeading>
                                <button onClick={() => createOtherCharge('weight-surcharge')} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">Add New Weight Surcharge</span>
                                </button>
                            </div>
                            <div className="mt-8">
                                {lengthLoader ?
                                    <Loader />
                                    :
                                    <div>
                                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                            <div className="absolute inset-0"></div>
                                            <div className="relative rounded-xl overflow-auto">
                                                <div className="shadow-sm overflow-hidden mt-7">
                                                    <SortableTable tableLayout="row" data={weightSurcharges} config={weightConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                        </div>
                                        <div className="flex justify-between items-center self-stretch py-6">
                                            {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                            {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                            {count > recordsPerPage ?
                                                <div className="flex">
                                                    <Pagination
                                                        to="/bols"
                                                        currentPage={page}
                                                        totalPages={Math.ceil(count / recordsPerPage)}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                    </div>}
                            </div>

                        </div>
                        <div className='w-[30%]'>
                            <div className="content-bar flex justify-between mt-6">
                                <PageHeading>Width Surcharges</PageHeading>
                                <button onClick={() => createOtherCharge('width-surcharge')} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">Add New Width Surcharge</span>
                                </button>
                            </div>
                            <div className="mt-8">
                                {lengthLoader ?
                                    <Loader />
                                    :
                                    <div>
                                        <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                            <div className="absolute inset-0"></div>
                                            <div className="relative rounded-xl overflow-auto">
                                                <div className="shadow-sm overflow-hidden mt-7">
                                                    <SortableTable tableLayout="row" data={widthSurcharges} config={widthConfig} keyFun={keyFun} />
                                                </div>
                                            </div>
                                            <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                                        </div>
                                        <div className="flex justify-between items-center self-stretch py-6">
                                            {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                            {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                            {count > recordsPerPage ?
                                                <div className="flex">
                                                    <Pagination
                                                        to="/bols"
                                                        currentPage={page}
                                                        totalPages={Math.ceil(count / recordsPerPage)}
                                                        onPageChange={handlePageChange}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <div className="content-bar flex justify-between my-6">
                                <PageHeading>Length Surcharges</PageHeading>
                                <button onClick={() => createOtherCharge('length-surcharge')} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">Add New Length Surcharge</span>
                                </button>
                            </div>
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                {/* <div className="absolute inset-0"></div> */}
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-hidden mt-7">
                                        <SortableTable tableLayout="row" data={lengthSurcharges} config={lengthConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                                <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <div className="content-bar flex justify-between my-6">
                                <PageHeading>Service Type Charges</PageHeading>
                                <button onClick={() => createOtherCharge('service-type')} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">Add New Service Type</span>
                                </button>
                            </div>
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                {/* <div className="absolute inset-0"></div> */}
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-hidden mt-7">
                                        <SortableTable tableLayout="row" data={serviceTypeCharges} config={serviceTypeConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                                <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <div className="content-bar flex justify-between my-6">
                                <PageHeading>Other Charges</PageHeading>
                                <button onClick={() => createOtherCharge('other-charges')} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="ml-2">Add New Charges</span>
                                </button>
                            </div>
                            <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                                {/* <div className="absolute inset-0"></div> */}
                                <div className="relative rounded-xl overflow-auto">
                                    <div className="shadow-sm overflow-hidden mt-7">
                                        <SortableTable tableLayout="row" data={otherCharges} config={otherChargesConfig} keyFun={keyFun} />
                                    </div>
                                </div>
                                <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                            </div>
                            <div className="flex justify-between items-center self-stretch py-6">
                                {/* {count > 0 ? <div>Showing {offset + 1} to {(page) * recordsPerPage} of {count} results</div> : null} */}
                                {count > 0 ? <div>Showing {offset + 1} to {paginationLastLimitHandler()} of {count} results</div> : null}
                                {count > recordsPerPage ?
                                    <div className="flex">
                                        <Pagination
                                            to="/bols"
                                            currentPage={page}
                                            totalPages={Math.ceil(count / recordsPerPage)}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                }
            </div>

            {showModal && <div>
                <div id="crud-modal" tabIndex="-1" aria-hidden="true" className="bg-black/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full d-flex">
                    <div className="relative p-4 w-full max-w-xl top-[3%] left-[35%] mb-20 d-flex justify-center">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-max-3xl">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    {modalData.title}
                                </h3>
                                <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form className="">
                                <div className='row'>
                                    <div className='md:w-full'>
                                        <p className='px-8'>{modalData.id ? 'ID : ' + modalData.id : ''}</p>
                                        <div className="bg-white rounded px-8 pt-6 pb-8 flex flex-col my-2">
                                            <div className="-mx-3 md:flex mb-6">
                                                {modalData.type !== 'service-type' && modalData.type !== 'other-charges' && <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Minimum Value
                                                    </label>
                                                    <input value={formData.minimumValue} onChange={(value) => handleChange('minimumValue', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Truck Number Plate" required />
                                                    {errors && errors.minimumValue && <p className="text-red-500 text-xs italic">{errors.minimumValue}</p>}
                                                </div>}
                                                {modalData.type !== 'service-type' && modalData.type !== 'other-charges' && <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Maximum Value
                                                    </label>
                                                    <input value={formData.maximumValue} onChange={(value) => handleChange('maximumValue', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Truck Number Plate" required />
                                                    {errors && errors.maximumValue && <p className="text-red-500 text-xs italic">{errors.maximumValue}</p>}
                                                </div>}
                                                {modalData.type === 'service-type' && modalData.type !== 'other-charges' && <div className="md:w-1/2 px-3">
                                                    {/* <p></p> */}
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Service Title
                                                    </label>
                                                    <input value={formData.title} onChange={(value) => handleChange('title', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Truck Number Plate" required />
                                                    {/* {errors && errors.maximumValue && <p className="text-red-500 text-xs italic">{errors.maximumValue}</p>} */}
                                                </div>}
                                                {modalData.type != 'other-charges' && <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Cost
                                                    </label>
                                                    <input value={formData.cost} onChange={(value) => handleChange('cost', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Truck Number Plate" required />
                                                    {errors && errors.cost && <p className="text-red-500 text-xs italic">{errors.cost}</p>}
                                                </div>}
                                                {modalData.type == 'other-charges' && <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Option Name
                                                    </label>
                                                    <input value={formData.optionName} onChange={(value) => handleChange('optionName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Option Name" required />
                                                    {errors && errors.optionName && <p className="text-red-500 text-xs italic">{errors.optionName}</p>}
                                                </div>}
                                                {modalData.type == 'other-charges' && <div className="md:w-1/2 px-3">
                                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Option Value
                                                    </label>
                                                    <input value={formData.optionValue} onChange={(value) => handleChange('optionValue', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="Option Value" required />
                                                    {errors && errors.optionValue && <p className="text-red-500 text-xs italic">{errors.optionValue}</p>}
                                                </div>}
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button type="button" onClick={() => handleSubmit()} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">{modalData.method == 'create' ? 'Submit' : 'Update'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ div >}

        </div>
    );
}

export default ChargesPage;