import React, { useState, useEffect, useContext } from 'react';
import PageHeading from "../../components/PageHeading";

import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client';
// import { BACKEND_API_URL } from '../../constants.js';
import { AuthContext } from '../../context/authContext';
import Loader from '../../components/Loader.js';
import moment from 'moment';

function NotificationDetailPage() {
    const { id } = useParams();
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();


    // get the data from the server
    const GET_NOTIFICATIONS = gql`
        query GetNotifications {
            notifications {
                id
                title
                description
                status
                readAt
                action
                objectId
                createdAt
            } 
        }
    `;

    const { loading, error, data: notificationsData } = useQuery(GET_NOTIFICATIONS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            id: parseInt(id),
        },
    });

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (notificationsData) {
            setNotifications(notificationsData.notifications);
        }
    }, [notificationsData, setNotifications]);

    if (loading) return <Loader />;
    if (error) {
        return <p>Error :(</p>;
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Notifications</PageHeading>
            </div>
            <div className="mt-8 w-full">
                {notifications && notifications.map((notification) => {
                    <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                        <div className="absolute inset-0"></div>
                        <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7">
                                <div className="w-full px-3 pb-3">
                                    <p>Date: {moment(notification.createdAt)?.format('DD/MM/YYYY hh:mmA')}</p>
                                    <p className='text-lg text-bg-primary capitalize'>{notification.title}</p>
                                    <p className='text-sm'>
                                        {notification.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                    </div>
                })}
            </div>

            <div className='w-full mt-5 flex items-center justify-end'>
                <button onClick={() => navigate(-1)} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
            </div>

        </div>
    );
}

export default NotificationDetailPage;