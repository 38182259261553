
import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { useForm } from "react-hook-form"

import Select from '../../components/Select';
import Input from '../../components/Input';

import Header from '../layouts/PublicSite/Header';
import Footer from '../layouts/PublicSite/Footer';

import { AuthContext } from '../../context/authContext';

function RegisterPage() {

    const navigate = useNavigate();
    const { setRegistrationFormData } = useContext(AuthContext);

    const [setFederalTaxId] = useState('');
    const [setCompanyName] = useState('');
    const [setCompanyAddress] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setRegistrationFormData(data);
        localStorage.setItem('registrationFormData', JSON.stringify(data));
        navigate('/users/register/step-2');
    };

    const GRAPHQL_QUERY = gql`
        query GetData {
            industryClassifications {
                id
                industryName,
            },
            industries {
                id,
                industryName,
            }
        }
    `;

    const { data: graphData } = useQuery(GRAPHQL_QUERY, {});

    const [industryClassificationOptions, setIndustryClassificationOptions] = useState([]);
    const [industryOptions, setIndustryOptions] = useState([]);

    useEffect(() => {

        if (graphData && graphData.industryClassifications) {
            let industryClassificationOptions = graphData.industryClassifications.map((industryClassification) => {
                return {
                    value: industryClassification.id,
                    label: industryClassification.industryName,
                }
            });

            setIndustryClassificationOptions(industryClassificationOptions);
        }

        if (graphData && graphData.industries) {
            let industryOptions = graphData.industries.map((industryClassification) => {
                return {
                    value: industryClassification.id,
                    label: industryClassification.industryName,
                }
            });

            setIndustryOptions(industryOptions);
        }

    }, [graphData]);

    return (
        <>
            <div className="hero bg-cover bg-center bg-no-repeat bg-color-primary text-white">
                <Header theme="dark" />
            </div>

            <div className=" wrapper xl:max-w-[2000px] 2xl:max-w-[2500px] mx-auto">
                <section className="sm:px-8 md:px-16 xl:px-32 2xl:px-52">
                    <div>
                        <svg className="hidden lg:block absolute left-0  top-[20rem] z-20" xmlns="http://www.w3.org/2000/svg"
                            width="314" height="602" viewBox="0 0 314 602" fill="none">
                            <path opacity="0.2"
                                d="M-184.203 602L-184.203 0M-108.241 602L-108.241 0M-32.2787 602L-32.2787 0M43.6835 602L43.6834 0M119.646 602L119.646 0M195.608 602L195.608 0M271.57 602L271.57 0M-257 72.7971H345M-257 148.759H345M-257 224.721H345M-257 300.684H345M-257 376.646H345M-257 452.608H345M-257 528.57H345"
                                stroke="url(#paint0_radial_152_17241)" strokeWidth="2" />
                            <defs>
                                <radialGradient id="paint0_radial_152_17241" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(44 301) rotate(90) scale(301)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="my-14 sm:px-0 px-6 overflow-hidden">
                        <div className="flex-column items-center justify-center py-9 relative">
                            <div className="flex flex-col-reverse relative z-20">
                                <div
                                    className="sm:border-2 sm:border-[#56545484] sm:bg-[#56545484] sm:rounded-3xl sm:p-8 xl:p-32 block md:pb-[74px] overflow-hidden">
                                    <div>
                                        <h3 className="sm:text-center md:text-4xl text-2xl text-white font-bold">Tell us about your Company</h3>
                                        <p className="sm:text-center text-white text-justify pt-4 pb-6 md:pr-12">
                                            Enter the field below to continue
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-start lg:items-center justify-center">
                                        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                    <Select 
                                                        options={industryClassificationOptions} 
                                                        id={`industryClassificationId`} 
                                                        name={`industryClassificationId`} 
                                                        firstOption="Select Industry"
                                                        label="Industry Classification"
                                                        register={register}
                                                        validationOptions={{required: 'Select your industry classification'}}
                                                    />
                                                    <p className="text-rose-700 text-sm mb-4">{errors.industryClassificationId?.message}</p>
                                                </div>
                                                <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                    <Input
                                                        type={`number`}
                                                        label="Federal Tax ID" 
                                                        id={`federalTaxId`}
                                                        register={register}
                                                        onChange={(e) => setFederalTaxId(e.target.value)}
                                                        validationOptions={{required: 'Federal Tax ID is required'}}
                                                    />
                                                    <p className="text-rose-700 text-sm mb-4">{errors.federalTaxId?.message}</p>
                                                </div>
                                                <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                    <Input
                                                        label="Company Name" 
                                                        register={register}
                                                        id={`companyName`}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                        validationOptions={{required: 'Company name is required'}}
                                                    />
                                                    <p className="text-rose-700 text-sm mb-4">{errors.companyName?.message}</p>
                                                </div>
                                                <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                    <Input
                                                        label="Company Address" 
                                                        register={register}
                                                        id={`companyAddress`}
                                                        onChange={(e) => setCompanyAddress(e.target.value)}
                                                        validationOptions={{required: 'Company Address is required'}}
                                                    />
                                                    <p className="text-rose-700 text-sm mb-4">{errors.companyAddress?.message}</p>
                                                </div>
                                                <div className="w-full px-3 mb-6 mt-4 md:mb-0">
                                                    <Select 
                                                        options={industryOptions} 
                                                        id={`companyIndustryId`} 
                                                        name={`companyIndustryId`} 
                                                        firstOption="Select Industry"
                                                        label="Company Industry"
                                                        register={register}
                                                        validationOptions={{required: 'Company Industry is required'}}
                                                    />
                                                    <p className="text-rose-700 text-sm mb-4">{errors.companyIndustryId?.message}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap justify-between -mx-3 mb-2">
                                                <div>
                                                    <a href="/" className="sm px-6 py-3">Back</a>
                                                </div>
                                                <div className="p-[2px] rounded-full bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block">
                                                    <button className="px-[21px] xl:px-[21px] rounded-full py-2 text-white bg-black text-sm font-[600]  hover:bg-gradient-to-r from-[#8115FF] via-[#FF2AD0] to-[#FF8F2A] to-100% inline-block duration-500">
                                                        Continue
                                                    </button>
                                                </div>                    
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default RegisterPage;