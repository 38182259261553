import React, { useEffect, useState } from "react";
import moment from 'moment';
import GraphQuery from "../../../components/graphql/queries/Query";

const InvoiceHistory = ({ pickupInvoiceHistories, updateMailingInvoiceId }) => {

    // const [pickupInvoiceHistories, setPickupInvoiceHistories] = useState([]);
    const [invoiceHistories, setInvoiceHistories] = useState([])
    const [mailInvoiceId, setMailInvoiceId] = useState(null);


    const invoiceDownloadHandle = (invoicePath) => {
        if (invoicePath) {
            fetch(invoicePath)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'pickup_invoice.pdf';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => console.error('Error downloading the file:', error));
        }
    }

    const invoiceMailHandle = (invoicePath, invoiceId) => {
        console.log('sending mail');
        updateMailingInvoiceId({ invoiceId: invoiceId, invoicePath: invoicePath });
    }

    return (
        <div className="p-5 border rounded-lg">
            <div>
                <h3 className="text-xl font-semibold">Pickup History</h3>
                {
                    pickupInvoiceHistories?.map((invoiceHistory, index) => {
                        let user = ''
                        if (invoiceHistory?.updatedBy?.firstName) {
                            user = invoiceHistory?.updatedBy?.firstName;
                        }
                        let exportDate = moment(invoiceHistory?.timestamp).format('DD-MM-Y H:m:s')
                        let invoicePath = invoiceHistory?.invoicePath
                        let invoiceTotalPrice = invoiceHistory?.newGrandTotal
                        let invoiceId = invoiceHistory?.invoice?.id
                        return (
                            <div key={index}>
                                <div className="flex items-center justify-start">
                                    <div className="w-[70%] flex flex-wrap items-center justify-start">
                                        <div className="w-[30%] text-sm font-semibold py-2 px-3">{user}</div>
                                        <div className="w-[50%] text-bg-primary">{invoiceHistory.updateType}</div>
                                        <div className="w-[20%] text-bg-primary font-semibold">${invoiceTotalPrice}</div>
                                        <div className="w-[30%]">#{invoiceHistory?.invoice?.pickupRequest?.pickupNumber}</div>
                                        <div className="w-[50%]">Exported: {exportDate}</div>
                                    </div>
                                    <div className="w-[30%] flex gap-3 items-center text-sm font-semibold py-2 px-3">
                                        <button onClick={() => invoiceDownloadHandle(invoicePath)} className="border rounded-lg bg-white text-black py-2 px-3">
                                            Download
                                        </button>
                                        <button onClick={() => invoiceMailHandle(invoicePath, invoiceId)} className="border rounded-lg bg-white text-black py-2 px-3">
                                            Send via Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default InvoiceHistory;