import React, { useState } from "react";
import searchIcon from '../assets/search-icon.svg';
const ReactSimpleSuggest = ({ searchValue, placeholderValue, getSuggestions, options, setSearchValue, setSuggestedItem }) => {

    const searchHandler = (e) => {
        e.preventDefault();
        setSearchValue(e.target.value);
        getSuggestions(e.target.value);
    }

    const renderSuggestions = () => {
        let suggestions = options;
        if (suggestions.length > 0 && searchValue.trim().length > 3) {
            return suggestions.map((suggestion) => {
                return (<li key={suggestion.id} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => selectSuggestionHandle(suggestion)}>{suggestion.businessName}</li>)
            })
        }
    }

    const selectSuggestionHandle = (value) => {
        setSuggestedItem(value);
    }

    return (
        <div className="search-bar relative">
            <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                <img className="pr-2" src={searchIcon} alt="Search Icon" />
                <input value={searchValue} onInput={(e) => searchHandler(e)} type="text" className="pl-1 border-inherit" placeholder={placeholderValue} />
            </div>
            {searchValue.trim().length > 3 && <div className="bg-white border rounded-md absolute z-20 w-full">
                <ul className="list-disc">
                    {renderSuggestions()}
                </ul>
            </div>}
        </div>
    );
}

export default ReactSimpleSuggest;