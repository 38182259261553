import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader';
import ReactSimpleSuggest from '../../components/ReactSimpleSuggest';
import CreateCustomerModal from '../../components/CreateCustomerModal';
import searchIcon from '../../assets/search-icon.svg';
import CommodityDescription from './CommotidyDescription';
import GraphMutation from '../graphql/mutations/Mutation';
import PhoneInput from 'react-phone-input-2';
import GraphQuery from '../graphql/queries/Query';

const GET_CUSTOMERS = gql`
        query GetCustomers($first: Int, $skip: Int, $search: String, $sortBy: CustomerSortInput) {
            customers(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                businessName,
                contactPersonFullName
                contactPersonEmail
                websiteUrl
                businessAddress1
                businessAddress2
                businessAddress3
                state
                city
                zipCode
                billingAddress1
                billingAddress2
                billingAddress3
                billingState
                billingCity
                billingZipCode
            },
        }
    `;

const GET_US_STATES = gql`
        query GetUSStates($first: Int, $skip: Int, $search: String, $sortBy: USStateSortInput) {
            usStates(first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                code,
            },
        }
    `;

const GET_CITIES = gql`
        query GetCities($stateId: ID,$first: Int, $skip: Int, $search: String, $sortBy: CitySortInput) {
            cities(stateId: $stateId, first: $first, skip: $skip, search: $search, sortBy: $sortBy) {
                id
                name,
                state {
                    name
                }
            },
        }
    `;



const ManualPickupRequest = ({ changeTab }) => {
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const [modalVisibility, setModalVisibility] = useState(false);
    const navigate = useNavigate();
    // const pageValue = urlParams.get('page');
    // const [page, setPage] = useState(pageValue ? parseInt(pageValue) : 1);

    const [buttonLoading, setButtonLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [disableCities, setDisableCities] = useState(false);
    const [cityOptions, setCityOptions] = useState([]);
    const [billingCityOptions, setBillingCityOptions] = useState([]);
    const [shipmentTermsOptions, setShipmentTermsOptions] = useState([
        { title: 'Collect', slug: 'Collect' },
        { title: 'Prepaid', slug: 'Prepaid' },
        { title: 'Third Party', slug: 'ThirdParty' },
    ]);
    const [pickupRequestAddressFieldDisabled, setPickupRequestAddressFieldDisabled] = useState(true)
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [manualPickupRequest, setManualPickupRequest] = useState({
        pickupDate: '',
        pickupTime: '',
        serviceType: '',
        shipmentTerms: '',
        customer: {},
        boxes: [],
    });

    const [demoData, setDemoData] = useState([]);

    const [customerformData, setcustomerFormData] = useState({
        id: null,
        businessName: '',
        businessAddress1: '',
        businessAddress2: '',
        businessAddress3: '',
        contactPersonMobileNumber: '',
        state: '',
        city: '',
        zipCode: '',
        billingBusinessName: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingState: '',
        billingCity: '',
        billingZipCode: '',
        billingContactPersonMobileNumber: ''
    });
    const [serviceTypeOptions, setServiceTypeOptions] = useState([]);

    const { responseData: serviceTypeData, } = GraphQuery({ name: 'ServiceTypes', single: 'ServiceType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'title',
        order: 'asc',
    }, 'id title slug');

    const { data: usStateData } = useQuery(GET_US_STATES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            // first: recordsPerPage,
            // skip: offset,
            sortBy: {
                field: 'name',
                direction: 'asc',
            },
        },
    });

    const [fetchCities, { data: cityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [fetchBillingCities, { data: billingCityData }] = useLazyQuery(GET_CITIES, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const { data: customerData, refetch } = useQuery(GET_CUSTOMERS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        variables: {
            // first: recordsPerPage,
            // skip: offset,
            sortBy: {
                field: 'business_name',
                direction: 'asc',
            },
        },
    });

    const { executeMutation: createManualPickupRequest, responseData: manualPickupData, loading: manualPickupLoading, errors: manualPickupError } = GraphMutation(
        { name: 'ManualPickupRequest', expectedResponseField: 'pickupRequest', method: 'create' },
        { name: 'manualPickupRequest', type: 'CreateManualPickupInput' },
        `
        id,
            createdAt,
            apiMode,
            pickupStatus,
            driver {
                id,
                user {
                    firstName,
                    lastName
                }
            },
            truck {
                id,
                numberPlate,
                make,
                model,
                color
            }
            account {
                id,
                companyName,
            }
            customer {
                id,
                contactPersonFullName,
                businessName
            }
        `
    );


    useEffect(() => {
        if (customerData && customerData.customers) {
            const customers = customerData.customers;
            setCustomerOptions(customers);
        }
    }, [customerData, setCustomerOptions]);

    useEffect(() => {
        if (serviceTypeData) {
            setServiceTypeOptions(serviceTypeData)
        }
    }, [serviceTypeData, setServiceTypeOptions]);

    useEffect(() => {
        if (usStateData && usStateData.usStates) {
            setStateOptions(usStateData.usStates);
        }
    }, [usStateData]);

    useEffect(() => {
        if (cityData && cityData.cities) {
            setCityOptions(cityData.cities);
        }
    }, [cityData]);

    useEffect(() => {
        if (billingCityData && billingCityData.cities) {
            setBillingCityOptions(billingCityData.cities);
        }
    }, [billingCityData]);

    useEffect(() => {
        let stateId = customerformData.state
        fetchCities({
            variables: {
                stateId: stateId,
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [customerformData.state]);

    useEffect(() => {
        let stateId = customerformData.billingState
        fetchBillingCities({
            variables: {
                stateId: stateId,
                sortBy: {
                    field: 'name',
                    direction: 'asc',
                },
            },
        });
    }, [customerformData.billingState]);

    useEffect(() => {
        if (manualPickupError) {
            setButtonLoading(false)
        }
    }, [manualPickupError])

    useEffect(() => {
        if (manualPickupData) {
            setButtonLoading(false)
            window.location.href = "/admin/pickup-requests";
        }
    }, [manualPickupData])

    useEffect(() => {
        let customer = customerformData;
        setManualPickupRequest({
            ...manualPickupRequest,
            customer: {
                ...customerformData,
                businessName: customerformData.businessName,
                businessAddress1: customerformData.businessAddress1,
                businessAddress2: customerformData.businessAddress2,
                businessAddress3: customerformData.businessAddress3,
                state: customerformData.state,
                city: customerformData.city,
                zipCode: customerformData.zipCode,
                billingAddress1: customerformData.billingAddress1,
                billingAddress2: customerformData.billingAddress2,
                billingAddress3: customerformData.billingAddress3,
                billingState: customerformData.billingState,
                billingCity: customerformData.billingCity,
                billingZipCode: customerformData.billingZipCode,
                id: customerformData.id,
            }
        })
    }, [customerformData])

    const handleChange = (property, e) => {
        setcustomerFormData({
            ...customerformData,
            [property]: e.target.value
        })
    }

    const stateHandle = (property, e) => {
        setcustomerFormData({
            ...customerformData,
            [property]: e.target.value,
        });

    };

    const cityHandle = (property, e) => {
        setcustomerFormData({
            ...customerformData,
            [property]: e.target.value || '',
        });

    };

    const customerSearchHandle = (value) => {
        setCustomerSearchValue(value);
    }

    const selectCustomerHandle = (customer) => {
        setcustomerFormData({
            ...customerformData,
            businessName: customer.businessName,
            businessAddress1: customer.businessAddress1,
            businessAddress2: customer.businessAddress2,
            businessAddress3: customer.businessAddress3,
            state: customer.state,
            city: customer.city,
            zipCode: customer.zipCode,
            billingAddress1: customer.billingAddress1,
            billingAddress2: customer.billingAddress2,
            billingAddress3: customer.billingAddress3,
            billingState: customer.billingState,
            billingCity: customer.billingCity,
            billingZipCode: customer.billingZipCode,
            id: customer.id
        });
        setCustomerSearchValue('');


    }



    const suggestionHandler = (value) => {

        refetch({
            // first: recordsPerPage,
            // skip: offset,
            search: value.length > 3 ? value : '',
            sortBy: {
                field: 'id',
                direction: 'desc',
            },
        });

    }

    const customerModalHandle = () => {
        setModalVisibility(!modalVisibility);
    }

    const selectCreatedCustomerHandle = (customer) => {
        setcustomerFormData(customer)

    }

    const boxesDataHandle = (data) => {
        setManualPickupRequest({
            ...manualPickupRequest,
            boxes: data,
        })
    }

    const manualPickupRequestHandle = (property, value) => {
        setManualPickupRequest({
            ...manualPickupRequest,
            [property]: value,
        })
    }

    const demoDataHandle = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hours}:${minutes}`;
        // setDateTime(formattedDate);
        setDemoData({
            pickupDate: formattedDate,
            pickupTime: formattedTime,
            shipmentTerms: "Collect",
            serviceType: "direct",
            customer: customerformData,
            boxes: [
                {
                    shipmentClass: "1",
                    grossWeight: "210",
                    packageTemplate: "7",
                    dimension1: '5.5',
                    dimension2: '8.5',
                    length: '194',
                    packageType: "1",
                    commoditySource: "1",
                    shipmentMode: "2",
                    consigneeType: "1",
                    routedExportTransaction: "yes",
                    transactionParties: "related"
                }
            ],
        });
        setManualPickupRequest({
            pickupDate: formattedDate,
            pickupTime: formattedTime,
            shipmentTerms: 'Collect',
            serviceType: "direct",
            customer: customerformData,
            boxes: [
                {
                    shipmentClass: "1",
                    grossWeight: "210",
                    packageTemplate: "7",
                    dimension1: '5.5',
                    dimension2: '8.5',
                    length: '194',
                    packageType: "1",
                    commoditySource: "1",
                    shipmentMode: "2",
                    consigneeType: "1",
                    routedExportTransaction: "yes",
                    transactionParties: "related"
                }
            ],
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = {
            shipmentTerms: manualPickupRequest.shipmentTerms,
            pickupDate: manualPickupRequest.pickupDate != '' ? manualPickupRequest.pickupDate : null,
            pickupTime: manualPickupRequest.pickupTime != '' ? manualPickupRequest.pickupTime : null,
            serviceType: manualPickupRequest.serviceType != '' ? manualPickupRequest.serviceType : null,
            customer: manualPickupRequest?.customer,
            items: manualPickupRequest.boxes,
        }
        // return false;
        setButtonLoading(true);
        await createManualPickupRequest(input);

    }

    const mobileNumberHandle = (mobileNumber) => {
        setcustomerFormData({
            ...customerformData,
            contactPersonMobileNumber: mobileNumber,
        })
    }

    const billingMobileNumberHandle = (mobileNumber) => {
        setcustomerFormData({
            ...customerformData,
            billingContactPersonMobileNumber: mobileNumber,
        })
    }

    return (
        <div>
            <div className="content px-3 lg:px-8">
                <div className="mt-8">
                    <div>
                        <div>
                            {manualPickupData && <p className='text-green-500'>Manual Pickup Request created successfully</p>}
                            {/* {manualPickupError && <p className='text-green-500'>{manualPickupError}</p>} */}
                        </div>
                        <div className='flex justify-start'>
                            <div>
                                <ReactSimpleSuggest searchValue={customerSearchValue} setSearchValue={customerSearchHandle} options={customerOptions} placeholderValue="Customer Name" getSuggestions={(value) => suggestionHandler(value)} setSuggestedItem={selectCustomerHandle} />
                            </div>
                            <button onClick={() => customerModalHandle()} type="button" className="ml-5 py-2 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="ml-2">Add New Customer</span>
                            </button>
                            <button onClick={() => demoDataHandle()} type="button" className="ml-5 py-2 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                                <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V299.6l-94.7 94.7c-8.2 8.2-14 18.5-16.8 29.7l-15 60.1c-2.3 9.4-1.8 19 1.4 27.8H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
                                </svg>
                                <span className="ml-2">Use Demo Data</span>
                            </button>
                        </div>
                        <div className="w-full bg-white shadow-md rounded mt-5 border">
                            <div className="flex w-full">
                                <div className="w-full md:w-1/6  px-8 pt-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="shipment_terms">
                                        Shipment Terms
                                    </label>
                                    <div className="relative mb-3 sm:mb-0">
                                        <select value={manualPickupRequest.shipmentTerms} onChange={(e) => manualPickupRequestHandle('shipmentTerms', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="shipment_terms">
                                            <option value="null">Select Shipment Terms</option>
                                            {
                                                shipmentTermsOptions.map((term, index) => {
                                                    return (
                                                        <option key={index} value={term.slug}>{term.title}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                        {manualPickupError && manualPickupError.shipmentTerms && <p className="text-red-500 text-xs italic">{manualPickupError.shipmentTerms}</p>}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/6  px-8 pt-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="shipment_terms">
                                        Pickup Date
                                    </label>
                                    <div className="relative mb-3 sm:mb-0">
                                        <div className="relative max-w-sm">
                                            {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                </svg>
                                            </div> */}
                                            <input value={manualPickupRequest.pickupDate} onChange={(e) => manualPickupRequestHandle('pickupDate', e.target.value)} id="default-datepicker" type="date" className="bg-gray-50 py-3 px-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
                                        </div>
                                        {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div> */}
                                        {manualPickupError && manualPickupError.pickupDate && <p className="text-red-500 text-xs italic">{manualPickupError.pickupDate}</p>}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/6  px-8 pt-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="shipment_terms">
                                        Pickup Time
                                    </label>
                                    <div className="relative mb-3 sm:mb-0">
                                        <div className="relative max-w-sm">
                                            {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                </svg>
                                            </div> */}
                                            <input value={manualPickupRequest.pickupTime} onChange={(e) => manualPickupRequestHandle('pickupTime', e.target.value)} id="default-timepicker" type="time" className="bg-gray-50 py-3 px-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
                                        </div>
                                        {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div> */}
                                        {manualPickupError && manualPickupError.pickupTime && <p className="text-red-500 text-xs italic">{manualPickupError.pickupTime}</p>}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 px-8 pt-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="service_type">
                                        Service Types
                                    </label>
                                    <div className="relative mb-3">
                                        <select value={manualPickupRequest.serviceType} onChange={(e) => manualPickupRequestHandle('serviceType', e.target.value)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="service_type">
                                            <option value="">No Selection</option>
                                            {
                                                serviceTypeOptions.map((option, index) => {
                                                    return (
                                                        <option key={index} value={option.slug}>{option.title}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                        {manualPickupError && manualPickupError.serviceType && <p className="text-red-500 text-xs italic">{manualPickupError.serviceType}</p>}
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-wrap lg:flex-nowrap w-full">
                                <div className="md:w-full">
                                    <div className=" px-8 pt-0 pb-8 mb-4 flex flex-col my-2">
                                        <h4 className="text-xl mb-3">Pickup Address</h4>
                                        <p className='mb-3'>Please search customer or add new customer to proceed </p>
                                        {manualPickupError && manualPickupError.customerId && <p className="text-red-500 text-xs italic mb-3">Customer Details are required</p>}

                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-customer-name">
                                                    Business Name
                                                </label>
                                                <input disabled={pickupRequestAddressFieldDisabled} value={customerformData.businessName} onChange={(value) => handleChange('businessName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-customer-name" type="text" autoComplete="on" placeholder="Customer Name" required />
                                                {manualPickupError && manualPickupError.businessName && <p className="text-red-500 text-xs italic">{manualPickupError.businessName}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-address-line-1">
                                                    Address 1
                                                </label>
                                                <input value={customerformData.businessAddress1} onChange={(value) => handleChange('businessAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-address-line-1" type="text" autoComplete="on" placeholder="Adress line 1" required />
                                                {manualPickupError && manualPickupError.businessAddress1 && <p className="text-red-500 text-xs italic">{manualPickupError.businessAddress1}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-address-line-2">
                                                    Address 2
                                                </label>
                                                <input value={customerformData.businessAddress2} onChange={(value) => handleChange('businessAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-address-line-2" type="text" autoComplete="on" placeholder="Adress line 2" required />
                                                {manualPickupError && manualPickupError.businessAddress2 && <p className="text-red-500 text-xs italic">{manualPickupError.businessAddress2}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-address-line-3">
                                                    Address 3
                                                </label>
                                                <input value={customerformData.businessAddress3} onChange={(value) => handleChange('businessAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-address-line-3" type="text" autoComplete="on" placeholder="Adress line 3" required />
                                                {manualPickupError && manualPickupError.businessAddress3 && <p className="text-red-500 text-xs italic">{manualPickupError.businessAddress3}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-state">
                                                    State
                                                </label>
                                                <div className="relative">
                                                    <select value={customerformData.state} onChange={(e) => stateHandle('state', e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-state">
                                                        <option value="">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.id} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div> */}

                                                </div>
                                                {manualPickupError && manualPickupError.state && <p className="text-red-500 text-xs italic">{manualPickupError.state}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-city">
                                                    City
                                                </label>
                                                <div className="relative">
                                                    <select value={customerformData.city} onChange={(e) => cityHandle('city', e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-state">
                                                        <option value="">Select City</option>
                                                        {
                                                            cityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div> */}

                                                </div>
                                                {manualPickupError && manualPickupError.city && <p className="text-red-500 text-xs italic">{manualPickupError.city}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-zipCode">
                                                    zipCode
                                                </label>
                                                <input value={customerformData.zipCode} onChange={(value) => handleChange('zipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {manualPickupError && manualPickupError.zipCode && <p className="text-red-500 text-xs italic">{manualPickupError.zipCode}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="mobile_number">
                                                    Mobile Number
                                                </label>
                                                <PhoneInput
                                                    className="border-0"
                                                    country={'us'}
                                                    value={customerformData.contactPersonMobileNumber}
                                                    onChange={mobileNumberHandle}
                                                />
                                                {manualPickupError && manualPickupError.contactPersonMobileNumber && <p className="text-red-500 text-xs italic">{manualPickupError.contactPersonMobileNumber}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <CreateCustomerModal showModal={modalVisibility} closeModal={() => customerModalHandle()} selectCreatedCustomer={(customer) => selectCreatedCustomerHandle(customer)} />
                                    </div>
                                </div>
                                <div className="w-full flex items-end">
                                    <div className="px-8 pt-6 pb-8 mb-4 flex flex-col my-2 justify-end w-full">
                                        <h4 className="text-xl mb-3">Destination Address</h4>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-business-name">
                                                    Destination Business Name
                                                </label>
                                                <input value={customerformData.billingBusinessName} onChange={(value) => handleChange('billingBusinessName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="billing-address-line-1" type="text" autoComplete="on" placeholder="Billing Business Name" />
                                                {manualPickupError && manualPickupError.billingBusinessName && <p className="text-red-500 text-xs italic">{manualPickupError.billingBusinessName}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-address-line-1">
                                                    Address 1
                                                </label>
                                                <input value={customerformData.billingAddress1} onChange={(value) => handleChange('billingAddress1', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="billing-address-line-1" type="text" autoComplete="on" placeholder="Billing Address line 1" />
                                                {manualPickupError && manualPickupError.billingAddress1 && <p className="text-red-500 text-xs italic">{manualPickupError.billingAddress1}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-address-line-2">
                                                    Address 2
                                                </label>
                                                <input value={customerformData.billingAddress2} onChange={(value) => handleChange('billingAddress2', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="billing-address-line-2" type="text" autoComplete="on" placeholder="Billing Address line 2" />
                                                {manualPickupError && manualPickupError.billingAddress2 && <p className="text-red-500 text-xs italic">{manualPickupError.billingAddress2}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-full px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-address-line-3">
                                                    Address 3
                                                </label>
                                                <input value={customerformData.billingAddress3} onChange={(value) => handleChange('billingAddress3', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="billing-address-line-3" type="text" autoComplete="on" placeholder="Billing Address line 3" />
                                                {manualPickupError && manualPickupError.billingAddress3 && <p className="text-red-500 text-xs italic">{manualPickupError.billingAddress3}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-state">
                                                    State
                                                </label>
                                                <div className="relative mb-3 sm:mb-0">
                                                    <select value={customerformData.billingState} onChange={(e) => stateHandle('billingState', e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="billing-state">
                                                        <option value="">Select State</option>
                                                        {
                                                            stateOptions.map((state, index) => {
                                                                return (
                                                                    <option key={state.id} value={state.code}>{state.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div> */}

                                                </div>
                                                {manualPickupError && manualPickupError.state && <p className="text-red-500 text-xs italic">{manualPickupError.billingState}</p>}
                                            </div>
                                            <div className="md:w-1/3 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="billing-city">
                                                    City
                                                </label>
                                                <div className="relative mb-3 sm:mb-0">
                                                    <select value={customerformData.billingCity} onChange={(e) => cityHandle('billingCity', e)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="billing-state">
                                                        <option value="">Select City</option>
                                                        {
                                                            billingCityOptions.map((city) => {
                                                                return (
                                                                    <option value={city.name} key={city.id}>{city.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    {/* <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div> */}

                                                </div>
                                                {manualPickupError && manualPickupError.city && <p className="text-red-500 text-xs italic">{manualPickupError.billingCity}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-zipCode">
                                                    zipCode
                                                </label>
                                                <input value={customerformData.billingZipCode} onChange={(value) => handleChange('billingZipCode', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-zipCode" type="text" autoComplete="on" placeholder="Zip Code" required />
                                                {manualPickupError && manualPickupError.billingZipCode && <p className="text-red-500 text-xs italic">{manualPickupError.billingZipCode}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="mobile_number">
                                                    Mobile Number
                                                </label>
                                                <PhoneInput
                                                    className="border-0"
                                                    country={'us'}
                                                    value={customerformData.billingContactPersonMobileNumber}
                                                    onChange={billingMobileNumberHandle}
                                                />
                                                {manualPickupError && manualPickupError.billingContactPersonMobileNumber && <p className="text-red-500 text-xs italic">{manualPickupError.billingContactPersonMobileNumber}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='bg-white'>
                            <h4 className="text-xl mt-12 mb-3">Commodity Description</h4>
                            {manualPickupError && manualPickupError.items && <p className="text-red-500 text-xs italic mb-3">Please provide atleast one Box data</p>}

                            <div className="row">
                                <div className="w-full lg:w-5/6 xl:w-3/6">
                                    <div className="overflow-x-auto">
                                        <CommodityDescription demoData={demoData} getBoxesData={(data) => boxesDataHandle(data)} boxErrors={manualPickupError} />
                                    </div>

                                </div>

                            </div>
                        </div>
                        {manualPickupError && <div>
                            <p className='text-red-500 font-semibold'>Input Data is Invalid</p>
                        </div>}
                        <div className="flex justify-start my-5">
                            {/* <button type="button" onClick={() => closeModal()} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button> */}
                            {buttonLoading === true ? <button disabled type="button" className="text-white bg-gray-600 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-normal rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                Loading...
                            </button> :
                                <button type="button" onClick={(e) => handleSubmit(e)} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}

export default ManualPickupRequest;