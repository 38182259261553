import React, { useState, useEffect, useContext } from 'react';

import SortableTable from "../../components/SortableTable";
import PageHeading from "../../components/PageHeading";

import { Link, useParams } from 'react-router-dom'
import Pagination from "../../components/Pagination";

import searchIcon from '../../assets/search-icon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import Swal from 'sweetalert2'
import Loader from '../../components/Loader';
import GraphQuery from '../../components/graphql/queries/Query';


function LocationsInventoryPage() {
    const urlParams = new URLSearchParams(window.location.search);
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const { id } = useParams();

    const [data, setData] = useState([]);


    // const GET_LOCATION = gql`
    //     query GetLocation($id: Int!,$pickupStatus: [String]) {
    //         location (id: $id, pickupStatus:$pickupStatus) {
    //             id,
    //             title,
    //             zone {
    //                 id,
    //                 title
    //             }
    //             storedPickupRequests(pickupStatus: $pickupStatus){
    //                 id,
    //                 createdAt,
    //                 apiMode,
    //                 shipmentDate,
    //                 pickupDate,
    //                 pickupTime,
    //                 pickupNumber,
    //                 shipmentTerms,
    //                 pickupStatus,
    //                 storageLocation{
    //                     id
    //                     title
    //                 }
    //                 customer {
    //                     contactPersonFullName,
    //                     businessName
    //                 }
    //                 account {
    //                     id,
    //                     companyName,
    //                 },
    //                 pickupRequestAddresses {
    //                     id,
    //                     companyName,
    //                     addressType,
    //                     address1,
    //                     address2,
    //                     city,
    //                     state,
    //                     zipCode,
    //                 },
    //                 pickupRequestItems {
    //                     id,
    //                     description,
    //                     quantity,
    //                     weight,
    //                     pallets,
    //                     shipmentClass,
    //                     charges
    //                 },
    //             }
    //         }
    //     }
    // `;

    // const { loading: locationloading, data: locationData } = useQuery(GET_LOCATION, {
    //     context: {
    //         headers: {
    //             Authorization: `JWT ${token}`
    //         }
    //     },
    //     variables: {
    //         id: parseInt(id),
    //         pickupStatus: 'unloaded'
    //     }
    // });

    const { responseData: locationData, locationloading, error } = GraphQuery({ name: 'Location', single: 'Location' }, {
        pickupStatus: ["unloaded"],
        id: parseInt(id),
    }, `
        id,
        title,
        zone{
            id,
            title
        }
        storedPickupRequests(pickupStatus:$pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            pickupStatus,
            storageLocation{
                id
                title
            }
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                pallets,
                shipmentClass,
                charges
            },
        }
    `);

    useEffect(() => {
        if (locationData && locationData.zone) {
            setData(locationData)
        }
    }, [locationData]);

    const searchHandler = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    }

    const renderPickupRequests = () => {
        if (data?.storedPickupRequests?.length > 0) {
            return data?.storedPickupRequests?.map((pickupRequest, index) => {
                let fromAddress = null;
                let toAddress = null;
                if (pickupRequest?.pickupRequestAddresses?.length > 0) {
                    pickupRequest?.pickupRequestAddresses.forEach((address) => {
                        if (address.addressType == 'CONSIGNEE' || address.addressType == 'DESTINATION') {
                            toAddress = address;
                        }
                        if (address.addressType == 'BILLTO') {
                            fromAddress = address;
                        }
                        if (address.addressType == 'SHIPPER' || address.addressType == 'SOURCE') {
                            fromAddress = address;
                        }
                    })
                }
                return (
                    <div className='w-2/6 border rounded-lg my-4' key={pickupRequest.id}>
                        <div className='bg-[#3B82F6] px-5 py-2 flex items-center justify-between'>
                            <p className='font-inter text-xl text-white font-semibold'>Pickup # {index + 1}</p>
                            <span className='text-white font-inter text-md font-medium'>Pickup Number: {pickupRequest.pickupNumber}</span>
                        </div>
                        <div className='mb-4'>
                            <div className='px-5 my-2 flex items-start justify-between'>
                                <span className='text-md'>From Address: </span>
                                <span className='text-[16px] font-medium'>{fromAddress ? `${fromAddress.address1}, ${fromAddress.city}, ${fromAddress.state}, United States` : ''}</span>
                            </div>
                            <div className='px-5 my-4  flex items-start justify-between'>
                                <span className='text-md'>To Address: </span>
                                <span className='text-[16px] font-medium'>{toAddress ? `${toAddress.address1}, ${toAddress.city}, ${toAddress.state}, United States` : ''}</span>
                            </div>
                        </div>
                        {pickupRequest.pickupRequestItems.map((item, itemIndex) => {
                            return (
                                <div key={item.id} className='px-5'>
                                    <p className='font-inter text-lg font-medium my-[12px]'>Pickup Item# {itemIndex + 1}</p>
                                    <div className='pl-4 border-l-4 mb-4 border-color-primary-light'>
                                        <div className='flex'>
                                            <span className='font-inter w-[30%] text-[16px]'>Description:</span>
                                            <span className='font-inter w-[70%] pl-[4rem] text-right text-[16px] font-medium'>{item.description}</span>
                                        </div>
                                        <div className='flex'>
                                            <span className='font-inter w-[30%] text-[16px]'>Quantity:</span>
                                            <span className='font-inter w-[70%] pl-[4rem] text-right text-[16px] font-medium'>{item.quantity}</span>
                                        </div>
                                        <div className='flex'>
                                            <span className='font-inter w-[30%] text-[16px]'>Weight:</span>
                                            <span className='font-inter w-[70%] pl-[4rem] text-right text-[16px] font-medium'>{item.weight}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )

            })
        }
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Locations Inventory</PageHeading>
                <div className="search-bar">
                    <div className="search-field-container flex p-2 pl-4 bg-white border border-gray-300 shadow-sm rounded-md">
                        <img className="pr-2" src={searchIcon} alt="Search Icon" />
                        <input value={search} onInput={(e) => searchHandler(e)} type="text" className="pl-1" placeholder="Title" />
                    </div>
                </div>
            </div>
            <div className="mt-8 w-full">
                {locationloading ?
                    <Loader />
                    :
                    <div className="mt-8 w-full">
                        <p className='text-lg'>Location Title: <span className='font-semibold'>{data.title}</span></p>
                        <p className='text-lg'>Location Zone: <span className='font-semibold'>{data?.zone?.title}</span></p>
                        <p className='my-8 text-2xl font-semibold'>Stored Items: ({data?.storedPickupRequests?.length})</p>
                        <div className='flex gap-5'>
                            {renderPickupRequests()}
                        </div>

                        {/* <pre>{data}</pre> */}

                    </div>}

            </div>

        </div >
    );
}

export default LocationsInventoryPage;