import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import ReportIsssueModal from './ReportIssueModal';

const CompletedFreight = ({ selectedTruckTab = 0, newCompletedPickupRequest = null, pickupStatus = 'unloaded' }) => {
    // const [trucks, setTrucks] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [completedTrucks, setCompletedTrucks] = useState([]);
    const [allPickupStatuses, setAllPickupStatuses] = useState([
        'driver-assigned',
        'driver-accepted',
        'on-the-move',
        'picked',
        'unloaded',
    ]);
    const [allDeliveryStatuses, setAllDeliveryStatuses] = useState([
        'loaded',
        'driver-accepted-delivery',
        'on-the-move-delivery',
        'delivered',
    ]);


    const { responseData: CompletedtrucksData, loading, error, refetchData } = GraphQuery({ name: 'Trucks', single: 'Truck' }, {
        pickupStatus: [pickupStatus],
        skip: 0,
        search: '',
        sortBy: 'number_plate',
        order: 'asc',
    }, `
        id,
        numberPlate,
        make,
        model,
        color
        zone{
            id,
            title,
            zoneLocations {
                id,
                title
            }
        }
        assignedPickupRequests(pickupStatus: $pickupStatus){
            id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            storageLocation{
                id
                title
            }
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                length,
                width,
                height,
                pallets,
                shipmentClass,
                charges
            },
        }
    `);

    useEffect(() => {
        if (CompletedtrucksData) {
            setCompletedTrucks(CompletedtrucksData);

        }
    }, [CompletedtrucksData]);

    useEffect(() => {
        if (newCompletedPickupRequest) {
            refetchData();
        }
    }, [newCompletedPickupRequest]);


    useEffect(() => {
        if (CompletedtrucksData) {
            let filteredTrucks = CompletedtrucksData.filter((truck) => { return truck.assignedPickupRequests.length > 0 ? truck : false; })
            setCompletedTrucks(filteredTrucks)
        }
    }, [CompletedtrucksData, setCompletedTrucks]);

    const renderPickupRequestItems = (pickupItems, pickupRequest) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (

                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">{ allPickupStatuses.includes(pickupRequest.pickupStatus) ? 'Pickup' : 'Delivery'} Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[14px] text-[#374151] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[14px] text-[#374151] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                )
            })
        }

    }

    const renderTruckPickupRequests = (truck) => {
        if (truck.assignedPickupRequests.length > 0) {
            return truck.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                return (
                    <div key={index} className="md:w-full px-0 lg:px-3">
                        <div className='w-full rounded-lg mb-[20px]'>
                            <div className="flex flex-wrap w-full">
                                <div className='flex flex-wrap w-full'>
                                    <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">{ allPickupStatuses.includes(pickupRequest.pickupStatus) ? 'Pickup' : 'Delivery'} # {pickupRequest.id}</span>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>{ allPickupStatuses.includes(pickupRequest.pickupStatus) ? 'Pickup' : 'Delivery'} Number:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                </div>
                                {renderPickupRequestItems(pickupRequest?.pickupRequestItems, pickupRequest)}
                                <div className='flex flex-wrap w-full'>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[70%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType ? pickupRequest.serviceType : ''}</span>
                                </div>
                            </div>
                        </div>
                        {/* {(index + 1) < truck?.assignedPickupRequests.length && <hr className="mt-5" />} */}
                    </div>
                )

            });
        }
    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const showReportIssueModalHandle = (id) => {
        // e.preventDefault();
        setShowReportIssueModal(true);
        setShowReportIssueModalId(id);
    }

    const renderTrucksDetails = () => {
        if (completedTrucks.length > 0) {
            return completedTrucks.map((truck, index) => {
                if (index === selectedTruckTab) {
                    return (
                        <div key={index} className="md:w-full mb-[20px]">
                            <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                                {/* <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-1/6"> */}
                                <div className="w-full sm:w-full md:w-full lg:w-[100%] xl:w-[25%] 2xl:w-[25%]">
                                    <div className="w-full flex items-start">
                                        <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px] mr-3">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
                                        <svg className="fill-[#009E1B] mt-[4px]" width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                                    </div>
                                    <div className="w-full">
                                        <p className="font-inter font-normal mb-[16px] capitalize">Assigned Zone: <span>{truck?.zone?.title}</span></p>
                                    </div>
                                    {/* <button onClick={() => checkinHandle(truck)} className="px-6 py-3 mr-4 mt-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button> */}
                                </div>
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-[75%] 2xl:w-[70%]">
                                    {renderTruckPickupRequests(truck)}
                                </div>
                            </div>
                            <div key={truck.id + '_' + index}>
                                {showReportIssueModalId === truck.id && <ReportIsssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div>
                        </div>
                    )
                }
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    const renderUpcomingTrucksDetails = () => {
        if (completedTrucks.length > 0) {

            return completedTrucks.map((truck, index) => {
                if (index !== selectedTruckTab) {
                    return (
                        <div key={index} className="md:w-full mb-[20px]">
                            <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                                {/* <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-1/6"> */}
                                <div className="w-full sm:w-full md:w-full lg:w-[100%] xl:w-[25%] 2xl:w-[25%]">
                                    <div className="w-full flex items-start">
                                        <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px] mr-3">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
                                        <svg className="fill-[#009E1B] mt-[4px]" width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
                                    </div>
                                    <div className="w-full">
                                        <p className="font-inter font-normal mb-[16px] capitalize">Assigned Zone: <span>{truck?.zone?.title}</span></p>
                                    </div>
                                    {/* <button onClick={() => checkinHandle(truck)} className="px-6 py-3 mr-4 mt-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Check-in</button> */}
                                </div>
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-[75%] 2xl:w-[70%]">
                                    {renderTruckPickupRequests(truck)}
                                </div>
                            </div>
                            <div key={truck.id + '_' + index}>
                                {showReportIssueModalId === truck.id && <ReportIsssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div>
                        </div>
                    )
                }

            })
        }
        return (<p>No truck Data Available...</p>)
    }

    return (
        <div>
            {/* <h4 className="text-[30px] my-6 font-bold">Freight Check-In</h4> */}
            <div className="tab-container">
                <div className="relative z-1">
                    {completedTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Completed</h3>
                        {renderTrucksDetails()}
                        {renderUpcomingTrucksDetails()}
                    </div>}
                </div>
            </div>
        </div >
    )
}
export default CompletedFreight;