import React, { useState, useEffect, useContext } from 'react';
import { gql, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import OtherTruckModel from './OtherTruckModal';
import SuggestedTrucks from './SuggestedTrucks';
import ReportIssueModal from './ReportIssueModal';
import CompletedFreight from './CompletedFreight';
import FreightLocationModal from './FreightLocationModal';

const Loading = ({ filterValue, selectedTruckTab, allTrucks, selectedTruck }) => {
    const { token } = useContext(AuthContext);
    const [trucks, setTrucks] = useState([]);
    const [errors, setErrors] = useState(null);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState([]);
    const [showOtherTruckModal, setShowOtherTruckModal] = useState(false);
    const [showFreightLocationModal, setShowFreightLocationModal] = useState(false);
    const [currentTruckId, setCurrentTruckId] = useState(null);
    const [suggestedTrucks, setSuggestedTrucks] = useState(null);
    const [otherTruckModalId, setOtherTruckModalId] = useState(null);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);
    const [currentPickup, setCurrentPickup] = useState(null);
    const [pickupRequests, setPickupRequests] = useState([]);

    const [driverOptions, setDriverOptions] = useState([]);
    const [truckOptions, setTruckOptions] = useState([]);
    const [formData, setFormData] = useState({ truckId: 0, driverId: 0 });

    const GET_DRIVERS = gql`
        query getDrivers {
            drivers {
                id,
                user {
                    firstName,
                    lastName,
                }
                defaultTruck {
                    id
                }
            }
        }
    `;

    const GET_TRUCKS = gql`
        query getTrucks {
            trucks {
                id,
                numberPlate,
                make,
                model,
            }
        }
    `;

    const BULK_INSERT_MUTATION = gql`
    mutation bulkUpdatePickupRequests($input: UpdatePickupRequestInput) {
        bulkUpdatePickupRequests(input: $input) {
            success
            message
            updatedPickupRequests {
                id,
            createdAt,
            apiMode,
            shipmentDate,
            pickupDate,
            pickupTime,
            pickupNumber,
            shipmentTerms,
            serviceType,
            pickupStatus,
            customer {
                contactPersonFullName,
                businessName
            }
            account {
                id,
                companyName,
            },
            pickupRequestAddresses {
                id,
                companyName,
                addressType,
                address1,
                address2,
                city,
                state,
                zipCode,
            },
            pickupRequestItems {
                id,
                description,
                quantity,
                weight,
                width,
                height,
                length,
                pallets,
                shipmentClass,
                charges
            },
            pickupRequestInvoices{
                id,
                customer {
                    id
                    contactPersonFullName
                }
                invoiceDate,
                invoicePath,
                status
            }

            }
        }
    }
    `;



    const [bulkInsert, { data: bulkInsertData, loading, error }] = useMutation(BULK_INSERT_MUTATION);

    const { driversLoding, driversError, data: driversData } = useQuery(GET_DRIVERS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`,
            },
        },
    });

    const { trucksLoading, trucksError, data: trucksData } = useQuery(GET_TRUCKS, {
        context: {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }
    });

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!,$status: String,$truckId: ID) {
            updatePickupRequest (id: $id,status: $status, truckId: $truckId) {
                pickupRequest {
                    id,
                    createdAt,
                    apiMode,
                    shipmentDate,
                    pickupDate,
                    pickupTime,
                    pickupNumber,
                    shipmentTerms,
                    serviceType,
                    pickupStatus,
                    truck {
                        id,
                        numberPlate,
                        model,
                    }
                    customer {
                        contactPersonFullName,
                        businessName
                    }
                    account {
                        id,
                        companyName,
                    },
                    pickupRequestAddresses {
                        id,
                        companyName,
                        addressType,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                    },
                    pickupRequestItems {
                        id,
                        description,
                        quantity,
                        weight,
                        length,
                        width,
                        height,
                        pallets,
                        shipmentClass,
                        charges
                    },
                }
            }
        }
    `;

    const [updatePickupRequest, { pickupRequestLoading, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);

    useEffect(() => {
        if (selectedTruck && selectedTruck.assignedPickupRequests.length > 0) {
            setTrucks([selectedTruck])
        }
    }, [selectedTruck, setTrucks]);

    useEffect(() => {
        let filteredUpcomingTrucks = allTrucks.filter((truck, index) => {
            return truck.id != selectedTruck.id;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [setUpcomingTrucks, allTrucks, selectedTruckTab]);

    useEffect(() => {
        if (driversData && driversData.drivers) {
            let driverOptions = driversData?.drivers.map((driver) => {
                return ({
                    label: driver?.user?.firstName + " " + driver.user.lastName[0].toUpperCase(),
                    value: driver.id
                });
            });

            setDriverOptions(driverOptions);
        }
    }, [driversData, setDriverOptions])

    useEffect(() => {
        if (trucksData && trucksData.trucks) {
            let truckOptions = trucksData?.trucks.map((truck) => {
                return ({
                    label: truck?.make + "-" + truck.model + " ( " + truck.numberPlate + " )",
                    value: truck.id
                });
            });

            setTruckOptions(truckOptions);
        }
    }, [trucksData, setTruckOptions])



    useEffect(() => {
        if (pickupRequestUpdatedData && pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest) {
            let updatedPickupRequest = pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest;
            let updatedPickupRequests = pickupRequests.map(request => {
                if (request.truckId === updatedPickupRequest?.truck?.id) {
                    let updatedAssignedPickupRequests = request.assignedPickupRequests.map(pickup =>
                        pickup.id === updatedPickupRequest.id ? updatedPickupRequest : pickup
                    );

                    return {
                        ...request,
                        assignedPickupRequests: updatedAssignedPickupRequests
                    };
                }
                return request;
            });

            setPickupRequests(updatedPickupRequests);

        }
    }, [pickupRequestUpdatedData]);

    useEffect(() => {
        if (bulkInsertData && bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests) {
            let pickupLoadedStatus = bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests.some((pickup) => {
                return pickup.pickupStatus == 'unloaded' ? true : false;
            })

            if (pickupLoadedStatus == true) {
                setPickupRequests([]);
            }

        }
    }, [bulkInsertData]);


    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (
                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Delivery Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                )
            })
        }

    }

    const closeOtherTruckModal = () => {
        setShowOtherTruckModal(false)
        setOtherTruckModalId(null)
    }

    const showReportIssueModalHandle = (id) => {
        setShowReportIssueModal(true);
        setShowReportIssueModalId(id);
    }

    const showOtherTruckModalHandle = (pickupId) => {
        setShowOtherTruckModal(true)
        setOtherTruckModalId(pickupId)
        setCurrentPickup(pickupId);
    }

    const confirmTruckHandle = (truckId, pickupId, status) => {

        if (truckId == 0 && suggestedTrucks != null) {
            truckId = suggestedTrucks[pickupId];
        }
        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: truckId,
                status: status,
            }
        });
        if (showOtherTruckModal == true) {
            setShowOtherTruckModal(false);
            setOtherTruckModalId(null)
        }
    }

    const setSuggestedTruckHandle = (truck, pickupId) => {
        if (truck != null) {
            setSuggestedTrucks(prevSuggestedTrucks => ({
                ...prevSuggestedTrucks,
                [pickupId]: truck.id
            }));
        }
    }


    const removePickupHandle = (truckId, pickupRequestId) => {
        setPickupRequests((prevRequests) => {
            return prevRequests.map(request => {
                if (request.truckId === truckId) {
                    return {
                        ...request,
                        assignedPickupRequests: request.assignedPickupRequests.filter(
                            pickup => pickup.id !== pickupRequestId
                        )
                    };
                }
                return request;
            });
        });
    };

    const renderTruckPickupRequests = (truck, truckIndex) => {
        const truckPickupRequests = pickupRequests.find(request => request.truckId === truck.id);

        if (truckPickupRequests && truckPickupRequests.assignedPickupRequests.length > 0) {
            return truckPickupRequests.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;
                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER' || address.addressType === 'SOURCE') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE' || address.addressType === 'DESTINATION') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                return (
                    <div key={pickupRequest.id} className="md:w-full px-0 lg:px-0">
                        <div className="w-full px-3">
                            <div className='relative w-full flex flex-wrap rounded-lg mb-[20px]'>
                                
                                <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-3/6">
                                    <div className="w-full">
                                        <div className="flex flex-wrap w-full">
                                            <div className='flex flex-wrap w-full'>
                                                <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Delivery # {pickupRequest.id}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Delivery Number:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                            </div>
                                            {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                                            <div className='flex flex-wrap w-full'>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                                <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                                <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
                                    <div className="flex justify-start md:justify-end">
                                        <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-[17px] font-semibold transition-colors duration-200 transform rounded-md  hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A]">
                                            <span className="mr-2">
                                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.583V12.4167Z" fill="#EA580C" />
                                                    <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                    <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                </svg>
                                            </span>
                                            <span>
                                                Report an issue
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={pickupRequest.id}>
                            {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                        {otherTruckModalId === pickupRequest.id && <OtherTruckModel updateSelectedTruck={(truckId) => confirmTruckHandle(truckId, pickupRequest.id, 'loaded')} currentPickup={pickupRequest.id} selectedTruck={suggestedTrucks} showModal={showOtherTruckModal} closeModal={() => closeOtherTruckModal()} />}
                    </div>
                );
            });
        }
    };


    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const loadingStatusHandle = (truckId, pickupId) => {
        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                truckId: truckId,
                status: 'loaded',
            }
        });

    }

    const selectFreightHandle = (truck) => {
        setShowFreightLocationModal(true);
        setCurrentTruckId(truck.id);
    }

    const closeFreightLocationModal = () => {
        setShowFreightLocationModal(false);
    }

    const removeLoadedInventory = () => {

        let pickupData = [];
        pickupRequests.forEach(async (truck) => {
            if (truck.truckId == selectedTruck.id) {
                return await truck?.assignedPickupRequests?.forEach((pickup) => {
                    return pickupData.push({ id: parseInt(pickup.id) })
                });
            }
        })


        const inputData = {
            pickups: pickupData,
            truckId: selectedTruck?.id ? parseInt(selectedTruck.id) : null,
            status: 'unloaded'
        };

        bulkInsert({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: { input: inputData }
        });
    }

    const getUpdatedPickups = (pickups) => {
        setPickupRequests((prevRequests) => {
            const existingIndex = prevRequests.findIndex(request => request.truckId === currentTruckId);
            if (existingIndex !== -1) {
                return prevRequests.map((request, index) => {
                    if (index === existingIndex) {
                        const updatedAssignedPickupRequests = [
                            ...request.assignedPickupRequests,
                            ...pickups.filter(
                                newPickup => !request.assignedPickupRequests.some(
                                    existingPickup => existingPickup.id === newPickup.id
                                )
                            )
                        ];
                        return {
                            ...request,
                            assignedPickupRequests: updatedAssignedPickupRequests,
                        };
                    }
                    return request;
                });
            } else {
                return [
                    ...prevRequests,
                    {
                        truckId: currentTruckId,
                        assignedPickupRequests: pickups,
                    },
                ];
            }
        });

        let pickupData = [];
        pickups.forEach((pickup) => {

            return pickupData.push({ id: parseInt(pickup.id) });
        })


        let truckId = selectedTruck?.id ? parseInt(selectedTruck.id) : null;
        let driverId = null;
        if (formData.driverId !== null) {
            truckId = parseInt(formData?.truckId);
            driverId = parseInt(formData?.driverId);
        }

        const inputData = {
            pickups: pickupData,
            truckId: truckId,
            driverId: driverId,
            status: 'loaded'
        };

        bulkInsert({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: { input: inputData }
        });

        setShowFreightLocationModal(false);
    }

    const driverHandle = (e) => {
        setFormData({
            ...formData,
            driverId: e.target.value
        });

        driversData.drivers.forEach((driver, index) => {
            if (driver.id === e.target.value && driver?.defaultTruck?.id != null) {
                return setFormData({
                    driverId: e.target.value,
                    truckId: driver?.defaultTruck?.id
                });
            }
            return false;
        });

    }

    const truckHandle = (e) => {
        return setFormData({
            ...formData,
            truckId: e.target.value
        });
    }



    const renderTrucksDetails = () => {
        if (trucks.length > 0) {
            return trucks.map((truck, index) => {
                return (
                    <div key={truck.id} className="md:w-full mb-[20px]">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <h5 className="text-xl font-bold mb-5 px-3">Truck {index + 1} : {truck.numberPlate ? truck.numberPlate : ''}</h5>
                            <div className="md:w-full flex flex-wrap px-3">
                                <div className="w-2/6 px-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                        Select Driver
                                    </label>
                                    <div className="relative mb-3">
                                        <select value={formData.driverId} onChange={driverHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                            <option value={0}>Select Drivers</option>
                                            {
                                                driverOptions.map((driver) => {
                                                    return (
                                                        <option value={driver.value} key={driver.value}>{driver.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-2/6 px-4">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                        Select Truck
                                    </label>
                                    <div className="relative mb-3">
                                        <select value={formData.truckId} onChange={truckHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                            <option value={0}>Select Trucks</option>
                                            {
                                                truckOptions.map((truck) => {
                                                    return (
                                                        <option value={truck.value} key={truck.value}>{truck.label}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="md:w-full flex flex-wrap px-3">
                                {renderActionButtons(truck, index)}
                            </div>



                            <div className="md:w-full flex flex-wrap">
                                {renderTruckPickupRequests(truck, index)}
                            </div>
                            {currentTruckId == truck.id && <FreightLocationModal showModal={showFreightLocationModal} closeModal={closeFreightLocationModal} updateSelectedPickups={(pickupRequests) => getUpdatedPickups(pickupRequests)} />}
                        </div>
                    </div>
                )
            })
        }
        return "No truck Data Available..."
    }

    const renderActionButtons = (truck, index) => {
        if (bulkInsertData && bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests) {
            let pickupLoadedStatus = bulkInsertData?.bulkUpdatePickupRequests?.updatedPickupRequests.some((pickup) => {
                return pickup.pickupStatus == 'loaded' ? true : false;
            })
            return (
                pickupLoadedStatus ?
                    <div>
                        <button disabled={true} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">Inventory Loaded</button>
                        <button onClick={() => removeLoadedInventory()} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-900">Remove Loaded Inventory</button>

                    </div>
                    :
                    <button onClick={() => selectFreightHandle(truck)} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Select Inventory to load</button>
            )
        }
        else {
            return (<button onClick={() => selectFreightHandle(truck)} className="px-6 py-3 mr-4 mb-4 leading-5 text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Select Inventory to load</button>);
        }
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {
            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full mb-5">
                        <div className="md:w-full p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="w-full">
                                <h5 className="text-[18px] font-bold capitalize mb-4">Truck test {index + 1}: {truck.numberPlate ? truck.numberPlate : ''}</h5>
                            </div>
                            <div className="w-full">
                                {renderTruckPickupRequests(truck, index)}
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return "No truck Data Available..."
    }

    return (
        <div>
            <div className="tab-container">
                {filterValue == 'unloaded' ? <div>
                    {trucks.length > 0 ? <div className="relative z-1">
                        <div className="row pt-3 mb-4 my-2">
                            <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                            {renderTrucksDetails()}
                        </div>
                    </div>
                        :
                        <p className=' mt-7'>No Delivery Request Found...</p>
                    }
                    {upcomingTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Up Coming</h3>
                        {renderUpComingTrucksDetails()}
                    </div>}
                </div> :
                    <CompletedFreight selectedTruckTab={selectedTruckTab} newCompletedPickupRequest={pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest} pickupStatus='loaded' />
                }

            </div>
        </div>
    )
}
export default Loading;