import React, { useEffect, useState } from "react";
import GraphQuery from "../../../components/graphql/queries/Query";

const EditInvoice = ({ showloading = false, type = 'preview', invoiceCharges = [], savedTotal, previewMode, saveCharges, item, serviceType = 'Rush' }) => {

    const [lineItemgrandTotal, setLineItemGrandTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [lineItems, setLineItems] = useState([]);
    const [invoiceloading, setInvoiceLoading] = useState(false);
    const { responseData: widthSurchargeData, loading: lengthLoader } = GraphQuery({ name: 'WidthSurcharges', single: 'WidthSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: lengthSurchargeData, } = GraphQuery({ name: 'LengthSurcharges', single: 'LengthSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: serviceTypesData, } = GraphQuery({ name: 'ServiceTypes', single: 'ServiceType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id title slug cost');

    const { responseData: weightSurchargeData, } = GraphQuery({ name: 'WeightSurcharges', single: 'WeightSurcharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id minimumValue maximumValue cost');

    const { responseData: otherChargesData, } = GraphQuery({ name: 'OtherCharges', single: 'OtherCharge' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id optionName optionValue slug');

    const calculateCost = (value, surchargeData) => {
        for (const surcharge of surchargeData) {
            if (value >= surcharge.minimumValue && value <= surcharge.maximumValue) {
                return surcharge.cost;
            }
        }
        return 0;
    };

    function findServiceCharges(serviceType) {
        const serviceSurcharge = serviceTypesData.find(surcharge => surcharge.slug === serviceType.toLowerCase());
        return serviceSurcharge ? parseFloat(serviceSurcharge.cost) : 0;
    }

    useEffect(() => {
        console.log(invoiceloading, 'invoiceloading')
        if (invoiceloading == true) {
            setInvoiceLoading(showloading);
        }
    }, [showloading])

    // useEffect(() => {
    //     if (item && invoiceCharges.length === 0) {
    //         const description = item.description;
    //         const pattern = /(\d+(\.\d+)?) X (\d+(\.\d+)?) X (\d+(\.\d+)?) (\d+(\.\d+)?) LBS/;
    //         const match = description.match(pattern);

    //         if (match) {
    //             const pickup_length = parseFloat(match[1]);
    //             const pickup_width = parseFloat(match[3]);
    //             const pickup_height = parseFloat(match[5]);
    //             const pickup_weight = parseFloat(match[7]);

    //             const lengthCost = calculateCost(pickup_length, lengthSurchargeData);
    //             const widthCost = calculateCost(pickup_width, widthSurchargeData);
    //             const weightCost = calculateCost(pickup_weight, weightSurchargeData);

    //             const serviceCharges = findServiceCharges(serviceType);

    //             setLineItems([
    //                 { description: 'Length Surcharges', quantity: item?.quantity || 1, unitPrice: lengthCost || 0.0, amount: (item?.quantity * lengthCost) || 0.0 },
    //                 { description: 'Width Surcharges', quantity: item?.quantity || 1, unitPrice: widthCost || 0.0, amount: (item?.quantity * widthCost) || 0.0 },
    //                 { description: 'Weight Surcharges', quantity: item?.quantity || 1, unitPrice: weightCost || 0.0, amount: (item?.quantity * weightCost) || 0.0 },
    //                 { description: `Service Surcharges - ${serviceType}`, quantity: item?.quantity || 1, unitPrice: serviceCharges || 0.0, amount: (item?.quantity * serviceCharges) || 0.0 },
    //             ]);
    //         } else {
    //             console.error("Description format is incorrect");
    //         }
    //     }
    // }, [item, lengthSurchargeData, widthSurchargeData, weightSurchargeData]);

    // useEffect(() => {
    //     if ((otherChargesData && item) && invoiceCharges.length === 0) { // Check length instead of comparing with []
    //         const transformedLineItems = otherChargesData.map(charge => ({
    //             description: charge.optionName,
    //             quantity: item?.quantity || 1,
    //             unitPrice: parseFloat(charge.optionValue),
    //             amount: (item?.quantity * parseFloat(charge.optionValue)) || 0.0,
    //         }));

    //         setLineItems(prevLineItems => [...prevLineItems, ...transformedLineItems]);
    //     }
    // }, [otherChargesData, item]);

    useEffect(() => {
        console.log(invoiceCharges)
        if (invoiceCharges.length > 0) {
            const deepCopy = invoiceCharges.map(charge => ({
                description: charge.description,
                quantity: charge?.quantity,
                unitPrice: charge.unitPrice,
                amount: charge.amount
            }));
            setLineItems(deepCopy);
            setGrandTotal(savedTotal);
        }
    }, [invoiceCharges]);


    useEffect(() => {
        if (serviceTypesData) {
            // setServiceTypeCharges(serviceTypesData)
        }
    }, [serviceTypesData]);

    const numberFormat = (number) => {
        if (typeof (number) === 'number') {
            return number.toFixed(2);
        }
    }

    const removeLineItemHandle = (index) => {
        let updatedLineItems = [...lineItems];
        updatedLineItems.splice(index, 1);
        setLineItems(updatedLineItems)
    }
    const lineItemInputHandle = (field, index, event) => {
        // let updatedlineItems = [...lineItems]
        // if (e.target.value !== '') {
        //     let value = e.target.value
        //     if (property === 'amount') {
        //         value = parseFloat(e.target.value)
        //     }
        //     updatedlineItems[index][property] = value
        // }
        // setLineItems(updatedlineItems);


        let value = event.target.value || 0;
        const updatedLineItems = [...lineItems];
        if (field !== 'description') {
            value = parseFloat(value)
        }
        updatedLineItems[index][field] = value;

        // Calculate the amount for the updated line item
        if (field === 'quantity' || field === 'unitPrice') {
            updatedLineItems[index].amount = parseFloat(updatedLineItems[index].quantity) * parseFloat(updatedLineItems[index].unitPrice);
        }
        if (field === 'amount') {
            updatedLineItems[index].unitPrice = parseFloat(updatedLineItems[index].amount) / parseFloat(updatedLineItems[index].quantity);
        }

        setLineItems(updatedLineItems);

    }
    const renderLineItems = () => {
        if (type === 'invoice-by-grand-total' || type === 'preview') {
            return lineItems.map((item, index) => {
                return (<div key={index} className="flex items-start justify-start mb-0 border-b">
                    <div className="md:w-[40%] py-1 px-3">
                        <p>{item.description}</p>
                        {/* <textarea onChange={(e) => lineItemInputHandle('description', index, e)} value={item.description} className="w-full border border-slate-500 rounded-lg px-3"></textarea> */}
                    </div>
                    {type === 'preview' && <div className="md:w-[15%] py-1 px-4">
                        <p className="text-lg">{item.quantity}</p>
                    </div>}
                    {type === 'preview' && <div className="md:w-[20%] py-1 px-4">
                        <p className="text-lg">{item.unitPrice}</p>
                    </div>}
                    {type === 'preview' && <div className="md:w-[15%] py-1 px-4">
                        <p className="text-lg">${item.amount}</p>
                    </div>}
                </div>)
            })
        }
        return (
            lineItems.map((item, index) => (
                <div key={index} className="flex items-start justify-start mb-2 border-b">
                    <div className="md:w-[40%] py-1 px-4">
                        <textarea
                            onChange={(e) => lineItemInputHandle('description', index, e)}
                            value={item.description}
                            className="w-full border h-[38px] min-h-[38px] text-md border-slate-500 rounded-lg px-3"
                        ></textarea>
                    </div>
                    <div className="md:w-[15%] py-1 px-2">
                        <input
                            onChange={(e) => lineItemInputHandle('quantity', index, e)}
                            min={0}
                            value={item.quantity}
                            placeholder="0"
                            className="text-md border border-slate-500 rounded-lg py-1 px-3 w-full"
                            type="text"
                        />
                    </div>
                    <div className="md:w-[20%] py-1 px-2">
                        <input
                            onChange={(e) => lineItemInputHandle('unitPrice', index, e)}
                            min={0}
                            value={item.unitPrice ? item.unitPrice : ''}
                            placeholder="0.00"
                            className="text-md border border-slate-500 rounded-lg py-1 px-3 w-full"
                            type="number"
                        />
                    </div>
                    <div className="md:w-[25%] py-1 px-2 flex items-center justify-start">
                        <input
                            min={0}
                            value={item.amount ? item.amount : ''}
                            onChange={(e) => lineItemInputHandle('amount', index, e)}
                            placeholder="0.00"
                            className="text-md border border-slate-500 rounded-lg py-1 px-3 w-[90%]"
                            type="number"
                        />
                        <div className="ml-3 w-[20%]">
                            {index !== 0 && (
                                <button
                                    onClick={() => removeLineItemHandle(index)}
                                    className="flex items-center rounded-md bg-red-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="fill-white cursor-pointer"
                                        height="25"
                                        width="17"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ))
        );
    }

    const addNewLineItemHandle = () => {
        let newLineItems = [...lineItems, { description: '', quantity: 1, amount: 0.00 }]
        setLineItems(newLineItems);
    }

    useEffect(() => {
        if (lineItems.length > 0 && type == 'invoice-by-line-item') {
            let subTotal = 0;
            lineItems.map(item => subTotal = subTotal + parseFloat(item.amount));
            setGrandTotal(subTotal);
        }
    }, [lineItems, type])

    const saveHandle = () => {
        let charges = [];
        let TotalPrice = grandTotal;
        setInvoiceLoading(true);
        if (type === 'invoice-by-line-item') {
            charges = [...lineItems];
        }
        if (type === 'invoice-by-grand-total') {
            charges = [...lineItems];
            charges = charges.map((item, index) => {
                return { description: item.description, quantity: item.quantity, unitPrice: 0, amount: 0 }
            })
            // charges = [{ description: 'Grand Total', quantity: 1, unitPrice: grandTotal, amount: grandTotal }];
        }
        saveCharges(charges, type, TotalPrice)
    }


    return (
        <div>
            <div className="p-5 border rounded-lg">
                {type !== 'preview' && <h4 className='text-lg font-semibold'>Generate Invoice By {type === 'invoice-by-line-item' ? 'Line Items' : 'Grand Total'}</h4>}
                {type === 'preview' && <h4 className='text-lg font-semibold'>Invoice Charges</h4>}
                <div className="w-full mt-2">
                    <div className="flex items-center justify-start border-b-2">
                        <div className="md:w-[40%] pb-3 pt-2">
                            <h4 className="text-md font-semibold">Description</h4>
                        </div>
                        <div className="md:w-[15%] pb-3 pt-2 px-4">
                            {(type === 'invoice-by-line-item' || type === 'preview') && <h4 className="text-md font-semibold">Quantity</h4>}
                        </div>
                        <div className="md:w-[20%] pb-3 pt-2 px-4">
                            {(type === 'invoice-by-line-item' || type === 'preview') && <h4 className="text-md font-semibold">Price Per Quantity</h4>}
                        </div>
                        <div className="md:w-[25%] pb-3 pt-2 px-4">
                            {(type === 'invoice-by-line-item' || type === 'preview') && <h4 className="text-md font-semibold">Amount ($)</h4>}
                        </div>
                    </div>
                    {renderLineItems()}
                </div>
                {type === 'invoice-by-line-item' && <div>
                    <button onClick={() => addNewLineItemHandle()} className="rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="ml-2">Add New Line Item</span>
                    </button>
                </div>}
                <div className="flex items-center justify-start border-b-2">
                    <div className="md:w-[60%] py-1 px-3">
                        <h4 className="text-xl font-semibold"></h4>
                    </div>
                    <div className="md:w-[15%] py-1 px-3">
                        <h4 className="text-md font-semibold">SubTotal:</h4>
                    </div>
                    <div className="md:w-[15%] py-1 px-3 flex items-center">
                        <span className="text-md font-semibold">$</span>{(type === 'invoice-by-line-item' || type === 'preview') && <h4 className="text-md font-semibold">{grandTotal?.toFixed(2)}</h4>}

                        {type === 'invoice-by-grand-total' && <input value={grandTotal} onChange={(e) => setGrandTotal(parseFloat(e.target.value !== '' ? e.target.value : 0))} placeholder="0.00" className="text-md border border-slate-500 rounded-lg py-2 px-3 w-full" type="number" />}
                    </div>
                </div>
                <div className="flex items-center justify-start border-b-2">
                    <div className="md:w-[60%] py-3 px-4">
                        <h4 className="text-md font-semibold"></h4>
                    </div>
                    <div className="md:w-[15%] py-3 px-4">
                        <h4 className="text-md font-semibold">Grand Total:</h4>
                    </div>
                    <div className="md:w-[15%] py-3 px-4">
                        <h4 className="text-md font-semibold">${grandTotal?.toFixed(2)}</h4>
                    </div>
                </div>
                {/* <div className="flex items-center justify-start border-b-2 bg-green-300">
                    <div className="md:w-[60%] py-3 px-4">
                        <h4 className="text-md font-semibold"></h4>
                    </div>
                    <div className="md:w-[15%] py-3 px-4">
                        <h4 className="text-md font-semibold">Saved Total:</h4>
                    </div>
                    <div className="md:w-[15%] py-3 px-4">
                        <h4 className="text-md font-semibold">${savedTotal?.toFixed(2)}</h4>
                    </div>
                </div> */}
                {type !== 'preview' && <div className="flex items-center justify-end mt-8">
                    <button onClick={() => previewMode()} className="rounded-md mr-4 bg-slate-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> */}
                        <span className="">Cancel</span>
                    </button>
                    <button onClick={() => saveHandle()} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> */}
                        <span className="">{invoiceloading ? 'loading...' : 'Save'}</span>
                    </button>
                </div>}
            </div>
        </div>
    )
}
export default EditInvoice;