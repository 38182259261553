import React, { useState, useEffect } from 'react';

import PageHeading from "../../components/PageHeading";

import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import GraphMutation from '../../components/graphql/mutations/Mutation';
import GraphQuery from '../../components/graphql/queries/Query';

function PriceTierCreatePage() {
    const loading = useState(false);
    const navigate = useNavigate();
    const [serviceTypeOptions, setServiceTypeOptions] = useState([])
    const [formData, setFormData] = useState({
        title: '',
        status: 'inactive',
        priceTierRules: [
            { type: 'price', status: 'inactive', amountMinimumValue: '0', amountMaximumValue: '', ruleType: 'percentage', ruleTypeValue: '10', markupType: '', serviceTypes: [] }
        ],
    });

    const { executeMutation: createPriceTier, responseData: priceTierData, errors: priceTierError } = GraphMutation(
        { name: 'PriceTier', expectedResponseField: 'priceTier', method: 'create' },
        { name: 'priceTier', type: 'CreatePriceTierInput' },
        `
            id,
            title,
            status,
            priceTierRules {
                id,
                status,
                amountMinimumValue,
                amountMaximumValue,
                ruleType,
                ruleTypeValue,
                type,
                serviceTypes,
                markupType,
                serviceTypes
            }
        `
    );

    const { responseData: serviceTypesData, } = GraphQuery({ name: 'ServiceTypes', single: 'ServiceType' }, {
        // first: 10,
        skip: 0,
        search: '',
        sortBy: 'id',
        order: 'desc',
    }, 'id title slug cost');

    useEffect(() => {
        if (serviceTypesData) {
            setServiceTypeOptions(serviceTypesData)
        }
    }, [serviceTypesData, setServiceTypeOptions]);

    useEffect(() => {
        if (priceTierData && priceTierData.priceTierRules) {
            window.location.href = '/admin/price-tiers';
        }
    }, [priceTierData])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let input = {
            title: formData.title,
            status: formData.status,
            priceTierRules: formData.priceTierRules,
        }
        console.log("data saving", input);
        await createPriceTier(input);
    }

    const handleCancel = () => {
        navigate("/admin/price-tiers");
    }

    const handleChange = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.value
        })
        console.log(e.target.value, 'handle change', formData);
    }

    const checkboxChangeHandle = (property, e) => {
        setFormData({
            ...formData,
            [property]: e.target.checked === true ? 'active' : 'inactive'
        })
        console.log(e.target.value, 'handle change', formData);
    }

    const priceTierRuleServicecheckboxChangeHandle = (property, value, index) => {
        console.log('checked value', value);
        let priceTierRules = [...formData?.priceTierRules];
        if (priceTierRules[index].serviceTypes.includes(value)) {
            let services = priceTierRules[index]?.serviceTypes?.filter(service => service !== value);
            priceTierRules[index].serviceTypes = services
        }
        else {
            priceTierRules[index]?.serviceTypes?.push(value)
        }
        setFormData({ ...formData, priceTierRules: priceTierRules })
    }

    const priceTierRulecheckboxChangeHandle = (property, e, index) => {
        const updatedPriceTierRules = [...formData.priceTierRules];
        updatedPriceTierRules[index][property] = e.target.checked ? 'active' : 'inactive';

        setFormData({
            ...formData,
            priceTierRules: updatedPriceTierRules
        })
    }

    const priceTierRuleChangeHandle = (property, e, index) => {
        const updatedPriceTierRules = [...formData.priceTierRules];
        updatedPriceTierRules[index][property] = e.target.value;

        setFormData({
            ...formData,
            priceTierRules: updatedPriceTierRules
        })
    }

    const renderServiceTypeOptions = (priceTierRule, ptierRuleIndex) => {
        if (priceTierRule.type === 'service') {
            return serviceTypeOptions?.map((option, index) => {
                return (
                    <div key={option.slug} className="md:w-2/6 px-3 mb-3 items-center">
                        <div className="inline-flex items-center">
                            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_price_tier_rule_service_type_' + option.slug + '_' + ptierRuleIndex}>
                                <input onChange={() => priceTierRuleServicecheckboxChangeHandle('serviceTypes', option.slug, ptierRuleIndex)} checked={formData?.priceTierRules[ptierRuleIndex]?.serviceTypes?.includes(option.slug)} type="checkbox"
                                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                    id={'check_price_tier_rule_service_type_' + option.slug + '_' + ptierRuleIndex} />
                                <span
                                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                        stroke="currentColor" strokeWidth="1">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </span>
                            </label>
                            <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_price_tier_rule_service_type_' + option.slug + '_' + ptierRuleIndex}>
                                {option.title}
                            </label>
                        </div>
                    </div>
                )
            })
        }
        else {
            return '';
        }

    }

    const renderPriceTierRules = () => {
        return formData.priceTierRules.map((priceTierRule, index) => {
            return (
                <div key={index} className='w-full py-3 px-2 border rounded-lg mb-4'>
                    <div className='relative px-3'>
                        <label className="block uppercase tracking-wide text-grey-darker text-md font-bold mb-2" htmlFor="price-tier-title">
                            Price Tier Rule {index + 1}
                        </label>
                        {index > 0 && <div className="absolute z-20 top-4 right-2 flex items-center justify-center">
                            <svg onClick={(e) => removePriceTierRule(e, index)} className="fill-slate-500 hover:fill-red-500" width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>}
                        <div className="md:w-3/6 mb-3 items-center">
                            <div className="md:w-full">
                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-rule-type">
                                    Select Type
                                </label>
                                <div className="relative mb-2">
                                    <select value={priceTierRule.type} onChange={(e) => priceTierRuleChangeHandle('type', e, index)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="price_tier_type">
                                        <option value="price">Price</option>
                                        <option value="weight">Weight</option>
                                        <option value="width">Width</option>
                                        <option value="service">Service Type Charges</option>
                                    </select>
                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>
                            {priceTierError && priceTierError[`priceTierRule.${index}.type`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.type`]}</p>}
                        </div>
                    </div>
                    <div className='flex items-center justify-start'>
                        {priceTierRule.type !== 'service' && <div className='md:w-2/6 flex items-center justify-start'>
                            <div className="md:w-3/6 px-3 mb-3 items-center">
                                <div className="md:w-full">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-amount-minimum-value">
                                        Minimum Amount Value
                                    </label>
                                    <div className="relative">
                                        <input value={priceTierRule.amountMinimumValue} onChange={(value) => priceTierRuleChangeHandle('amountMinimumValue', value, index)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="price-tier-amount-minimum-value" type="text" autoComplete="on" placeholder="Minimum Amount" required />
                                    </div>
                                </div>
                                {priceTierError && priceTierError[`priceTierRule.${index}.amountMinimumValue`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.amountMinimumValue`]}</p>}
                            </div>
                            <div className="md:w-3/6 px-3 mb-3 items-center">
                                <div className="md:w-full">
                                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-amount-maximum-value">
                                        Maximum Amount Value
                                    </label>
                                    <div className="relative">
                                        <input value={priceTierRule.amountMaximumValue} onChange={(value) => priceTierRuleChangeHandle('amountMaximumValue', value, index)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="price-tier-amount-maximum-value" type="text" autoComplete="on" placeholder="Maximum Amount" required />
                                    </div>
                                </div>
                                {priceTierError && priceTierError[`priceTierRule.${index}.amountMaximumValue`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.amountMaximumValue`]}</p>}
                            </div>
                        </div>}
                        <div className="md:w-1/6 px-3 mb-3 items-center">
                            <div className="md:w-full">
                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-rule-type">
                                    Select Discount Type
                                </label>
                                <div className="relative mb-2">
                                    <select value={priceTierRule.ruleType} onChange={(e) => priceTierRuleChangeHandle('ruleType', e, index)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="truck_zone">
                                        <option value="null">Select Type</option>
                                        <option value={'percentage'}>Percentage</option>
                                        <option value={'fixed'}>Fixed</option>
                                    </select>
                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>
                            {priceTierError && priceTierError[`priceTierRule.${index}.ruleType`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.ruleType`]}</p>}
                        </div>
                        <div className="md:w-1/6 px-3 mb-3 items-center">
                            <div className="md:w-full">
                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-markup-type">
                                    Markup Type
                                </label>
                                <div className="relative mb-2">
                                    <select value={priceTierRule.markupType} onChange={(e) => priceTierRuleChangeHandle('markupType', e, index)} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="price_tier_type">
                                        <option value="up">Up</option>
                                        <option value="down">Down</option>
                                    </select>
                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                            </div>
                            {priceTierError && priceTierError[`priceTierRule.${index}.markupType`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.markupType`]}</p>}
                        </div>
                        <div className="md:w-1/6 px-3 mb-3 items-center">
                            <div className="md:w-full">
                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-rule-type-value">
                                    Discount Amount
                                </label>
                                <div className="relative mb-2">
                                    <input value={priceTierRule.ruleTypeValue} onChange={(value) => priceTierRuleChangeHandle('ruleTypeValue', value, index)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="price-tier-rule-type-value" type="text" autoComplete="on" placeholder="Maximum Amount" required />
                                </div>
                            </div>
                            {priceTierError && priceTierError[`priceTierRule.${index}.ruleTypeValue`] && <p className="text-red-500 text-xs italic">{priceTierError[`priceTierRule.${index}.ruleTypeValue`]}</p>}
                        </div>

                        <div className="md:w-1/6 px-3 flex items-center">
                            <div className="inline-flex items-center">
                                <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_price_tier_rule_status'}>
                                    <input onChange={(value) => priceTierRulecheckboxChangeHandle('status', value, index)} checked={formData?.priceTierRules[index]?.status === 'inactive' ? false : true} type="checkbox"
                                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                        id={'check_price_tier_rule_status'} />
                                    <span
                                        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                            stroke="currentColor" strokeWidth="1">
                                            <path fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clipRule="evenodd"></path>
                                        </svg>
                                    </span>
                                </label>
                                <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_price_tier_rule_status'}>
                                    Status
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center justify-start'>
                        {renderServiceTypeOptions(priceTierRule, index)}
                    </div>

                </div>
            )
        })
    }

    const addPriceTierRuleHandle = () => {
        let updatedPriceTierRules = [];
        updatedPriceTierRules = formData.priceTierRules;
        updatedPriceTierRules.push({ status: 'inactive', amountMinimumValue: '0', amountMaximumValue: '', ruleType: 'percentage', ruleTypeValue: '0', serviceTypes: [] })
        setFormData({
            ...formData,
            priceTierRules: updatedPriceTierRules,
        })
    }

    const removePriceTierRule = (e, idx) => {
        e.preventDefault();
        let filteredPriceTierRules = formData.priceTierRules.filter((priceTierRule, index) => index !== idx);
        console.log('Filetered Price rule', filteredPriceTierRules);
        return setFormData({
            ...formData,
            priceTierRules: filteredPriceTierRules,
        })
    }

    // const priceTierStatusHandle = (event) => {
    //     const { value, checked } = event.target;
    //     var selectedFeatures = formData.selectedTruckFeatures;
    //     if (checked) {
    //         selectedFeatures.push(value);
    //     } else {
    //         selectedFeatures = selectedFeatures.filter((id) => id !== value);
    //     }
    //     setFormData({
    //         ...formData,
    //         selectedTruckFeatures: selectedFeatures,
    //     });
    // };

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Add New Price Tier</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    {loading === true ?
                        <Loader />
                        : <div className="relative rounded-xl overflow-auto">
                            <div className="shadow-sm overflow-hidden mt-7 px-4">
                                {
                                    priceTierData && priceTierData.priceTierRules && <div className="text-green-500">Price Tier added successfully.</div>
                                }

                                {/* Create Driver Form  */}
                                <div className="row">
                                    <div className="md:w-2/4">
                                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">

                                            <div className="-mx-3 md:flex mb-6">
                                                <div className="md:w-2/4 px-3 flex items-center">
                                                    <div className='md:w-full'>
                                                        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="price-tier-title">
                                                            Title
                                                        </label>
                                                        <input value={formData.title} onChange={(value) => handleChange('title', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="price-tier-title" type="text" autoComplete="on" placeholder="Price Tier Title" required />
                                                        {priceTierError && priceTierError.title && <p className="text-red-500 text-xs italic">{priceTierError.title}</p>}
                                                    </div>

                                                </div>
                                                <div className="md:w-2/4 px-3 flex items-center">
                                                    <div className="inline-flex items-center">
                                                        <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={'check_price_tier_status'}>
                                                            <input onChange={(value) => checkboxChangeHandle('status', value)} checked={formData.status === 'active' ? true : false} type="checkbox"
                                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                                id={'check_price_tier_status'} />
                                                            <span
                                                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                                                                    stroke="currentColor" strokeWidth="1">
                                                                    <path fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                        clipRule="evenodd"></path>
                                                                </svg>
                                                            </span>
                                                        </label>
                                                        <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={'check_price_tier_status'}>
                                                            Status
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="md:w-1/4 px-3 flex items-end justify-end mb-4">
                                                    <button onClick={() => addPriceTierRuleHandle()} type="submit" className="rounded-md bg-indigo-600 py-3 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        <span className="ml-2">Add New Rule</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="-mx-3 flex flex-wrap mb-6">
                                                {renderPriceTierRules()}
                                            </div>

                                            <div className="flex justify-end mt-6">
                                                <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                                <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default PriceTierCreatePage;