import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { gql, useMutation } from '@apollo/client';

const GraphMutation = (modelName = { method: 'create' }, inputType, output = 'id') => {
    const { token } = useContext(AuthContext);
    const [responseData, setResponseData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    // Define the method mapping
    const modelMethodNames = { update: 'Update', create: 'Create', delete: 'Delete' };

    // Generate dynamic GraphQL mutation
    const DYNAMIC_MUTATION = gql`
        mutation ${modelMethodNames[modelName.method] + modelName.name}(
            ${modelName.method === 'update' || modelName.method === 'delete' ? '$id: ID!' : ''}
            ${modelName.method === 'update' || modelName.method === 'create' ? `$${inputType.name}New: ${inputType.type}!` : ''}
        ) {
            ${modelName.method}${modelName.name}(
                ${modelName.method === 'update' || modelName.method === 'delete' ? 'id: $id' : ''}
                ${modelName.method === 'update' || modelName.method === 'create' ? `${modelName.method === 'delete' ? '' : `${inputType.name}Data: $${inputType.name}New`}` : ''}
            ) {
                ${modelName.expectedResponseField} {
                    ${output}
                }
            }
        }
    `;

    // Mutation hook with dynamic context (headers)
    const [mutateFunction] = useMutation(DYNAMIC_MUTATION, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
        onError: (error) => {
            let parsedError;
            try {
                parsedError = JSON.parse(error.message); // Try parsing the error message
            } catch (parseError) {
                parsedError = { message: error.message }; // Default to raw error message
            }
            setErrors(parsedError);
        },
        onCompleted: (data) => {
            const responseKey = `${modelName.method}${modelName.name}`;
            if (data && data[responseKey]) {
                setResponseData(data[responseKey][modelName.expectedResponseField]); // Set response data
            }
            setErrors(null); // Reset errors on success
        },
        onMutate: () => setLoading(true), // Set loading true before request
        onFinally: () => setLoading(false), // Set loading false after request completion
    });

    // Function to execute mutation
    const executeMutation = async (variables, id = null) => {
        setErrors(null); // Reset errors before mutation
        try {
            const mutationVariables = {
                [`${inputType.name}New`]: variables, // Add main input data
            };
            if (id) mutationVariables.id = id; // Add ID for update/delete

            await mutateFunction({ variables: mutationVariables }); // Execute mutation
        } catch (e) {
            console.error('Mutation error:', e); // Log error for debugging
            setErrors(e); // Update error state
        }
    };

    return { executeMutation, responseData, loading, errors }; // Return states and function
};

export default GraphMutation;
