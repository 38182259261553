// index.js
import './index.css';
import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink, ApolloLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { GRAPHQL_API_URL, WEBSOCKET_API_URL } from './constants';
import { AuthContext } from './context/authContext';
const token = localStorage.getItem('token');

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WEBSOCKET_API_URL,
    connectionParams: {
      authToken: `${token}`,
    },
    keepAlive: 10000,  // Keep alive every 10 seconds
    on: {
      connected: () => console.log("connected client"),
      closed: () => console.log("closed"),
    },
    onOpen: () => {
      console.log('WebSocket connection opened');
    },
    onError: (error) => {
      console.error('WebSocket Error:', error);
    },
    onClose: (closeCode) => {
      console.log(`WebSocket closed with code: ${closeCode}`);
    },
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.some(({ message }) => message === "Signature has expired")) {
    // alert("Session expired. Please log in again.");
    localStorage.removeItem('token');
    window.location.href = '/users/login';
  }

  if (networkError) console.error(`[Network error]: ${networkError}`);
});


const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  // link: splitLink,
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
