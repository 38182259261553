import TopNavigation from '../../components/TopNavigation';
import TopBar from '../../components/TopBar';
import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import bellIcon from '../../assets/bell-icon.svg';
import { useSubscription, gql, useMutation } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification($id: ID!) {
        updateNotification (id: $id) {
            notification {
                id
                title
                description
                status
                readAt
                action
                objectId
                createdAt
            }
        }
    }
`;

function AdminLayout(props) {
    const [showNotificationSideBar, setShowNotificationSideBar] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const { token } = useContext(AuthContext);
    const [notificationUpdatedFlag, setNotificationUpdatedFlag] = useState(false);
    const [updateNotification, { data: updateNotificationData, loading: updateNotificationLoading, error: updateNotificationError }] = useMutation(UPDATE_NOTIFICATION);

    const notificationToggleHandle = (data) => {
        setNotifications(data)
        setShowNotificationSideBar((prev) => !prev);
    }

    const timeAgo = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        const intervals = {
            year: 31536000,
            month: 2592000,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (let key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
            }
        }
        return 'just now';
    };

    const notificationDetailPageHandle = (notification) => {
        if (notification.status == 'unread') {
            updateNotification({
                context: {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                },
                variables: { id: notification.id }
            });
        }
        // refetchData({ fetchPolicy: 'network-only' });
        // setShowNotificationSideBar(false);

    }

    useEffect(() => {
        console.log(updateNotificationData);
        if (updateNotificationData?.updateNotification) {
            let updatedNotification = updateNotificationData?.updateNotification?.notification
            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id == updatedNotification.id
                        ? updatedNotification
                        : notification
                )
            );
            setNotificationUpdatedFlag(true);
        }
    }, [updateNotificationData])

    return (
        // <div className="mx-auto py-2 px-0">
        //     <div className="px-3 lg:px-8 border-b">
        //         <TopBar />
        //         <TopNavigation />
        //     </div>
        //     <div className="">
        //         <Outlet />
        //     </div>
        // </div>

        <div className="mx-auto py-2 px-0">
            <div className="flex">
                {/* Main Content */}
                <div
                    className="transition-all duration-150 ease-in"
                    style={{ width: showNotificationSideBar ? '83.333%' : '100%' }}
                >
                    <div className="px-3 lg:px-8 border-b">
                        <TopBar notificationUpdatedFlag={notificationUpdatedFlag} notificationDropdownToggle={(data) => notificationToggleHandle(data)} />
                        <TopNavigation />
                    </div>
                    <div>
                        <Outlet />
                    </div>
                </div>

                {/* Notification Sidebar */}
                <div
                    className={`transition-all duration-150 ease-in overflow-hidden`}
                    style={{
                        width: showNotificationSideBar ? '16.667%' : '0%',
                        opacity: showNotificationSideBar ? 1 : 0,
                    }}
                >
                    <div className={showNotificationSideBar ? 'block' : 'hidden'}>
                        <h4 className="text-xl">Notifications</h4>
                        <hr />
                        {notifications.length > 0 && <div className="py-1 h-[450px] overflow-y-scroll">

                            {notifications?.map((notification, index) => (
                                <a
                                    key={notification.id}
                                    onClick={() => notificationDetailPageHandle(notification)}
                                    // href="#"
                                    className={`flex items-center justify-start ${notification.status === 'unread' ? 'bg-blue-200' : 'bg-white'} px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b`}
                                >
                                    <div className='w-1/6 flex items-center justify-center'>
                                        <img className="" src={bellIcon} alt="Notifications" />
                                    </div>
                                    <div className='w-5/6'>
                                        <strong className='capitalize'>{notification.title}</strong>
                                        <p>
                                            {notification.description}
                                        </p>
                                        <small className="text-gray-500">{timeAgo(notification.createdAt)}</small>
                                    </div>
                                </a>
                            ))}
                        </div>}
                        {notifications.length == 0 && <div className="py-1">
                            <p className='px-2'>No Notification Found...</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AdminLayout;