import React, { useState, useEffect, useContext } from 'react';
import { gql, useLazyQuery, useMutation, useQuery, } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import GraphQuery from '../graphql/queries/Query';
import GraphMutation from '../graphql/mutations/Mutation';
import OtherLocationModel from './OtherLocationModal';
import SuggestedLocations from './SuggestedLocations';
import CompletedFreight from './CompletedFreight';
import ReportIssueModal from './ReportIssueModal';
import { BACKEND_API_URL } from '../../constants';
import ImageLightBoxModal from './ImageLightBoxModal';

const Unload = ({ filterValue, selectedTruckTab, allTrucks, checkinTruck, goBack, reportUnloadIssue }) => {
    const { token } = useContext(AuthContext);
    const [trucks, setTrucks] = useState([]);
    const [unloadStatus, setUnloadStatus] = useState([]);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showOtherLocationModal, setShowOtherLocationModal] = useState(false);
    const [showReportIssueModalId, setShowReportIssueModalId] = useState(null);
    const [currentPickup, setCurrentPickup] = useState(null);
    const [storageLocation, setStorageLocation] = useState(null);
    const [otherLocationModalId, setOtherLocationModalId] = useState(null);
    const [upcomingTrucks, setUpcomingTrucks] = useState([]);
    const [errors, setErrors] = useState(null);
    const [lighBoxImage, setLighBoxImage] = useState(null)
    const [lighBoxImageCaption, setLighBoxImageCaption] = useState(null)
    const [showImageLightBoxModal, setImageLightBoxModal] = useState(false)

    const UPDATE_PICKUPREQUEST = gql`
        mutation ($id: ID!, $status: String, $locationId: ID) {
            updatePickupRequest (id: $id,status: $status, locationId: $locationId) {
                pickupRequest {
                    id,
                    pickupTime,
                    pickupDate,
                    shipmentId,
                    pickupStatus,
                    storageLocation {
                        title
                        id
                    }
                    truck {
                        id
                    },
                    driver {
                        id,
                        user {
                            firstName,
                            lastName
                        }
                    },
                    pickupRequestLogs {
                        id,
                        action,
                        description,
                        createdAt,
                    }
                }
            }
        }
    `;

    const GET_TRUCK = gql`
        query GetTruck($id: Int!,$pickupStatus: [String]) {
            truck(id: $id) {
                id,
                numberPlate,
                make,
                model,
                color
                assignedPickupRequests(pickupStatus:$pickupStatus){ 
                    id,
                    createdAt,
                    apiMode,
                    shipmentDate,
                    pickupDate,
                    pickupTime,
                    pickupNumber,
                    shipmentTerms,
                    pickupStatus,
                    remarks
                    truck {
                        id,
                        numberPlate,
                        model,
                    }
                    storageLocation{
                        id
                        title
                    }
                    customer {
                        contactPersonFullName,
                        businessName
                    }
                    account {
                        id,
                        companyName,
                    },
                    pickupRequestAddresses {
                        id,
                        companyName,
                        addressType,
                        address1,
                        address2,
                        city,
                        state,
                        zipCode,
                    },
                    pickupRequestItems {
                        id,
                        description,
                        quantity,
                        weight,
                        width,
                        height,
                        length,
                        pallets,
                        shipmentClass,
                        charges
                    },
                }
            }
        }
    `;

    const GET_LOCATIONS_BY_ZIP_CODE = gql`
        query GetLocationByZipCode($zipCode: String, $sortBy: LocationsByZipCodeSortInput) {
            locationsByZipCode(zipCode: $zipCode, sortBy: $sortBy) {
                id
                title
                zone {
                    id
                    title
                }
            }
        }
    `;

    const [fetchLocationByZipCode, { data: locationsByZipCodeData }] = useLazyQuery(GET_LOCATIONS_BY_ZIP_CODE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });


    const [fetchTruck, { data: truckData }] = useLazyQuery(GET_TRUCK, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    const [updatePickupRequest, { pickupRequestLoading, pickupRequestError, data: pickupRequestUpdatedData }] = useMutation(UPDATE_PICKUPREQUEST);

    useEffect(() => {
        let filteredUpcomingTrucks = allTrucks.filter((truck, index) => {
            return truck.id != checkinTruck.id;
        });
        setUpcomingTrucks(filteredUpcomingTrucks);
    }, [setUpcomingTrucks, allTrucks, selectedTruckTab]);

    useEffect(() => {
        if (checkinTruck && checkinTruck.assignedPickupRequests.length > 0) {
            
            setTrucks([checkinTruck])
        }
        else {
            setTrucks([]);
        }
    }, [checkinTruck, setTrucks]);

    

    useEffect(() => {
        if (truckData && truckData?.truck) {
            setTrucks([truckData.truck])
        }
    }, [truckData]);

    const renderPickupRequestItems = (pickupItems) => {
        if (pickupItems.length > 0) {
            return pickupItems.map((pickupItem, index) => {
                return (
                    <div key={pickupItem.id} className="w-full justify-start">
                        <div className='w-full my-[8px]'>
                            <span className="font-inter font-medium text-[16px] text-[#374151]">Pickup Item# {index + 1}</span>
                        </div>
                        <div className='flex flex-wrap w-full'>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Weight:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupItem.weight} lbs</span>
                            <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Dimensions:</span>
                            <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupItem.length + ' X ' + pickupItem.width + ' X ' + pickupItem.height}</span>
                        </div>
                    </div>
                )
            })
        }

    }

    const closeReportIssueModal = () => {
        setShowReportIssueModal(false);
    }

    const showReportIssueModalHandle = (id) => {
        setShowReportIssueModal(true);
        setShowReportIssueModalId(id);
    }

    const unloadStatusHandle = (index, pickupId) => {
        let updatedUnloadStatus = [...unloadStatus, pickupId]
        setUnloadStatus(updatedUnloadStatus);
        setCurrentPickup(pickupId);
    }

    const confirmLocationHandle = (truckStorageLocationId, pickupId, status) => {

        if (truckStorageLocationId == 0 && storageLocation != null) {
            truckStorageLocationId = storageLocation[pickupId];
        }
        // truckStorageLocationId = truckStorageLocationId == 0 ? storageLocation[pickupId] : truckStorageLocationId;
        updatePickupRequest({
            context: {
                headers: {
                    'Authorization': `JWT ${token}`,
                },
            },
            variables: {
                id: pickupId,
                locationId: truckStorageLocationId,
                status: status,
            }
        });
        // }
        if (showOtherLocationModal == true) {
            setShowOtherLocationModal(false);
            setOtherLocationModalId(null);
        }

    }

    const chooseOtherLocationHandle = (pickupId) => {
        setShowOtherLocationModal(true);
        setOtherLocationModalId(pickupId)
    }

    const closeOtherLocationModal = () => {
        setShowOtherLocationModal(false);
        setOtherLocationModalId(null);
    }


    const setSuggestedLocationHandle = (location, pickupId) => {
        if (location != null) {
            setStorageLocation(prevStorageLocation => ({
                ...prevStorageLocation,
                [pickupId]: location.id
            }));
        }
    }

    const closeImageLightBoxModal = () => {
        setImageLightBoxModal(false);
    }

    const showImageLightBox = (pickupImage, caption) => {
        setLighBoxImage(pickupImage?.image)
        setLighBoxImageCaption(caption)
        setImageLightBoxModal(true);
    }

    const renderPickupImages = (pickupRequest) => {
        if (pickupRequest?.pickupRequestImages?.length > 0) {
            return pickupRequest.pickupRequestImages.map((pickupImage) => {
                if (pickupImage.pickupRequestStatus == pickupRequest.pickupStatus) {
                    return (
                        <div title={"remarks:" + pickupRequest.remarks} key={pickupImage.id} onClick={() => showImageLightBox(pickupImage, pickupRequest.remarks)} className='border p-1 rounded-md hover:bg-black'>
                            <img width="50" src={BACKEND_API_URL + 'images/' + pickupImage.image} />
                        </div>
                    )
                }
                return '';
            })
        }
        return '';
    }

    const renderPickupIssueImages = (reportedIssue) => {
        if (reportedIssue?.pickupRequestIssueImages?.length > 0) {
            return reportedIssue?.pickupRequestIssueImages.map((pickupImage) => {
                return (
                    <div key={pickupImage.id} onClick={() => showImageLightBox(pickupImage, reportedIssue)} className='border p-1 rounded-md hover:bg-black'>
                        <img width="50" src={BACKEND_API_URL + 'images/' + pickupImage.image} />
                    </div>
                )
            })
        }
        return '';
    }

    const renderPickupImageHeading = (pickupRequest) => {
        let checkImageExist = pickupRequest?.pickupRequestImages.some((pickupImage) => {
            return pickupImage.pickupRequestStatus == pickupRequest.pickupStatus;
        })

        if (checkImageExist) {
            return (<h3 className='text-xl font-medium'>Freight Photos at Pickup</h3>)
        }
    }

    const renderTruckPickupRequests = (truck, truckIndex) => {

        if (truck.assignedPickupRequests.length > 0) {
            return truck.assignedPickupRequests.map((pickupRequest, index) => {
                let sourceAddressData = {};
                let destinationAddressData = {};
                let addresses = pickupRequest?.pickupRequestAddresses;

                addresses.map((address) => {
                    if (address.addressType === 'SHIPPER' || address.addressType === 'SOURCE') {
                        sourceAddressData = address;
                    }
                    else if (address.addressType === 'CONSIGNEE' || address.addressType === 'DESTINATION') {
                        destinationAddressData = address;
                    }
                    return true;
                });

                return (
                    <div key={index} className="md:w-full px-0 lg:px-3 border-b-2 mb-4">
                        <div className="md:w-full flex flex-wrap rounded-lg ">
                            <div className="w-full px-0">
                                <div className='relative w-full flex flex-wrap rounded-lg mb-[20px]'>
                                    <div className="w-full sm:w-full md:w-full lg:w-[30%] xl:w-1/6 2xl:w-[25%]">
                                        <div>
                                            <h5 className="text-[18px] text-[#374151] font-semibold font-inter capitalize mb-[8px]">{truck.make} {truck.model} {truck.numberPlate ? '( ' + truck.numberPlate + ')' : ''}</h5>
                                            {pickupRequest.pickupStatus && pickupRequest.pickupStatus === 'unloaded' ?
                                                <p className="font-inter font-normal text-[16px] mb-[16px] capitalize">Confirmed location: <span className="font-bold text-green-500">{pickupRequest.storageLocation ? pickupRequest.storageLocation.title : ''}</span></p>
                                                : <SuggestedLocations suggestedLocations={storageLocation} pickupId={pickupRequest.id} zipCode={destinationAddressData.zipCode} setSelectedSuggestedLocation={(location, pickupId) => setSuggestedLocationHandle(location, pickupId)} />}
                                        </div>
                                        <div className="w-full mb-5">
                                            {pickupRequest.pickupStatus && pickupRequest.pickupStatus === 'unloaded' && <p className="capitalize">Pickup Status: <span className="font-bold text-green-500">{pickupRequest.pickupStatus}</span></p>}
                                            <div>
                                                {pickupRequestUpdatedData && pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest?.id == pickupRequest.id && <p className="text-green-500">Pickup Request #{pickupRequest.id} - {pickupRequest.pickupNumber} unloaded successfully</p>}
                                            </div>
                                        </div>
                                        {pickupRequest.pickupStatus !== 'unloaded' && truck.id == checkinTruck?.id && <div className="flex flex-wrap justify-start">
                                            <button onClick={() => unloadStatusHandle(index, pickupRequest.id)} className="mb-5 px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-white transition-colors duration-200 transform bg-[#EA580C] rounded-md hover:bg-[#c6e3ff] focus:outline-none focus:bg-gray-600">Unload</button>
                                            {unloadStatus.includes(pickupRequest.id) &&
                                                <>
                                                    {storageLocation && storageLocation[pickupRequest.id] && <div className="mb-5 w-2/4 flex align-items-center justify-start">
                                                        <button onClick={() => confirmLocationHandle(0, pickupRequest.id, 'unloaded')} className="px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-[#EA580C] font-semibold transition-colors duration-200 transform bg-[#EA580C1A] rounded-md hover:bg-[#c6e3ff] focus:outline-none focus:bg-gray-500">Confirm</button>
                                                    </div>}

                                                    <button onClick={() => chooseOtherLocationHandle(pickupRequest.id)} className="mb-5 px-[1.5rem] py-[0.75rem]  mr-4 leading-5 text-[16px] text-[#EA580C] font-semibold transition-colors duration-200 transform  rounded-md hover:bg-[#c6e3ff] focus:outline-none focus:bg-gray-500">Choose Other Location</button>
                                                </>
                                            }
                                        </div>}
                                        {errors && errors[pickupRequest.id] && <p className="text-red-500">{errors[pickupRequest.id].suggestedTruck}</p>}
                                    </div>
                                    <div className="relative w-full sm:w-full md:w-full lg:w-[45%] xl:w-3/6 2xl:md:w-[50%]">
                                        <div className="w-full">
                                            <div className="flex flex-wrap w-full">
                                                <div className='flex flex-wrap w-full'>
                                                    <span className="w-full font-inter font-semibold text-[16px] text-[#374151] mb-[20px]">Pickup # {pickupRequest.id}</span>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Pickup Number:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.pickupNumber}</span>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Zip:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.zipCode}</span>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Zip:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.zipCode}</span>
                                                </div>
                                                {renderPickupRequestItems(pickupRequest?.pickupRequestItems)}
                                                <div className='flex flex-wrap w-full'>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Source Company Name:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{sourceAddressData.companyName}</span>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Destination Company Name:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{destinationAddressData.companyName}</span>
                                                    <span className='w-3/6 md:w-[25%] lg:w-[40%] xl:w-[25%] 2xl:w-[35%] font-inter text-[16px] font-normal capitalize text-[#6B7280] mr-0 lg:mr-[24px]'>Service Type:</span>
                                                    <span className='w-3/6 md:w-[75%] lg:w-[40%] xl:w-[60%] 2xl:w-[60%] font-inter font-medium text-[16px] text-[#374151] text-right lg:text-left'>{pickupRequest.serviceType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-2/6 lg:w-[25%] xl:w-2/6 2xl:w-[25%] relative md:absolute top-0 right-0 lg:relative">
                                        <div className="flex justify-start md:justify-end">
                                            <button onClick={() => showReportIssueModalHandle(pickupRequest.id)} className="flex items-center px-0 md:p-2 leading-5 text-[#EA580C] text-[17px] font-semibold transition-colors duration-200 transform   hover:bg-[#EA580C1A] focus:outline-none focus:bg-[#EA580C1A] rounded-md">
                                                <span className="mr-2">

                                                    <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM15.8333 12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667H12.4167L15.8333 7.58333V12.4167Z" fill="#EA580C" />
                                                        <path d="M10.0001 14.1667C10.4603 14.1667 10.8334 13.7936 10.8334 13.3333C10.8334 12.8731 10.4603 12.5 10.0001 12.5C9.53984 12.5 9.16675 12.8731 9.16675 13.3333C9.16675 13.7936 9.53984 14.1667 10.0001 14.1667Z" fill="#EA580C" />
                                                        <path d="M9.16675 5.83333H10.8334V11.6667H9.16675V5.83333Z" fill="#EA580C" />
                                                    </svg>

                                                </span>
                                                <span>
                                                    Report an issue
                                                </span>
                                            </button>
                                        </div>
                                        <div className='w-[100] mt-4'>
                                            {(pickupRequest.pickupStatus == 'picked' && pickupRequest?.pickupRequestImages?.length > 0) &&
                                                <div>
                                                    {renderPickupImageHeading(pickupRequest)}
                                                    <div className='w-full flex flex-wrap items-center justify-start gap-3 mt-4'>
                                                        {renderPickupImages(pickupRequest)}
                                                    </div>
                                                </div>
                                            }
                                            {pickupRequest?.pickupRequestIssues.length > 0 &&
                                                <div className='w-full mt-4'>
                                                    <hr className='my-4'></hr>
                                                    <h3 className='text-xl font-medium'>Issue Reported - Case Photos</h3>
                                                    <div className='w-full flex flex-wrap items-center justify-start gap-3 mt-4'>
                                                        {renderPickupIssueImages(pickupRequest?.pickupRequestIssues[0])}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div key={pickupRequest.id}>
                                {showReportIssueModalId === pickupRequest.id && <ReportIssueModal pickupId={showReportIssueModalId} showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                            </div>
                            {otherLocationModalId == pickupRequest.id && <div className='w-full px-0'>
                                <OtherLocationModel updateSelectedLocation={(locationId) => confirmLocationHandle(locationId, pickupRequest.id, 'unloaded')} pickupId={pickupRequest.id} selectedLocation={storageLocation} showModal={showOtherLocationModal} closeModal={() => closeOtherLocationModal()} />
                            </div>}
                        </div>
                    </div>
                )

            });
        }
    }


    const renderTrucksDetails = () => {
        if (trucks.length > 0) {
            return trucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full">
                        <div className="relative md:w-full flex flex-wrap p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="md:w-full flex flex-wrap">
                                {renderTruckPickupRequests(truck, index)}
                            </div>


                        </div>
                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }

    const renderUpComingTrucksDetails = () => {
        if (upcomingTrucks.length > 0) {
            return upcomingTrucks.map((truck, index) => {
                return (
                    <div key={index} className="md:w-full mb-[20px]">
                        <div className="md:w-full flex p-5 border bg-white border-[#D1D5DB] shadow-md rounded-lg h-[100%]">
                            <div className="w-full">
                                {renderTruckPickupRequests(truck, index)}
                            </div>
                            
                        </div>
                        <div key={truck.id + '_' + index}>
                            {showReportIssueModalId === truck.id && <ReportIssueModal showModal={showReportIssueModal} closeModal={() => closeReportIssueModal()} />}
                        </div>
                    </div>
                )
            })
        }
        return (<p>No truck Data Available...</p>)
    }


    return (
        <div>
            <div className="tab-container">
                {(filterValue == 'picked' || filterValue == 'unloaded') ? <div>
                    {trucks.length > 0 ? <div className="relative z-1">
                        <div className="row pt-3 mb-4 my-2">
                            <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">In Progress</h3>
                            {renderTrucksDetails()}
                        </div>

                    </div>
                        :
                        <p className=' mt-7'>No Pickup Request Found...</p>
                    }
                    {upcomingTrucks.length > 0 && <div className="row pt-3 mb-4 my-2">
                        <h3 className="uppercase text-[#6B7280] mb-3 font-medium font-inter text-[12px]">Up Coming</h3>
                        {renderUpComingTrucksDetails()}
                    </div>}
                    <ImageLightBoxModal remarks={lighBoxImageCaption} pickupImage={lighBoxImage} showModal={showImageLightBoxModal} closeModal={() => closeImageLightBoxModal()} />
                </div>
                    :
                    <CompletedFreight selectedTruckTab={selectedTruckTab} newCompletedPickupRequest={pickupRequestUpdatedData?.updatePickupRequest?.pickupRequest} pickupStatus='unloaded' />
                }
            </div>
        </div >
    )
}
export default Unload;