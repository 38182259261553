import React, { useContext, useEffect, useState } from 'react';
import bellIcon from '../assets/bell-icon.svg'; // You need to install @heroicons/react

import { useSubscription, gql, useMutation } from '@apollo/client';
import { WEBSOCKET_API_URL } from '../constants';
import GraphQuery from './graphql/queries/Query';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import NotificationTune from '../assets/sounds/notification_ding.mp3'

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription onNotification {
    pickupRequestStatusUpdate {
        id
        title
        description
        status
        readAt
        action
        objectId
        createdAt
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification($id: ID!) {
        updateNotification (id: $id) {
            notification {
                id
                title
                description
                status
                readAt
                action
                objectId
                createdAt
            }
        }
    }
`;

// const notifications = [
//     { id: 1, action: 'Created', description: 'Your pickup request has been created.' },
//     { id: 2, action: 'Picked', description: 'Your pickup request has been picked up.' },
//     { id: 3, action: 'On-the-move', description: 'Your pickup is on the way.' },
//     // Add more notifications as needed
// ];

const NotificationDropdown = ({ toggleNotificationDropdown, notificationUpdatedFlag }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const { data, loading, error } = useSubscription(NOTIFICATION_SUBSCRIPTION);
    const { token } = useContext(AuthContext);
    const [receiveNewNotification, setReceiveNewNotification] = useState({
        status: false,
        count: 0
    })
    const { responseData: notificationData, refetchData } = GraphQuery({ name: 'Notifications', single: 'Notification' }, {
        first: 10,
        skip: 0,
        // search: '',
        sortBy: 'created_at',
        order: 'desc',
    }, 'id title description status readAt action objectId createdAt');

    const [updateNotification, { data: updateNotificationData, loading: updateNotificationLoading, error: updateNotificationError }] = useMutation(UPDATE_NOTIFICATION);


    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
        toggleNotificationDropdown(notifications)
        // const notificationSound = new Audio(NotificationTune);
        // notificationSound.play();
        // setReceiveNewNotification({
        //     status: false,
        //     count: 0
        // });
    };

    useEffect(() => {
        if (notificationUpdatedFlag) {
            refetchData({ fetchPolicy: 'network-only' });
        }
    }, [notificationUpdatedFlag])

    useEffect(() => {
        const socket = new WebSocket(`${WEBSOCKET_API_URL}?token=${token}`);
        socket.onopen = () => {
            console.log("WebSocket connection opened");

            socket.send(JSON.stringify({
                type: "send_notification",
                payload: {
                    query: NOTIFICATION_SUBSCRIPTION,
                    variables: {},
                    operationName: "subscription",
                }
            }));
        };

        socket.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };

        socket.onmessage = (event) => {
            const response = JSON.parse(event.data);
            const notificationSound = new Audio(NotificationTune);
            notificationSound.play();
            console.log("Received message:", response);
            setNotifications((prevNotifications) => [
                {
                    id: response.id,
                    title: response.title,
                    description: response.description,
                    status: response.status,
                    readAt: response.readAt,
                    action: response.action,
                    objectId: response.objectId,
                    createdAt: response.createdAt,
                },
                ...prevNotifications,
            ]);
            let newNotification = notifications?.filter(notification => notification.status === 'unread');

            setReceiveNewNotification((prevState) => ({
                status: true,
                count: prevState.count + 1,
            }));
        };

        socket.onclose = (event) => {
            console.log(`WebSocket closed with code: ${event.code}`);
        };

    }, [])

    useEffect(() => {
        if (notificationData) {
            setNotifications(notificationData);
            let newNotification = notificationData?.filter(notification => notification.status === 'unread');

            setReceiveNewNotification({
                status: true,
                count: newNotification.length,
            });
        }
    }, [notificationData]);


    let pickupStatuses = {
        'driver-assigned': 'Driver is Assigned'
    }

    const notificationDetailPageHandle = (id) => {
        updateNotification({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: { id }
        });
        refetchData({ fetchPolicy: 'network-only' });
        setIsOpen(false);

    }

    const timeAgo = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        const intervals = {
            year: 31536000,
            month: 2592000,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (let key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
            }
        }
        return 'just now';
    };

    return (
        <div className="relative inline-block text-left">
            <div>

                <button
                    type="button"
                    onClick={toggleDropdown}
                    className="flex items-center p-2 text-gray-600 hover:text-gray-800 focus:outline-none relative"
                >
                    <img className="ml-auto" src={bellIcon} alt="Notifications" />
                    {receiveNewNotification?.status && (receiveNewNotification.count > 0) && <div className='w-[15px] h-[15px] text-xs bg-blue-600 rounded-[25px] text-center text-white absolute top-0 right-0'>{receiveNewNotification.count}</div>}
                </button>
            </div>

            {/* {isOpen && (
                <div className="absolute right-0 z-10 w-80 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    {notifications.length > 0 && <div className="py-1 h-[450px] overflow-y-scroll">

                        {notifications?.map((notification, index) => (
                            <a
                                key={notification.id}
                                onClick={() => notificationDetailPageHandle(notification.id)}
                                // href="#"
                                className={`flex items-center justify-start ${notification.status === 'unread' ? 'bg-blue-200' : 'bg-white'} px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b`}
                            >
                                <div className='w-1/6 flex items-center justify-center'>
                                    <img className="" src={bellIcon} alt="Notifications" />
                                </div>
                                <div className='w-5/6'>
                                    <strong className='capitalize'>{notification.title}</strong>
                                    <p>
                                        {notification.description}
                                    </p>
                                    <small className="text-gray-500">{timeAgo(notification.createdAt)}</small>
                                </div>
                            </a>
                        ))}
                    </div>}
                    {notifications.length == 0 && <div className="py-1">
                        <p className='px-2'>No Notification Found...</p>
                    </div>}
                </div>
            )} */}
        </div>
    );
};

export default NotificationDropdown;
