import React, { useState, useEffect, useContext } from 'react';

import PageHeading from "../../components/PageHeading";

import { gql, useMutation, useQuery } from '@apollo/client';

import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


function DriverCreatePage() {
    // const urlParams = new URLSearchParams(window.location.search);
    const [errors, setErrors] = useState([]);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [htmlFormData, setHtmlFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        mobileNumber: '',
        address: '',
        licenseNumber: '',
        defaultTruckId: '',
    });
    const [truckOptions, setTruckOptions] = useState([]);
    const GET_TRUCKS = gql`
        query GetTrucks {
            trucks {
                id,
                numberPlate,
                color,
                model,
                make
            }
        }
    `;

    const CREATE_DRIVER = gql`
        mutation($driverNew: CreateDriverInput!) {
            createDriver(driverData: $driverNew) {
                driver {
                    id
                    user{
                        id
                        username
                        firstName
                        lastName
                        email
                    }
                    mobileNumber
                    address
                    licenseNumber
                    profileImage
                    address
                    licenseNumber
                }
            }
        }
    `;

    const { data: trucksData } = useQuery(GET_TRUCKS, {
        context: {
            headers: {
                Authorization: `JWT ${token}`
            }
        }
    });
    const [createDriver, { loading }] = useMutation(CREATE_DRIVER, {
        onError: (error) => {
            try {
                const parsedError = JSON.parse(error.message);
                setErrors(parsedError);
            } catch (parseError) {
                setErrors('An error occurred while processing your request.');
            }

        },
        onCompleted: () => {
            window.location.href = '/admin/drivers';
        }
    });




    useEffect(() => {
        if (trucksData && trucksData.trucks) {
            let truckOptions = trucksData.trucks.map((truck) => {
                return {
                    value: truck.id,
                    label: truck.make + " " + truck.model + " ( " + truck.numberPlate + " )",
                }
            });

            setTruckOptions(truckOptions);
        }
    }, [trucksData]);

    if (loading) return <p>Loading ... please wait</p>

    const handleChange = (property, e) => {
        setHtmlFormData({
            ...htmlFormData,
            [property]: e.target.value
        })
    }

    const defaultTruckHandle = (e) => {
        setHtmlFormData({
            ...htmlFormData,
            defaultTruckId: e.target.value,
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createDriver({
            context: {
                headers: {
                    Authorization: `JWT ${token}`,
                },
            },
            variables: {
                driverNew: {
                    username: htmlFormData.username,
                    firstName: htmlFormData.firstName,
                    lastName: htmlFormData.lastName,
                    email: htmlFormData.email,
                    password: htmlFormData.password,
                    mobileNumber: htmlFormData.mobileNumber,
                    address: htmlFormData.address,
                    licenseNumber: htmlFormData.licenseNumber,
                    profileImage: htmlFormData.profileImage,
                    defaultTruckId: htmlFormData.defaultTruckId,
                }
            }
        });
    }

    const handleCancel = () => {
        navigate("/admin/drivers");
    }

    const mobileNumberHandle = (mobileNumber) => {
        setHtmlFormData({
            ...htmlFormData,
            mobileNumber: mobileNumber,
        })
    }

    return (
        <div className="content px-3 lg:px-8">
            <div className="content-bar flex justify-between mt-6">
                <PageHeading>Add New Driver</PageHeading>
            </div>
            <div className="mt-8">
                <div className="not-prose relative bg-gray-50 rounded-xl overflow-hidden">
                    <div className="absolute inset-0"></div>
                    <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden mt-7 px-4">
                            <div className='row'>
                                <div className='md:w-3/6'>
                                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                    First Name
                                                </label>
                                                <input value={htmlFormData.firstName} onChange={(value) => handleChange('firstName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-first-name" type="text" autoComplete="on" placeholder="First Name" required />
                                                {errors && errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                    Last Name
                                                </label>
                                                <input value={htmlFormData.lastName} onChange={(value) => handleChange('lastName', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-last-name" type="text" autoComplete="on" placeholder="Last Name" required />
                                                {errors && errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-email">
                                                    Email
                                                </label>
                                                <input value={htmlFormData.email} onChange={(value) => handleChange('email', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4" id="grid-email" type="email" placeholder="email@gmail.com" required />
                                                {errors && errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-username">
                                                    Username
                                                </label>
                                                <input value={htmlFormData.username} onChange={(value) => handleChange('username', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-username" type="text" placeholder="Username" required />
                                                {errors && errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-password-input">
                                                    Password
                                                </label>
                                                <input value={htmlFormData.password} onChange={(value) => handleChange('password', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-password-input" type="password" placeholder="******************" />
                                                {errors && errors.pasword && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-mobile-number">
                                                    Mobile Number
                                                </label>
                                                <PhoneInput
                                                    country={'us'}
                                                    value={htmlFormData.mobileNumber}
                                                    onChange={mobileNumberHandle}
                                                />
                                                {/* <input value={htmlFormData.mobileNumber} onChange={(value) => handleChange('mobileNumber', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-mobile-number" type="text" placeholder="Mobile Number" /> */}
                                                {errors && errors.mobileNumber && <p className="text-red-500 text-xs italic">{errors.mobileNumber}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-6">
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-default-truck">
                                                    Default Truck
                                                </label>
                                                <div className="relative mb-3">
                                                    <select onChange={defaultTruckHandle} className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded" id="grid-default-truck">
                                                        <option value="null">Select Default Truck</option>
                                                        {
                                                            truckOptions.map((truck) => {
                                                                return (
                                                                    <option value={truck.value} key={truck.value}>{truck.label}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker right-0 top-4">
                                                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>

                                                </div>
                                                <p className="text-grey-dark text-xs italic"></p>
                                            </div>
                                            <div className="md:w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="license_number_input">
                                                    License Number
                                                </label>
                                                <input maxLength={13} value={htmlFormData.licenseNumber} onChange={(value) => handleChange('licenseNumber', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 " id="license_number_input" type="text" placeholder="License Number" />
                                                {errors && errors.licenseNumber && <p className="text-red-500 text-xs italic">{errors.licenseNumber}</p>}
                                            </div>
                                        </div>
                                        <div className="-mx-3 md:flex mb-2">
                                            <div className="md:w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-city">
                                                    Address
                                                </label>
                                                <input value={htmlFormData.address} onChange={(value) => handleChange('address', value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-city" type="text" placeholder="Address" />
                                            </div>
                                        </div>
                                        {/*
                                        <div className="-mx-3 md:flex mb-2">
                                            <div className="md:w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="grid-city">
                                                    Select Profile Image
                                                </label>
                                                <div className="extraOutline p-4 bg-white w-max bg-whtie m-auto rounded-lg">
                                                    <div className="file_upload p-5 relative border-4 border-dotted border-gray-300 rounded-lg" style={{ width: '450px' }}>
                                                        <svg className="text-indigo-500 w-24 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
                                                        <div className="input_field flex flex-col w-max mx-auto text-center">
                                                            <label>
                                                                <input onChange={handleImageChange} className="text-sm cursor-pointer w-36 hidden" type="file" />
                                                                <div className="text bg-indigo-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-indigo-500">Select</div>
                                                            </label>

                                                            <div className="title text-indigo-500 uppercase">or drop files here</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                        <div className="flex justify-end mt-6">
                                            <button onClick={handleCancel} className="px-6 py-2 mr-3 leading-5 text-black transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-500">Cancel</button>
                                            <button onClick={handleSubmit} className="px-6 py-2 leading-5 text-white transition-colors duration-200 transhtmlForm bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Create Driver htmlForm  */}


                        </div>
                    </div>
                    <div className="absolute inset-0 pointer-events-none border border-black/1 rounded-xl"></div>
                </div>
            </div>
        </div >
    );
}

export default DriverCreatePage;