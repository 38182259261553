import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as XLSX from "xlsx";
import { gql } from "@apollo/client";
import { AuthContext } from '../../../context/authContext';

// import { IMPORT_CUSTOMERS_MUTATION } from './graphql/mutations';


const CustomerImport = ({ dataImportedHandle }) => {
    const [loading, setLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const IMPORT_CUSTOMER_MUTATION = gql`
        mutation ImportCustomers($customerImportData: CustomerImportInput) {
            importCustomers(customerImportData: $customerImportData) {
                success
                message
            }
        }
    `;

    const [importCustomers, { data: customerImportedData, loading: dataloading, error }] = useMutation(IMPORT_CUSTOMER_MUTATION);

    useEffect(() => {
        console.log('reson', customerImportedData);
        if (customerImportedData?.importCustomers && customerImportedData?.importCustomers?.success) {
            dataImportedHandle()
        }
    }, [customerImportedData])

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setLoading(true);

        try {
            // Validate file type
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!['csv', 'xlsx'].includes(fileExtension)) {
                throw new Error('Invalid file type. Please upload CSV or XLSX.');
            }

            // Read file
            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Convert to JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                    header: [
                        'Active', 'Status', 'Customer', 'Company', 'Salutation',
                        'FirstName', 'MiddleInitial', 'LastName', 'PrimaryContact',
                        'SecondaryContact', 'MainPhone', 'Fax', 'AltPhone',
                        'JobTitle', 'MainEmail', 'BillTo1', 'BillTo2', 'BillTo3',
                        'BillTo4', 'Terms', 'Rep', 'AccountNo', 'CreditLimit'
                    ]
                });

                // Remove header row
                jsonData.shift();

                console.log(jsonData, 'json')
                // Validate required columns
                const requiredColumns = [
                    'Company', 'BillTo1'
                ];
                const missingColumns = requiredColumns.filter(col =>
                    !jsonData[0].hasOwnProperty(col)
                );

                if (missingColumns.length > 0) {
                    throw new Error(`Missing required columns: ${missingColumns.join(', ')}`);
                }

                console.log('jsonData---', jsonData)

                await importCustomers({
                    context: {
                        headers: {
                            Authorization: `JWT ${token}`,
                        },
                    },
                    variables: {
                        customerImportData: {
                            data: JSON.stringify(jsonData)
                        }
                    }
                });
            };
            reader.readAsArrayBuffer(file);
        } catch (error) {
            alert('import error');
            // toast({
            //     title: 'Import Error',
            //     description: error.message,
            //     variant: 'destructive'
            // });
            setLoading(false);
        }
    };


    return (
        <div className="flex items-center space-x-2">
            <input
                type="file"
                id="customer-import"
                accept=".csv,.xlsx"
                onChange={handleFileUpload}
                className="hidden"
            />
            <button
                className='flex px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-3'
                onClick={() => document.getElementById('customer-import').click()}
                disabled={dataloading}
            >
                {dataloading ? 'Importing...' : 'Import'}
            </button>
        </div>
    );
};

export default CustomerImport;

// GraphQL Mutation (to be defined in separate file)
// export const IMPORT_CUSTOMERS_MUTATION = gql`
//   mutation ImportCustomers($customers: [CustomerInput!]!) {
//     importCustomers(customers: $customers) {
//       count
//       errors
//     }
//   }
// `;