import { Fragment } from "react";
import classNames from "classnames";
import moment from 'moment';
function Table({ data, config, keyFun, tableHeaderTextColor = 'text-gray-500' }) {
    const renderedHeaders = config.map((column, index) => {
        if (column.header) {
            return <Fragment key={index}>{column.header()}</Fragment>
        }

        const classes = classNames(
            'text-xs leading-4 font-medium tracking-wider uppercase border-b p-4 text-left pb-3 pt-0 text-nowrap',
            tableHeaderTextColor,
            column.headerClassName,
        );
        if (column.label == 'Select All') {
            return <th className={classes} key={index} width={column.width}>{column.labelRender(column.label)}</th>
        }
        return <th className={classes} key={index} width={column.width}>{column.label}</th>
    });

    const renderedRows = data.map((rowData) => {
        const unMutatedRow = { ...rowData }
        unMutatedRow.createdAt = moment(unMutatedRow.createdAt).format('DD-MM-Y');
        const renderedCells = config.map((column, index) => {
            const classes = classNames(
                'border-b border-gray-200 p-4',
                column.rowCellClassName,
            );
            return <td className={classes} key={index}>{column.render(unMutatedRow, unMutatedRow.createdAt)}</td>;
        });
        return (
            <tr key={keyFun(unMutatedRow)}>
                {renderedCells}
            </tr>
        );
    });

    return (
        <table className="border-collapse table-auto w-full text-sm shadow-base">
            <thead>
                <tr className="whitespace-nowrap">
                    {renderedHeaders}
                </tr>
            </thead>
            <tbody className="bg-white">
                {renderedRows}
            </tbody>
        </table>
    );
}


export default Table;