import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../context/authContext';

const SuggestedTrucks = ({ suggestedTrucks, pickupId, zipCode, setSelectedSuggestedTruck }) => {
    const { token } = useContext(AuthContext);
    // const [suggestedTruck, setSuggestedTruck] = useState(null);
    const [suggestedTruckName, setSuggestedTruckName] = useState(null);

    const GET_TRUCKS_BY_ZIP_CODE = gql`
        query GetTrucksByZipCode($zipCode: String, $sortBy: TrucksByZipCodeSortInput) {
            trucksByZipCode(zipCode: $zipCode, sortBy: $sortBy) {
                id
                model
                numberPlate
                zone {
                    id
                    title
                }
            }
        }
    `;

    const [fetchTrucksByZipCode, { data: trucksByZipCodeData }] = useLazyQuery(GET_TRUCKS_BY_ZIP_CODE, {
        context: {
            headers: {
                Authorization: `JWT ${token}`,
            },
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            if (zipCode) {
                try {
                    let result = await fetchTrucksByZipCode({
                        variables: {
                            zipCode: zipCode,
                            sortBy: {
                                field: 'numberPlate',
                                direction: 'asc',
                            },
                        },
                    });

                    if (result?.data?.trucksByZipCode[0] != null) {
                        let title = result?.data?.trucksByZipCode[0] ? result?.data?.trucksByZipCode[0]?.model + " (" + result?.data?.trucksByZipCode[0]?.numberPlate + ")" : '';
                        setSuggestedTruckName({
                            pickupId: pickupId,
                            title: title
                        });
                        let truck = result?.data?.trucksByZipCode[0];
                        setSelectedSuggestedTruck(truck, pickupId);
                    }
                } catch (error) {
                }
            }
        };

        fetchData();
    }, [zipCode, fetchTrucksByZipCode]);


    return (
        <div className="my-0">
            <span className=" pb-1 text-[#374151] rounded-md">Suggested Truck: {suggestedTruckName != null && suggestedTruckName.pickupId == pickupId ? suggestedTruckName.title : ''}</span>
        </div>
    );
};

export default SuggestedTrucks;
